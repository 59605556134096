import { createSlice } from '@reduxjs/toolkit';
import {
  CreatePriceListData,
  DeletePriceListData,
  GetAllPriceListData,
  GetSinglePriceListData,
  UpdatePriceListData,
} from './thunk';
import { IPriceList } from 'src/utils/types/priceListTypes';

interface IPriceListState {
  priceList: IPriceList[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IPriceListState = {
  priceList: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const priceListSlice = createSlice({
  name: 'priceList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllPriceListData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllPriceListData.fulfilled, (state, action) => {
        state.priceList = action.payload as [];
        state.isLoading = false;
      })
      .addCase(GetAllPriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSinglePriceListData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSinglePriceListData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSinglePriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreatePriceListData.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreatePriceListData.fulfilled, (state, action) => {
        state.priceList.push(action.payload as IPriceList);
        state.isCreating = false;
      })
      .addCase(CreatePriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdatePriceListData.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdatePriceListData.fulfilled, (state, action) => {
        const updatedContact = action.payload as IPriceList;
        const index = state.priceList.findIndex((contact) => contact.id === updatedContact.id);
        state.priceList[index] = updatedContact;
        state.isUpdating = false;
      })
      .addCase(UpdatePriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      })
      .addCase(DeletePriceListData.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeletePriceListData.fulfilled, (state, action) => {
        const deletedContactId = action.payload as string;
        state.priceList = state.priceList.filter((contact) => contact.id !== deletedContactId);
        state.isDeleting = false;
      })
      .addCase(DeletePriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isDeleting = false;
      });
  },
});

export default priceListSlice.reducer;
