/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row, Spinner, Button, Modal } from 'reactstrap';
import { processOccupancyPredictionSlice } from 'src/slices/OccupancyPrediction/thunk';
import { useDispatch, useSelector } from 'react-redux';

import { notification, Space } from 'antd';
import { IUserInputForm, IUserInputFormHandle, UserInputForm } from '../form/UserInputForm';
import { Button as ButtonAntD } from 'antd';

import Select from 'react-select';
import Chart, { ChartProps, defaultChartValue } from 'src/components/elements/Chart';
import './index.scss';
import { RootState } from 'src/slices';
import { Tabs } from 'antd';
import type { NotificationArgsProps, TabsProps } from 'antd';
import { IUserInputFormHandleV1, UserInputFormV1 } from '../form/UserInputFormV1';
import Breadcrumb from 'src/components/Common/Breadcrumb';

const OccupancyPrediction: React.FC = () => {
  const userInputFormHandle = useRef<IUserInputFormHandleV1>(null);
  const { properties, selectedProperty } = useSelector((state: RootState) => state.porperty);

  const handleButtonClick = async () => {
    const mealPlans = userInputFormHandle.current?.mealPlans;
    const roomTypes = userInputFormHandle.current?.roomTypes;
    const weathers = userInputFormHandle.current?.weathers;
    const regions = userInputFormHandle.current?.regions;
    const reservationTypes = userInputFormHandle.current?.reservationTypes;
    const isRoomInternal = userInputFormHandle.current?.isRoomInternal;

    const formValues: any = await userInputFormHandle.current?.fetchValues();
    console.log(formValues);

    const newModalData = {
      ...mealPlans,
      ...roomTypes,
      ...regions,
      ...reservationTypes,
      ...weathers,
      RoomSize: formValues?.RoomSize,
      TotalRate: formValues?.totalRate,
      DiscountedRate: formValues?.discountRate,
      IsRoomInternal: isRoomInternal,
      MandantId: 0,
      MandantName: selectedProperty?.value,
      WeatherConditions: formValues?.weatherCondition,
      BookingPromotion: 'example',
      PromotionVendor: 'example',
      SourceOfBusiness: 'example',
      RoomTypeName: 'example',
    };
    console.log('newModalData');
    console.log(newModalData);

    const keysToBeFiltered = [
      'RoomTypeName',
      'SourceOfBusiness',
      'BookingPromotion',
      'MandantName',
      'WeatherConditions',
      'PromotionVendor',
    ];

    Object.keys(newModalData).forEach((key) => {
      if (keysToBeFiltered.includes(key)) {
        // @ts-ignore
        delete newModalData[key];
      }
    });
    console.log('newModalData');
    console.log(newModalData);

    const data = convertData(newModalData, selectedProperty?.value);

    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    const file = new File([blob], 'Occupancy.json', { type: 'application/json' });

    const requestData = {
      modelName: 'Occupancy',
      file: file,
      startDate: formValues?.dateRange[0],
      endDate: formValues?.dateRange[1],
      isFromForm: true,
    };

    try {
      const response: any = await dispatch(processOccupancyPredictionSlice(requestData));
      console.log(response);
      console.log(occupancyPrediction);
      setChartData(response?.payload); // Assuming data is in the format expected by your chart library
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }

    // var formData = new FormData();

    // formData.append('File', blob, 'Occupancy.json');
    // formData.append('ModelName', 'Occupancy');

    // fetch('http://localhost:5264/ProcessMLModelFile/processOccupancyModelFile', {
    //   method: 'POST',
    //   body: formData
    // })
    //   .then(response => {
    //     console.log(response);
    //     setChartData(response?.body);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  };

  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [chartData, setChartData] = useState<ChartProps>(defaultChartValue);
  const { occupancyPrediction, occupancyPredictionLoading } = useSelector(
    (state: RootState) => state.occupancyPrediction,
  );

  console.log(selectedProperty);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };
  type NotificationPlacement = NotificationArgsProps['placement'];

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string, placement: NotificationPlacement) => {
    api[type]({
      message: message,
      placement,
    });
  };

  const handleSubmit = async () => {
    if (!file) {
      openNotificationWithIcon('warning', 'Please select a file', 'bottomRight');
      return;
    }

    const requestData = {
      modelName: 'Occupancy',
      file: file,
      isFromForm: false,
    };

    try {
      const response: any = await dispatch(processOccupancyPredictionSlice(requestData));
      console.log(response);
      console.log(occupancyPrediction);
      setChartData(response?.payload); // Assuming data is in the format expected by your chart library
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Data Entry',
      children: (
        <Card>
          <CardBody>
            <UserInputFormV1 callFunction={handleButtonClick} ref={userInputFormHandle} />
            {/* <button style={{ marginTop: '-10px' }} className="btn btn-primary" onClick={handleButtonClick}>
              Process
            </button> */}
          </CardBody>
        </Card>
      ),
    },
    // {
    //   key: '2',
    //   label: 'File Upload',
    //   children: (
    //     <Card>
    //       <CardBody>
    //         <Row>
    //           <Col md={12}>
    //             <div className="file-upload-container">
    //               <div id="file-upload-div">
    //                 <input type="file" onChange={handleFileChange} />
    //                 <button className="btn btn-primary" disabled={occupancyPredictionLoading} onClick={handleSubmit}>
    //                   Upload File
    //                 </button>
    //               </div>
    //             </div>
    //           </Col>
    //         </Row>
    //       </CardBody>
    //     </Card>
    //   ),
    // },
  ];

  console.log(chartData);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Occupancy</title>
      </MetaTags>
      <Container fluid>
        {contextHolder}
        <Breadcrumb title="Dashboard" breadcrumbItem="Occupancy" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    {!chartData?.keys?.length && !chartData?.values?.length ? (
                      <>
                        <div
                          style={{
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                            background: '#e9ecef',
                            padding: '2rem',
                          }}
                        >
                          {occupancyPredictionLoading ? (
                            <div className="spinnerBlock">
                              <Spinner />
                            </div>
                          ) : (
                            <>
                              <h6>Please make your selections and execute the analysis.</h6>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <div>
                        {occupancyPredictionLoading && (
                          <div className="spinnerBlock">
                            <Spinner />
                          </div>
                        )}
                        {chartData && <Chart {...chartData} />}
                      </div>
                    )}

                    <FileUploadModal
                      showModal={showModal}
                      handleFileChange={handleFileChange}
                      handleSubmit={handleSubmit}
                      occupancyPredictionLoading={occupancyPredictionLoading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Tabs style={{ marginTop: '-10px' }} defaultActiveKey="1" items={items} />
      </Container>
    </div>
  );
};

const convertData = (data: any, MandantName: string) => {
  return {
    Inputs: {
      data: [data],
    },
    GlobalParameters: 0.0,
  };
};

const FileUploadModal = ({
  showModal,
  closeModal,
  handleFileChange,
  handleSubmit,
  occupancyPredictionLoading,
  chartData,
}: any) => {
  return (
    <Modal isOpen={showModal} onRequestClose={closeModal}>
      <div id="file-upload-div">
        <input type="file" onChange={handleFileChange} />
        <button disabled={occupancyPredictionLoading} onClick={handleSubmit}>
          Upload File
        </button>
      </div>
      {occupancyPredictionLoading && (
        <div className="spinnerBlock">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

export default OccupancyPrediction;
