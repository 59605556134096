
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useEffect, useState  } from 'react';
import { IMealPlans } from 'src/utils/types/mealPlansTypes';

import { CreateMealPlansData, GetAllMealPlansData, UpdateMealPlansData } from 'src/slices/MealPlans/thunk';


import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

interface IMealPlansProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IMealPlans | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IMealPlans | null) => void;
}


const MealPlansModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  isEditMode = false,
  setIsEditMode,
  setEditingData,
}: IMealPlansProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating, mealPlans } = useSelector((state: RootState) => state.mealPlans);
  const dispatch: AppDispatch = useDispatch();

  const [propertyIdOptions, setPropertyIdOptions] = useState<string[]>([]);


  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      console.log('initialValues', initialValues);
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

 useEffect(() => {
    const fetchMealPlans = async () => {
      const response = await dispatch(GetAllMealPlansData());

      if (response.payload) {
      
        const uniquePropertyIds = Array.from(
          new Set((response.payload as IMealPlans[]).map((mealPlan) => mealPlan.propertyId))
        );
        setPropertyIdOptions(uniquePropertyIds);
      }
    };

    fetchMealPlans();
  }, [dispatch]);

  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IMealPlans = form.getFieldsValue();
    console.log(formData);

    try {
      if (isEditMode) {
        // ** Update Operation
        const payload = formData;
        // payload.tenant = initialValues?.tenant;
        payload.id = initialValues?.id;
        const response = await dispatch(
          UpdateMealPlansData({
            data: payload,
            mealplansId: initialValues?.id as string,
          }),
        );
        // ** Handled Response
        if ((response.payload as IMealPlans)['id']) {
          openNotificationWithIcon('success', 'Meal plans updated successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllMealPlansData());
        } else {
          openNotificationWithIcon('error', `Error updating Meal plans data`);
        }
      } else {
        // ** Create Operation
        const payload = formData;
        const response = await dispatch(
          CreateMealPlansData({
            data: payload,
          }),
        );

        // ** Handled Response
        if ((response.payload as IMealPlans)['id']) {
          openNotificationWithIcon('success', 'Meal plans created successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllMealPlansData());
        } else {
          openNotificationWithIcon('error', `Error creating Meal plans data`);
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', `Something went wrong`);
    }
  };



  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };


  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
      <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? 'Update' : 'Create'} Meal Plans
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>

            <Form.Item
              required={true}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}
            >
              <Input id="mealPlansName" placeholder="Name" className="p-2 px-2" />
            </Form.Item>


            <Form.Item
              required={true}
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please enter a description',
                },
              ]}
            >
              <Input.TextArea id="mealPlansDescription" placeholder="Description" className="p-2 px-2" />
            </Form.Item>

            <Form.Item
              required={true}
              label="Accommodation Kinds"
              name="accommodationKinds"
              rules={[
                {
                  required: true,
                  message: 'Please select accommodation kinds',
                },
              ]}
            >
              <Select mode="multiple" id="mealPlansAccommodationKinds" placeholder="Select Accommodation Kinds">
                <Option value="full">Full</Option>
                <Option value="half">Half</Option>

              </Select>
            </Form.Item>


            <Form.Item
              required={true}
              label="Room Types"
              name="roomTypes"
              rules={[
                {
                  required: true,
                  message: 'Please select room types',
                },
              ]}
            >
              <Select mode="multiple" id="mealPlansRoomTypes" placeholder="Select Room Types">
                <Option value="single">Single</Option>
                <Option value="double">Double</Option>
                <Option value="suite">Suite</Option>

              </Select>
            </Form.Item>

            <Form.Item
              required
              label="Property ID"
              name="propertyId"
             
              rules={[{ required: true, message: 'Please select a property ID' }]}
            >
              <Select  id="mealPlansPropertyId" placeholder="Select Property ID">
                {propertyIdOptions.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="manage-hotel-cancel-button"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>
            <Button
             id="mealPlansCreateORUpdateButton"
              disabled={isCreating || isUpdating}
              onClick={handleCreateOrUpdate}
              style={{ marginTop: 20 }}
            >
              {isCreating || isUpdating
                ? isEditMode
                  ? 'Updating . . .'
                  : 'Creating . . .'
                : isEditMode
                  ? 'Update'
                  : 'Create'}
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MealPlansModal;
