import MetaTags from 'react-meta-tags';

//import Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { Container } from 'reactstrap';

const Dashboard = () => {
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Main | Data!</title>
        </MetaTags>

        <Container fluid></Container>
      </div>
    </>
  );
};

export default Dashboard;
