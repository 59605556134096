import { createSlice } from '@reduxjs/toolkit';
import { getProfile_Picture } from './thunk';

interface ProfileStateType {
  error?: string | object | null | undefined | unknown;
  success?: any;
  isProfilePictureLoading?: boolean;
  profilePicture?: any;
}
export const initialState: ProfileStateType = {
  error: '',
  success: '',
  isProfilePictureLoading: false,
  profilePicture: '',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileSuccess(state, action) {
      state.success = action.payload;
    },
    profileError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile_Picture.pending, (state) => {
      state.isProfilePictureLoading = true;
    });
    builder.addCase(getProfile_Picture.fulfilled, (state, action) => {
      state.isProfilePictureLoading = false;
      state.profilePicture = action.payload;
    });
    builder.addCase(getProfile_Picture.rejected, (state) => {
      state.isProfilePictureLoading = false;
      state.profilePicture = null;
    });
  },
});

export const { profileSuccess, profileError } = profileSlice.actions;

export default profileSlice.reducer;
