import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification, Switch  } from 'antd';
import { IimageManagement } from 'src/utils/types/imageManagementTypes';
import ImageManagementModal from './components/ImageManagementModal';
import { FiEdit, FiEye } from 'react-icons/fi';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { GetAllImageData, DeleteImageData } from 'src/slices/ImageManagement/thunk';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';
import { NotificationType } from 'src/utils/types/NotificationTypes';


export const ImageManagementTestData: IimageManagement[] = [
  {
    "id": "1",
    "filename": "src/assets/images/users/avatar-1.jpg",
    "hashtags": ["nature", "forest"],
    "keywords": ["trees", "green", "wildlife"],
    "enabled": true
  },
  {
    "id": "2",
    "filename": "src/assets/images/users/avatar-2.jpg",
    "hashtags": ["city", "night"],
    "keywords": ["buildings", "lights", "urban"],
    "enabled": false
  },
  {
    "id": "3",
    "filename": "src/assets/images/users/avatar-3.jpg",
    "hashtags": ["mountains", "hiking"],
    "keywords": ["climb", "adventure", "view"],
    "enabled": true
  },
  {
    "id": "4",
    "filename": "src/assets/images/users/avatar-4.jpg",
    "hashtags": ["ocean", "sunset"],
    "keywords": ["waves", "horizon", "romantic"],
    "enabled": true
  },
  {
    "id": "5",
    "filename": "src/assets/images/users/avatar-5.jpg",
    "hashtags": ["animals", "wildlife"],
    "keywords": ["lion", "safari", "nature"],
    "enabled": false
  },
  {
    "id": "6",
    "filename": "src/assets/images/users/avatar-6.jpg",
    "hashtags": ["winter", "snow"],
    "keywords": ["cold", "ice", "holiday"],
    "enabled": true
  },
  {
    "id": "7",
    "filename": "src/assets/images/users/avatar-7.jpg",
    "hashtags": ["food", "dessert"],
    "keywords": ["cake", "sweet", "tasty"],
    "enabled": false
  },
  {
    "id": "8",
    "filename": "src/assets/images/users/avatar-8.jpg",
    "hashtags": ["travel", "adventure"],
    "keywords": ["explore", "journey", "discovery"],
    "enabled": true
  },
  {
    "id": "9",
    "filename": "src/assets/images/users/avatar-9.jpg",
    "hashtags": ["technology", "coding"],
    "keywords": ["programming", "computer", "innovation"],
    "enabled": true
  },
  {
    "id": "10",
    "filename": "image10.jpg",
    "hashtags": ["fitness", "health"],
    "keywords": ["workout", "exercise", "gym"],
    "enabled": false
  },
  {
    "id": "11",
    "filename": "image11.jpg",
    "hashtags": ["architecture", "building"],
    "keywords": ["design", "structure", "modern"],
    "enabled": true
  },
  {
    "id": "12",
    "filename": "image12.jpg",
    "hashtags": ["art", "painting"],
    "keywords": ["canvas", "colors", "creative"],
    "enabled": true
  },
  {
    "id": "13",
    "filename": "image13.jpg",
    "hashtags": ["music", "concert"],
    "keywords": ["band", "stage", "live"],
    "enabled": false
  },
  {
    "id": "14",
    "filename": "image14.jpg",
    "hashtags": ["fashion", "style"],
    "keywords": ["clothing", "runway", "trendy"],
    "enabled": true
  },
  {
    "id": "15",
    "filename": "image15.jpg",
    "hashtags": ["automobile", "racing"],
    "keywords": ["cars", "speed", "track"],
    "enabled": false
  },
  {
    "id": "16",
    "filename": "image16.jpg",
    "hashtags": ["space", "astronomy"],
    "keywords": ["stars", "galaxy", "universe"],
    "enabled": true
  },
  {
    "id": "17",
    "filename": "image17.jpg",
    "hashtags": ["photography", "camera"],
    "keywords": ["lens", "portrait", "capture"],
    "enabled": true
  },
  {
    "id": "18",
    "filename": "image18.jpg",
    "hashtags": ["garden", "flowers"],
    "keywords": ["bloom", "botany", "nature"],
    "enabled": false
  },
  {
    "id": "19",
    "filename": "image19.jpg",
    "hashtags": ["festival", "celebration"],
    "keywords": ["party", "fireworks", "joy"],
    "enabled": true
  },
  {
    "id": "20",
    "filename": "image20.jpg",
    "hashtags": ["coffee", "morning"],
    "keywords": ["caffeine", "cup", "breakfast"],
    "enabled": true
  },
  {
    "id": "21",
    "filename": "image21.jpg",
    "hashtags": ["cycling", "sports"],
    "keywords": ["bike", "ride", "exercise"],
    "enabled": false
  },
  {
    "id": "22",
    "filename": "image22.jpg",
    "hashtags": ["pets", "cute"],
    "keywords": ["dogs", "cats", "adorable"],
    "enabled": true
  },
  {
    "id": "23",
    "filename": "image23.jpg",
    "hashtags": ["reading", "books"],
    "keywords": ["literature", "knowledge", "study"],
    "enabled": true
  },
  {
    "id": "24",
    "filename": "image24.jpg",
    "hashtags": ["fitness", "yoga"],
    "keywords": ["meditation", "balance", "wellness"],
    "enabled": false
  },
  {
    "id": "25",
    "filename": "image25.jpg",
    "hashtags": ["history", "monuments"],
    "keywords": ["ancient", "heritage", "tourism"],
    "enabled": true
  },
  {
    "id": "26",
    "filename": "image26.jpg",
    "hashtags": ["sea", "boats"],
    "keywords": ["fishing", "sail", "marine"],
    "enabled": true
  },
  {
    "id": "27",
    "filename": "image27.jpg",
    "hashtags": ["sunrise", "morning"],
    "keywords": ["dawn", "start", "new day"],
    "enabled": false
  },
  {
    "id": "28",
    "filename": "image28.jpg",
    "hashtags": ["desert", "sand"],
    "keywords": ["dunes", "heat", "dry"],
    "enabled": true
  },
  {
    "id": "29",
    "filename": "image29.jpg",
    "hashtags": ["animals", "birds"],
    "keywords": ["eagle", "sky", "freedom"],
    "enabled": true
  },
  {
    "id": "30",
    "filename": "image30.jpg",
    "hashtags": ["kids", "playground"],
    "keywords": ["fun", "smile", "childhood"],
    "enabled": false
  }
];



const ImageManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [editingData, setEditingData] = useState<IimageManagement | null>(null);
  const [viewData, setViewData] = useState<IimageManagement | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();


  const { isLoading,isDeleting, imageManagement } = useSelector((state: RootState) => state.imageManagement);
  const dispatch: AppDispatch = useDispatch();



  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };


  const handleToggleEnabled = (id: string, enabled: boolean) => {
    console.log(`Record with id ${id} is now ${enabled ? 'enabled' : 'disabled'}`);
    
  };
  const columns: TableProps<IimageManagement>['columns'] = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 150,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsViewMode(true);
                setViewData(record);
              }}
            >
              <FiEye id="viewDetails" size={20} />
            </div>
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateImage" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="No"
                okText="Yes"
                okButtonId={`confirmAndDeleteImage`}
                triggerButtonId="deleteImage"
                onConfirm={() => {
                
                   console.log('Remove');
                }}


              />
            </div>
          </div>
        );
      },
    },

    {
      title: 'Image',
      key: 'filename',
      dataIndex: 'filename',
      width: 200,
      render: (filename) => (
        <div style={{ textAlign: 'center' }}>
          <img
            src={filename}
            alt="image"
            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
          />
          <div>{filename.split('/').pop()}</div> 
        </div>
      ),
    },
    {
      title: 'Hashtags',
      dataIndex: 'hashtags',
      key: 'hashtags',
      width: 200,
      render: (hashtags) => hashtags.join(', '), 
    },
    {
      title: 'Keywords',
      dataIndex: 'keywords',
      key: 'keywords',
      width: 200,
      render: (keywords) => keywords.join(', '), 
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      render: (enabled, record) => (
        <Switch
          checked={enabled}  
          onChange={(checked) => {
            handleToggleEnabled(record.id, checked);
          }}
        />
      ),
    },



  ];



  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };


  const deleteImage = async (imageId: string) => {
    if (!imageId) {
      openNotificationWithIcon('error', 'Did not find the image id');
      return;
    }
    try {
      const response = await dispatch(
        DeleteImageData({
          imageId,
        }),
      );
      if (response.payload === imageId) {
        openNotificationWithIcon('success', 'Image deleted successfully');
        dispatch(GetAllImageData());
      } else {
        openNotificationWithIcon('error', 'Error deleting image');
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };


  console.log('view data', viewData);
  console.log('editingData data', editingData);
  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>Image Management</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="Settings" breadcrumbItem="Image Management" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                          id="imageManagementSearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder="Search data by name"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                             id="createImageManagement"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> Add
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >


                      <TableView loading={false}  columns={columns} dataSource={ImageManagementTestData} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <ImageManagementModal
              isEditMode={isEditMode}
              initialValues={editingData}
              initialViewValues={viewData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              setEditingData={setEditingData}
              setViewData={setViewData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ImageManagement;
