import React from 'react';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { ITag } from 'src/utils/types/tagTypes';
/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, DatePickerProps, Form, Input, notification, Popconfirm, Select, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AppDispatch, RootState } from 'src/store';
import { Card, Space } from 'antd';
import { useEffect } from 'react';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { TableProps } from 'antd/lib';
import { AddAssignmentToTag, CreateTag, GetAllTags, UpdateTag } from 'src/slices/Tags/thunk';

interface ISpecialPeriodsProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: ITag | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: ITag | null) => void;
}
const TagsModal = ({
  isOpen,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: ISpecialPeriodsProps) => {
  const [form] = Form.useForm();
  const [assignmentForm] = Form.useForm();
  const { isCreating, isUpdating, isAddingAssignment, tags } = useSelector((state: RootState) => state.tags);
  const [assignments, setAssignments] = React.useState<{ year: string; week: string }[]>([]);
  const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] = React.useState(false);
  const [selectedYear, setSelectedYear] = React.useState<string | null>(null);
  const [selectedWeek, setSelectedWeek] = React.useState<string | null>(null);
  const { selectedProperty } = useSelector((state: RootState) => state.porperty);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      console.log('initialValues', initialValues);
      form.setFieldsValue(initialValues);
      if (initialValues) {
        setAssignments(
          initialValues?.assignments?.map((assignment) => ({
            year: assignment?.year?.toString(),
            week: assignment?.week?.toString(),
          })),
        );
      }
    }
  }, [initialValues, isEditMode]);

  const assignmentColumns: TableProps['columns'] = [
    {
      title: 'Action',
      dataIndex: 'x',
      key: 'x',
      width: 100,
      render: (_, _record, index) => (
        <>
          <Popconfirm
            title="Remove assignment"
            description="Are you sure you want to remove this ?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              setAssignments((prevAssignments) => prevAssignments.filter((_, i) => i !== index));
            }}
          >
            <IoIosRemoveCircleOutline
              size={20}
              color="red"
              style={{
                cursor: 'pointer',
              }}
            />
          </Popconfirm>
        </>
      ),
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: 100,
    },
    {
      title: 'Week',
      dataIndex: 'week',
      key: 'week',
      width: 100,
    },
  ];

  // ** Function to handle create or update
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: ITag | any = form.getFieldsValue();

    console.log(formData);

    try {
      if (isEditMode) {
        const payload = formData;
        payload.id = initialValues?.id;
        payload.propertyId = selectedProperty?.value?.toString();
        payload.assignments = assignments;
        if (!payload.propertyId) {
          openNotificationWithIcon('error', 'Property Id is required');
          return;
        }

        if (payload?.assignments.length === 0) {
          openNotificationWithIcon('error', `Please add at least one assignment to the tag.`);
          return;
        }

        const response = await dispatch(UpdateTag({ data: payload, tagId: initialValues?.id as string }));
        if ((response.payload as ITag)['id']) {
          openNotificationWithIcon('success', 'Tags Updated successfully');
          handleCancel();
          form.resetFields();
          setAssignments([]);
          dispatch(GetAllTags());
        } else {
          openNotificationWithIcon('error', `Error Updating Tags`);
        }
      } else {
        const payload = formData;
        payload.propertyId = selectedProperty?.value?.toString();
        payload.assignments = assignments;

        if (!payload.propertyId) {
          openNotificationWithIcon('error', 'Property Id is required');
          return;
        }
        // A tag with the name 'Hello World v1' already exists.

        if (tags.some((tag) => tag.name === payload.name)) {
          openNotificationWithIcon('error', `A tag with the name '${payload.name}' already exists.`);
          return;
        }

        if (payload?.assignments.length === 0) {
          openNotificationWithIcon('error', `Please add at least one assignment to the tag.`);
          return;
        }

        const response = await dispatch(CreateTag({ data: payload }));
        if ((response.payload as ITag)['id']) {
          openNotificationWithIcon('success', 'Tags created successfully');
          handleCancel();
          form.resetFields();
          setAssignments([]);
          dispatch(GetAllTags());
        } else {
          openNotificationWithIcon('error', `Error creating Tags`);
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', `Error ${isEditMode ? 'updating' : 'creating'} Notification Contact`);
    }
  };

  const handleAddAssignmentsToTag = () => {
    if (selectedYear && selectedWeek) {
      setAssignments((prevAssignments) => [
        ...prevAssignments,
        { year: selectedYear as string, week: selectedWeek as string },
      ]);

      setIsAssignmentsModalOpen(false);
      handleCancelForAssignments();
    } else {
      openNotificationWithIcon('error', 'Both year and week must be selected');
    }
  };
  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  const onWeekChange: DatePickerProps['onChange'] = (_date, dateString) => {
    const week = (dateString as string).split('-')[1];
    const realFormat = week?.replace(/(st|nd|rd|th)/, '');

    setSelectedWeek(realFormat as string);
  };

  const onYearChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setSelectedYear(dateString as string);
  };

  const handleCancelForAssignments = () => {
    setIsAssignmentsModalOpen(false);
    assignmentForm.resetFields();
    setSelectedWeek(null);
    setSelectedYear(null);
  };

  return (
    <div>
      {' '}
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? 'Update' : 'Create'} Tag
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name a tag name',
                },
              ]}
            >
              <Input id="tagName" placeholder="Please enter name a tag name" className="p-2 px-2" />
            </Form.Item>
            <Card
              title="Manage Assignments "
              extra={
                <Button id="openAssignmentModal" onClick={() => setIsAssignmentsModalOpen(true)}>
                  {
                    <>
                      <i className="uil uil-plus me-1"></i>
                    </>
                  }
                  Add Assignment
                </Button>
              }
              style={{ width: '100%' }}
            >
              <Table
                pagination={{
                  defaultPageSize: 100,
                  pageSizeOptions: [50, 100],
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
                scroll={{ y: 200 }}
                columns={assignmentColumns}
                dataSource={assignments}
              />
            </Card>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="tagsCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>
            <AsyncButton
              buttonText={isEditMode ? 'Update' : 'Create'}
              isEditMode={isEditMode}
              style={{ marginTop: 20 }}
              isLoading={isCreating || isUpdating}
              onClick={handleCreateOrUpdate}
              id="tagsCreateORUpdateButton"
            />
          </Col>
        </ModalBody>
      </Modal>
      <Modal
        style={{
          width: 300,
        }}
        centered
        isOpen={isAssignmentsModalOpen}
        toggle={() => setIsAssignmentsModalOpen(false)}
      >
        <ModalHeader toggle={() => setIsAssignmentsModalOpen(false)} tag="h5">
          Add Assignment
        </ModalHeader>
        <ModalBody>
          <Form layout="vertical" form={assignmentForm}>
            <Form.Item
              required={true}
              label="Year"
              name="year"
              rules={[
                {
                  required: true,
                  message: 'Please enter year',
                },
              ]}
            >
              <DatePicker
                id="selectYear"
                style={{
                  width: '100%',
                }}
                className="p-2"
                onChange={onYearChange}
                picker="year"
              />
            </Form.Item>
            <Form.Item
              required={true}
              label="Week"
              name="week"
              rules={[
                {
                  required: true,
                  message: 'Please enter week',
                },
              ]}
            >
              <DatePicker
                id="selectWeek"
                style={{
                  width: '100%',
                }}
                className="p-2"
                onChange={onWeekChange}
                picker="week"
              />
            </Form.Item>
          </Form>
          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="assignmentsCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancelForAssignments}
              style={{ marginTop: 10, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>
            <Button
              id="assignmentsAddButton"
              isLoading={isAddingAssignment}
              onClick={handleAddAssignmentsToTag}
              style={{ marginTop: 10, marginRight: 10, background: 'white', color: 'black' }}
            >
              Add
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default TagsModal;
