import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createWeatherConfigs,
  deleteWeatherConfigs, getWeatherConfig,
  getWeathersConfigs,
  updateWeatherConfigs,
} from 'src/slices/WeatherProvider/thunk';
import { IWeatherConfigsGetResponse, IWeatherOneConfig } from 'src/utils/types/weatherConfigurations';

interface IWeatherConfigsStateType {
  configs: Array<IWeatherConfigsGetResponse>,
  weatherConfig: IWeatherOneConfig | null
  error?: string | object | null | undefined | unknown,
  loading: boolean,
  configIsLoaded: boolean
}
export const initialState: IWeatherConfigsStateType = {
  configs: [],
  weatherConfig: null,
  error: '',
  loading: false,
  configIsLoaded: false
};

const weatherConfigsSlice = createSlice({
  name: "weather",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWeathersConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getWeathersConfigs.fulfilled, (state, action) => {
        state.configs = action.payload as any;
        state.loading = false;
      })

      .addCase(getWeatherConfig.pending, (state) => {
        state.configIsLoaded = true;
        state.error = null;
      })

      .addCase(getWeatherConfig.fulfilled, (state, action) => {
        state.weatherConfig = action.payload as any;
        state.configIsLoaded = false;
      })

      .addCase(createWeatherConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createWeatherConfigs.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateWeatherConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateWeatherConfigs.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(deleteWeatherConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteWeatherConfigs.fulfilled, (state) => {
        state.loading = false;
      })

      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  }

});

export default weatherConfigsSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
