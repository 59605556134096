import { MenuProps } from 'antd';

import { MdOutlineRoomPreferences } from 'react-icons/md';
import { MdEvent } from 'react-icons/md';
import { LuTableProperties } from 'react-icons/lu';
import { FaLocationCrosshairs } from 'react-icons/fa6';
import { IoSettingsOutline } from 'react-icons/io5';
import { GrSystem } from 'react-icons/gr';
import { IoNotificationsOutline } from 'react-icons/io5';
import { HiUserGroup } from 'react-icons/hi';
import { GrObjectGroup } from 'react-icons/gr';
import { RiKeyLine } from 'react-icons/ri';
import { VscUngroupByRefType } from 'react-icons/vsc';
import { MdOutlineDashboard } from 'react-icons/md';
import { FiActivity } from 'react-icons/fi';
import { MdOutlineAvTimer } from 'react-icons/md';
import { GrConnectivity } from 'react-icons/gr';
import { IoPricetagOutline } from 'react-icons/io5';
import { RiText } from 'react-icons/ri';
import { LuHotel } from 'react-icons/lu';

interface MenuItemsProps {
  id: number;
  novidade?: any;
  label: string;
  icon?: any;
  link?: string;
  badge?: string;
  badgecolor?: string;
  subItems?: any;
  isHeader?: boolean;
}

const menuItems: Array<MenuItemsProps> = [
  {
    id: 1,
    novidade: false,
    label: 'Dashboards',
    icon: MdOutlineDashboard,
    isHeader: false,
    subItems: [
      // {
      //   id: 11,
      //   novidade: false,
      //   label: 'Occupancy',
      //   icon: 'bar-chart',
      //   link: '/occupancy-prediction',
      // },
      // {
      //   id: 12,
      //   novidade: false,
      //   label: "Cancellation",
      //   icon: "bar-chart",
      //   link: "/cancellation-prediction",
      // },
      // {
      //   id: 13,
      //   novidade: false,
      //   label: 'Cancellation',
      //   icon: 'bar-chart',
      //   link: '/cancellation-prediction',
      // },
      // {
      //   id: 14,
      //   novidade: false,
      //   label: "Total Rate",
      //   icon: "bar-chart",
      //   link: "/total-rate-prediction",
      // }

      {
        id: 15,
        novidade: false,
        label: 'Anomaly',
        icon: FiActivity,
        link: '/anomaly',
      },
      {
        id: 16,
        novidade: false,
        label: 'Pricing',
        icon: FiActivity,
        link: '/pricing-prediction',
      },
      {
        id: 17,
        novidade: false,
        label: 'Occupancy Assistance',
        icon: FiActivity,
        link: '/occupancy-assistance',
      },
    ],
  },
  {
    id: 2,
    novidade: false,
    label: 'Settings',
    icon: IoSettingsOutline,
    isHeader: false,
    subItems: [
      {
        id: 22,
        novidade: false,
        label: 'Competitor Hotels',
        icon: LuHotel,
        link: '/competitor-hotels',
      },
      {
        id: 24,
        novidade: false,
        label: 'Notification Contacts',
        icon: IoNotificationsOutline,
        link: '/notification-contacts',
      },
      {
        id: 25,
        novidade: false,
        label: 'Room Plans',
        icon: MdOutlineRoomPreferences,
        link: '/room-plans',
      },
      {
        id: 26,
        novidade: false,
        label: 'Special Periods',
        icon: MdOutlineAvTimer,
        link: '/special-periods',
      },
      {
        id: 9,
        novidade: false,
        label: 'External Events',
        icon: MdEvent,
        link: '/external-events',
      },
      {
        id: 298,
        novidade: false,
        label: 'Tags',
        link: '/tags',
        icon: IoPricetagOutline,
      },
      {
        id: 299,
        novidade: false,
        label: 'Texts',
        link: '/texts',
        icon: RiText,
      },
      {
        id: 9,
        novidade: false,
        label: 'Properties',
        icon: LuTableProperties,
        link: '/properties',
      },
      {
        id: 7,
        novidade: false,
        label: 'Geographical Locations',
        icon: FaLocationCrosshairs,
        link: '/geographic-locations',
      },
      {
        id: 34,
        novidade: false,
        label: 'Manage Hotel',
        icon: FaLocationCrosshairs,
        link: '/manage-hotel',
      },
      {
        id: 35,
        novidade: false,
        label: 'Price List',
        icon: FaLocationCrosshairs,
        link: '/price-list',
      },
      {
        id: 39,
        novidade: false,
        label: 'Meal Plans',
        icon: FaLocationCrosshairs,
        link: '/meal-plans',
      },
      {
        id: 40,
        novidade: false,
        label: 'Image Management',
        icon: FaLocationCrosshairs,
        link: '/image-management',
      },
    ],
  },
  {
    id: 33,
    novidade: false,
    label: 'System Settings',
    icon: GrSystem,
    isHeader: false,
    subItems: [
      {
        id: 21,
        novidade: false,
        label: 'Tenants',
        icon: VscUngroupByRefType,
        link: '/tenants-list',
      },
      {
        id: 31,
        novidade: false,
        label: 'Users',
        icon: HiUserGroup,
        link: '/users',
      },
      {
        id: 313,
        novidade: false,
        label: 'Groups',
        icon: GrObjectGroup,
        link: '/groups',
      },
      {
        id: 45,
        novidade: false,
        label: 'Api-Keys',
        icon: RiKeyLine,
        link: '/api-keys',
      },
      {
        id: 46,
        novidade: false,
        label: 'External Api Keys',
        icon: GrConnectivity,
        link: '/external-api-keys',
      },
      // {
      //   id: 63,
      //   novidade: false,
      //   label: 'Import Providers',
      //   icon: 'sliders',
      //   link: '/import-providers',
      // },
      // {
      //   id: 83,
      //   novidade: false,
      //   label: 'Weather Providers',
      //   icon: 'cloud-lightning',
      //   link: '/weather-providers',
      // },
    ],
  },
];

const convertMenuItems = (items: Array<MenuItemsProps>) => {
  return items.map((item) => {
    return {
      key: item.id.toString(),
      icon: item.icon,
      label: item.label,
      children: item.subItems?.map((subItem: any) => {
        return {
          key: subItem.id.toString(),
          label: subItem.label,
          icon: subItem.icon,
        };
      }),
    };
  });
};

const items2: MenuProps['items'] = convertMenuItems(menuItems);

export { menuItems, items2 };
