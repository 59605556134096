import React, { useImperativeHandle, useState } from 'react';

import { Form, Select, DatePicker, Slider, InputNumber, Checkbox, Tabs } from 'antd';

const { RangePicker } = DatePicker;

const roomTypes = [
  // 'Tafel',
  // 'Kühtrote',
  // 'Weiher',
  // 'In der Kehr',
  // 'Widum',
  // 'Colbedoi',
  // 'Waldile',
  // 'Oberanger',
  // 'Rit  - 3-Bettzimmer und Schlafcouch',
  // 'Panorama-Doppelzimmer mit Terrasse',
  // 'Doppelzimmer ohne Balkon',
  // 'Doppelzimmer mit Balkon',
  // 'Tiroler Stube',
  // 'Gemütliches Einzelzimmer',
  // 'Col de Scofes - 4-Bett Wohnung mit Schlafcouch',
  // 'Ciamaor - 3-Bettzimmer und Schlafcouch',
  // 'Cianross  - 3-Bettzimmer und Schlafcouch',
  // 'Les Corceles - 2-Bett-Zimmer und Schlafcouch',
  // 'Überholtes Zimmer',
  // 'Urlaub',
  // 'Mountain Home Family',
  // 'Mountain Home Deluxe',
  // 'Mountain Home Basic ',
  // 'Kategorie Ingwer',
  // 'Kategorie Rosmarin',
  // 'Mountain Home DZ',
  // 'Kellerwohnung',
  // 'S_14.30 - 17 Uhr',
  // 'S_18-20.30 Uhr',

  'Apartment',
];

// ** Room Type ;
const roomTypesArray = ['Apartment', 'Resting Place', 'Room', 'Holiday Home'];

// ** weather Type ;
const weathers = ['Sunny', 'Rainy', 'Cold', 'Hot', 'Too Hot', 'Humid'];

// ** Reservation Type ;
const reservationTypesArray = ['Reservation', 'Request'];

// ** Meal Plans
const mealPlansArray = ['Overnight Stay', 'Bed and Breakfast', 'Half Board', 'Full Board', 'All Inclusive'];

const regionsArray = ['North America', 'Europe', 'Asia', 'Africa', 'Middle East', 'South America', 'Oceania'];

interface IUserInputForm {
  roomType: string;
  totalRate: number;
  discountRate: number;
  weatherCondition: string;
  dateRange: [Date, Date];
  minOccupancy: number;
  maxOccupancy: number;
  offer: string[];
}

interface RoomTypes {
  IsApartment: boolean;
  IsRestingPlace: boolean;
  IsRoom: boolean;
  IsHolidayHome: boolean;
}

interface IWeather {
  IsSunny: boolean;
  IsRainy: boolean;
  IsCold: boolean;
  IsHot: boolean;
  IsTooHot: boolean;
  IsHumid: boolean;
}

interface ReservationTypes {
  IsReservation: boolean;
  IsRequest: boolean;
}

interface MealPlans {
  IsOvernightStay: boolean;
  IsBedAndBreakfast: boolean;
  IsHalfBoard: boolean;
  IsFullBoard: boolean;
  IsAllInclusive: boolean;
}

interface Regions {
  IsNorthAmerica: boolean;
  IsEurope: boolean;
  IsAsia: boolean;
  IsAfrica: boolean;
  IsMiddleEast: boolean;
  IsSouthAmerica: boolean;
  IsOceania: boolean;
}

interface IUserInputFormHandleV1 {
  fetchValues: () => Promise<IUserInputForm>;
  mealPlans: MealPlans;
  roomTypes: RoomTypes;
  weathers: IWeather;
  regions: Regions;
  reservationTypes: ReservationTypes;
  isRoomInternal: boolean;
}
interface IUserInputFormProps {
  callFunction: () => void;
}

const UserInputFormV1 = React.forwardRef<IUserInputFormHandleV1, IUserInputFormProps>(({ callFunction }, ref) => {
  const [form] = Form.useForm<IUserInputForm>();
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [selectedWeather, setSelectedWeather] = useState(null);
  const [isRoomInternal, setIsRoomInternal] = useState(false);
  const [selectedReservationType, setSelectedReservationType] = useState(null);
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [roomTypes, setRoomTypes] = useState({
    IsApartment: false,
    IsRestingPlace: false,
    IsRoom: false,
    IsHolidayHome: false,
  });
  const [weather, setWeather] = useState<IWeather>({
    IsSunny: false,
    IsRainy: false,
    IsCold: false,
    IsHot: false,
    IsTooHot: false,
    IsHumid: false,
  });
  const [reservationTypes, setReservationTypes] = useState({
    IsReservation: false,
    IsRequest: false,
  });
  const [mealPlans, setMealPlans] = useState({
    IsOvernightStay: false,
    IsBedAndBreakfast: false,
    IsHalfBoard: false,
    IsFullBoard: false,
    IsAllInclusive: false,
  });
  const [regions, setRegions] = useState({
    IsNorthAmerica: false,
    IsEurope: false,
    IsAsia: false,
    IsAfrica: false,
    IsMiddleEast: false,
    IsSouthAmerica: false,
    IsOceania: false,
  });

  const handleCheckboxChange = (e: any) => {
    setIsRoomInternal(e.target.checked);
  };

  // **  for room type change
  const handleRoomTypeChange = (selectedOption: any) => {
    console.log(selectedOption);
    // Reset all values to false
    const updatedRoomTypes = {
      IsApartment: false,
      IsRestingPlace: false,
      IsRoom: false,
      IsHolidayHome: false,
    };

    // Set the corresponding room type to true based on the selected option
    if (selectedOption === 'Apartment') {
      updatedRoomTypes.IsApartment = true;
    } else if (selectedOption === 'Resting Place') {
      updatedRoomTypes.IsRestingPlace = true;
    } else if (selectedOption === 'Room') {
      updatedRoomTypes.IsRoom = true;
    } else if (selectedOption === 'Holiday Home') {
      updatedRoomTypes.IsHolidayHome = true;
    }

    // Update state
    setRoomTypes(updatedRoomTypes);
    setSelectedRoomType(selectedOption);
  };

  // ** for weather
  const handleWeatherChange = (selectedOption: any) => {
    const weather = {
      IsSunny: false,
      IsRainy: false,
      IsCold: false,
      IsHot: false,
      IsTooHot: false,
      IsHumid: false,
    };

    // set the corresponding weather to true based on selected option
    if (selectedOption === 'Sunny') {
      weather.IsSunny = true;
    } else if (selectedOption === 'Rainy') {
      weather.IsRainy = true;
    } else if (selectedOption === 'Cold') {
      weather.IsCold = true;
    } else if (selectedOption === 'Hot') {
      weather.IsHot = true;
    } else if (selectedOption === 'Too Hot') {
      weather.IsTooHot = true;
    } else if (selectedOption === 'Humid') {
      weather.IsHumid = true;
    }

    // Update state
    setWeather(weather);
    setSelectedWeather(selectedOption);
  };

  // **  for reservation type change
  const handleReservationTypeChange = (selectedOption: any) => {
    // Reset all values to false
    const updatedReservationTypes = {
      IsReservation: false,
      IsRequest: false,
    };

    // Set the corresponding reservation type to true based on the selected option
    if (selectedOption === 'Reservation') {
      updatedReservationTypes.IsReservation = true;
    } else if (selectedOption === 'Request') {
      updatedReservationTypes.IsRequest = true;
    }

    // Update state
    setReservationTypes(updatedReservationTypes);
    setSelectedReservationType(selectedOption);
  };

  // **  for meal plan change
  const handleMealPlanChange = (selectedOption: any) => {
    // Reset all values to false
    const updatedMealPlans = {
      IsOvernightStay: false,
      IsBedAndBreakfast: false,
      IsHalfBoard: false,
      IsFullBoard: false,
      IsAllInclusive: false,
    };

    // Set the corresponding meal plan to true based on the selected option
    if (selectedOption === 'Overnight Stay') {
      updatedMealPlans.IsOvernightStay = true;
    } else if (selectedOption === 'Bed and Breakfast') {
      updatedMealPlans.IsBedAndBreakfast = true;
    } else if (selectedOption === 'Half Board') {
      updatedMealPlans.IsHalfBoard = true;
    } else if (selectedOption === 'Full Board') {
      updatedMealPlans.IsFullBoard = true;
    } else if (selectedOption === 'All Inclusive') {
      updatedMealPlans.IsAllInclusive = true;
    }

    // Update state
    setMealPlans(updatedMealPlans);
    setSelectedMealPlan(selectedOption);
  };

  // **  for region change

  const handleRegionChange = (selectedOption: any) => {
    // Reset all values to false
    const updatedRegions = {
      IsNorthAmerica: false,
      IsEurope: false,
      IsAsia: false,
      IsAfrica: false,
      IsMiddleEast: false,
      IsSouthAmerica: false,
      IsOceania: false,
    };

    // Set the corresponding region to true based on the selected option
    if (selectedOption === 'North America') {
      updatedRegions.IsNorthAmerica = true;
    } else if (selectedOption === 'Europe') {
      updatedRegions.IsEurope = true;
    } else if (selectedOption === 'Asia') {
      updatedRegions.IsAsia = true;
    } else if (selectedOption === 'Africa') {
      updatedRegions.IsAfrica = true;
    } else if (selectedOption === 'Middle East') {
      updatedRegions.IsMiddleEast = true;
    } else if (selectedOption === 'South America') {
      updatedRegions.IsSouthAmerica = true;
    } else if (selectedOption === 'Oceania') {
      updatedRegions.IsOceania = true;
    }

    // Update state
    setRegions(updatedRegions);
    setSelectedRegion(selectedOption);
  };

  const fetchValues = async () => {
    await form.validateFields();
    return await form.getFieldsValue();
  };

  useImperativeHandle(ref, () => ({
    fetchValues: async () => await fetchValues(),
    mealPlans: mealPlans,
    roomTypes: roomTypes,
    regions: regions,
    reservationTypes: reservationTypes,
    isRoomInternal: isRoomInternal,
    weathers: weather,
  }));

  const stringArrayToOptions = (array: string[]) => {
    return array.map((item) => ({ label: item, value: item }));
  };

  const mainForm = (
    <>
      <Form layout="vertical" form={form}>
        <div style={{ display: 'flex' }}>
          <Form.Item
            className="menu-item"
            required
            label={<span style={{ color: '#7b8190' }}>Room Type</span>}
            name="roomType"
            style={{ width: '30%' }}
            rules={[{ required: true, message: 'Please select a room type' }]}
          >
            <Select
              placeholder="Select room type"
              showSearch
              options={stringArrayToOptions(roomTypesArray)}
              value={selectedRoomType}
              onChange={handleRoomTypeChange}
            />
          </Form.Item>

          <Form.Item
            label={<span style={{ color: '#7b8190' }}>Date Range</span>}
            required
            name="dateRange"
            rules={[{ required: true, message: 'Please select date range' }]}
            style={{ width: '30%', marginLeft: '0.5rem' }}
          >
            <RangePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Please add total rate' }]}
            style={{ width: '30%', marginLeft: '0.5rem' }}
            required
            label={<span style={{ color: '#7b8190' }}>Total Rate</span>}
            name="totalRate"
          >
            <InputNumber placeholder="Total rate" style={{ width: '100%' }} />
          </Form.Item>

          <div style={{ alignContent: 'center', width: '25%', marginLeft: '0.5rem' }}>
            <button className="btn btn-primary" onClick={callFunction}>
              Process
            </button>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>{/* <di */}</div>
      </Form>
    </>
  );

  const AdvancedForm = (
    <>
      <Form layout="vertical" form={form}>
        <div style={{ display: 'flex' }}>
          <Form.Item
            label={<span style={{ color: '#7b8190' }}>Weather Condition</span>}
            style={{ width: '25%', marginLeft: '0.5rem' }}
            name="weatherCondition"
          >
            <Select
              value={selectedWeather}
              onChange={handleWeatherChange}
              showSearch
              options={stringArrayToOptions(weathers)}
              placeholder="Select weather type"
            />
          </Form.Item>
          <Form.Item
            required
            style={{ width: '25%', marginLeft: '0.5rem' }}
            name="minOccupancy"
            label={<span style={{ color: '#7b8190' }}>Meal Plan</span>}
            rules={[{ required: true, message: 'Please select meal plan' }]}
          >
            <Select
              placeholder="Select meal plan"
              showSearch
              options={stringArrayToOptions(mealPlansArray)}
              value={selectedMealPlan}
              onChange={handleMealPlanChange}
            />
          </Form.Item>

          <div style={{ alignContent: 'center', width: '25%', marginLeft: '0.5rem' }}>
            <button className="btn btn-primary" onClick={callFunction}>
              Process
            </button>
          </div>
        </div>
      </Form>
    </>
  );

  return (
    <div>
      {/* <Tabs
        defaultActiveKey="1"
        type="card"
        size={'small'}
        items={[
          {
            label: `Main`,
            key: '1',
            children: mainForm,
          },
          {
            label: `Advanced`,
            key: '2',
            children: AdvancedForm,
          },
        ]}
      /> */}
      <div style={{ border: '1px solid #e5e7eb', borderRadius: '0.375rem', position: 'relative', marginTop: '2rem' }}>
        <div
          style={{
            position: 'absolute',
            top: '-1rem',
            left: '1.5rem',
            padding: '0.5rem',
            backgroundColor: 'white',
            fontWeight: '500',
            fontSize: '1rem',
          }}
        >
          Main
        </div>
        <div style={{ marginTop: '1.25rem', padding: '0.75rem' }}>
          <Form layout="vertical" form={form}>
            <div style={{ display: 'flex' }}>
              <Form.Item
                style={{ width: '30%' }}
                className="menu-item"
                required
                label={<span style={{ color: '#7b8190' }}>Room Type</span>}
                name="roomType"
                rules={[{ required: true, message: 'Please select a room type' }]}
              >
                <Select
                  placeholder="Select room type"
                  showSearch
                  options={stringArrayToOptions(roomTypesArray)}
                  value={selectedRoomType}
                  onChange={handleRoomTypeChange}
                />
              </Form.Item>

              <Form.Item
                style={{ width: '30%', marginLeft: '0.5rem' }}
                label={<span style={{ color: '#7b8190' }}>Date Range</span>}
                required
                name="dateRange"
                rules={[{ required: true, message: 'Please select date range' }]}
              >
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                style={{ width: '30%', marginLeft: '0.5rem' }}
                rules={[{ required: true, message: 'Please add total rate' }]}
                required
                label={<span style={{ color: '#7b8190' }}>Total Rate</span>}
                name="totalRate"
              >
                <InputNumber placeholder="Total rate" style={{ width: '100%' }} />
              </Form.Item>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>{/* <di */}</div>
          </Form>
        </div>
      </div>
      <div style={{ border: '1px solid #e5e7eb', borderRadius: '0.375rem', position: 'relative', marginTop: '2rem' }}>
        <div
          style={{
            position: 'absolute',
            top: '-1rem',
            left: '1.5rem',
            padding: '0.5rem',
            backgroundColor: 'white',
            fontWeight: '500',
            fontSize: '1rem',
          }}
        >
          Advanced
        </div>
        <div style={{ marginTop: '1.25rem', padding: '0.75rem' }}>
          <Form layout="vertical" form={form}>
            <div style={{ display: 'flex' }}>
              <Form.Item
                label={<span style={{ color: '#7b8190' }}>Weather Condition</span>}
                style={{ width: '25%', marginLeft: '0.5rem' }}
                name="weatherCondition"
              >
                <Select
                  value={selectedWeather}
                  onChange={handleWeatherChange}
                  showSearch
                  options={stringArrayToOptions(weathers)}
                  placeholder="Select weather type"
                />
              </Form.Item>
              <Form.Item
                required
                style={{ width: '25%', marginLeft: '0.5rem' }}
                name="minOccupancy"
                label={<span style={{ color: '#7b8190' }}>Meal Plan</span>}
                rules={[{ required: true, message: 'Please select meal plan' }]}
              >
                <Select
                  placeholder="Select meal plan"
                  showSearch
                  options={stringArrayToOptions(mealPlansArray)}
                  value={selectedMealPlan}
                  onChange={handleMealPlanChange}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <div style={{ alignContent: 'center', width: '25%', marginTop: '0.5rem' }}>
        <button className="btn btn-primary" onClick={callFunction}>
          Process
        </button>
      </div>

      {/* <Form layout="vertical" form={form}>
        <div style={{ display: 'flex' }}>
          <Form.Item
            className="menu-item"
            required
            label={<span style={{ color: '#7b8190' }}>Room Type</span>}
            name="roomType"
            style={{ width: '25%' }}
            rules={[{ required: true, message: 'Please select a room type' }]}
          >
            <Select
              placeholder="Select room type"
              showSearch
              options={stringArrayToOptions(roomTypesArray)}
              value={selectedRoomType}
              onChange={handleRoomTypeChange}
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: '#7b8190' }}>Weather Condition</span>}
            style={{ width: '25%', marginLeft: '0.5rem' }}
            name="weatherCondition"
          >
            <Select
              value={selectedWeather}
              onChange={handleWeatherChange}
              showSearch
              options={stringArrayToOptions(weathers)}
              placeholder="Select weather type"
            />
          </Form.Item>
          <Form.Item
            required
            name="Reservation"
            style={{ width: '25%', marginLeft: '0.5rem' }}
            label={<span style={{ color: '#7b8190' }}>Reservation Type</span>}
            rules={[{ required: true, message: 'Please reservation type' }]}
          >
            <Select
              placeholder="Select reservation type"
              showSearch
              options={stringArrayToOptions(reservationTypesArray)}
              value={selectedReservationType}
              onChange={handleReservationTypeChange}
            />
          </Form.Item>
          <Form.Item
            required
            style={{ width: '25%', marginLeft: '0.5rem' }}
            name="minOccupancy"
            label={<span style={{ color: '#7b8190' }}>Meal Plan</span>}
            rules={[{ required: true, message: 'Please select meal plan' }]}
          >
            <Select
              placeholder="Select meal plan"
              showSearch
              options={stringArrayToOptions(mealPlansArray)}
              value={selectedMealPlan}
              onChange={handleMealPlanChange}
            />
          </Form.Item>
        </div>

        <div style={{ display: 'flex' }}>
          <Form.Item
            label={<span style={{ color: '#7b8190' }}>Date Range</span>}
            required
            name="dateRange"
            rules={[{ required: true, message: 'Please select date range' }]}
            style={{ width: '25%' }}
          >
            <RangePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Please add total rate' }]}
            style={{ width: '25%', marginLeft: '0.5rem' }}
            required
            label={<span style={{ color: '#7b8190' }}>Total Rate</span>}
            name="totalRate"
          >
            <InputNumber placeholder="Total rate" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            style={{ width: '25%', marginLeft: '0.5rem' }}
            label={<span style={{ color: '#7b8190' }}>Discount Rate</span>}
            required
            rules={[{ required: true, message: 'Please add discount rate' }]}
            name="discountRate"
          >
            <InputNumber placeholder="Discount rate" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            required
            style={{ width: '25%', marginLeft: '0.5rem' }}
            label={<span style={{ color: '#7b8190' }}>Region</span>}
            name="Region"
            rules={[{ required: true, message: 'Please select a region' }]}
          >
            <Select
              placeholder="Select region"
              showSearch
              options={stringArrayToOptions(regionsArray)}
              value={selectedRegion}
              onChange={handleRegionChange}
            />
          </Form.Item>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <div style={{ display: 'flex', width: '100%', alignContent: 'center' }}>
            <Form.Item
              rules={[{ required: true, message: 'Please add room size' }]}
              style={{ width: '25%' }}
              required
              label={<span style={{ color: '#7b8190' }}>Rome size</span>}
              name="RoomSize"
            >
              <InputNumber placeholder="Rome size" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              // rules={[{ required: true, message: 'Please add room size' }]}
              style={{ width: '25%', marginLeft: '1.3rem', marginTop: '41px', flexWrap: 'wrap' }}
              // required
              // label={<span style={{ color: '#7b8190' }}>Rome Internal</span>}
              name="IsRoomInternal"
            >
              <Checkbox checked={isRoomInternal} onChange={handleCheckboxChange} style={{ color: '#7b8190' }}>
                Room Internal
              </Checkbox>
            </Form.Item>
          </div>
          <div style={{ alignContent: 'center' }}>
            <button className="btn btn-primary" onClick={callFunction}>
              Process
            </button>
          </div>
        </div>
      </Form> */}
    </div>
  );
});

export { UserInputFormV1 };
export type { IUserInputFormHandleV1, IUserInputForm };
