import React from 'react';
// @ts-ignore
import { Column } from '@ant-design/plots';

interface ChartProps {
  keys: string[];
  values: string[];
}

const Chart: React.FC<ChartProps> = ({ keys, values }: ChartProps) => {
  const transformedData = keys.map((key: any, i: number) => ({ key: key.split(" ")[0], value: values[i] }))

  const config = {
    xField: 'key',
    yField: 'value',
    data: transformedData,
    label: {
      text: ({ value }: any) => `${value.toFixed(2)} %`,
      textBaseline: 'bottom',
    },
    style: {
      maxWidth: 50,
      radiusTopLeft: 10,
    },
  };

  return <Column {...config} />
};

const defaultChartValue: ChartProps = {
  keys: [],
  values: [],
};

export default Chart;
export type { ChartProps };
export { defaultChartValue }