import axios from 'axios';
import env from 'src/env';
import { IRoomConfiguration } from 'src/utils/types/roomConfigurationsTypes';

const baseURL = env.VITE_ROOM_CONFIGURATIONS_BASE_URL();
const roomPlaneBaseUrl = '/room-configurations';

const getAllRoomConfigurations = async (): Promise<IRoomConfiguration[] | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IRoomConfiguration[] = await axios.get(`${roomPlaneBaseUrl}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching all room configurations:', error);
    throw error;
  }
};

const getRoomConfigurationById = async (roomConfigurationId: string): Promise<IRoomConfiguration | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IRoomConfiguration = await axios.get(`${roomPlaneBaseUrl}/${roomConfigurationId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching room configuration by ID:', error);
    throw error;
  }
};

const createRoomConfiguration = async (roomConfiguration: IRoomConfiguration): Promise<IRoomConfiguration | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IRoomConfiguration = await axios.post(`${roomPlaneBaseUrl}`, roomConfiguration, {
      headers,
      baseURL,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.error('Error creating room configuration:', error);
    throw error;
  }
};

const updateRoomConfiguration = async (roomConfiguration: IRoomConfiguration): Promise<IRoomConfiguration | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IRoomConfiguration = await axios.put(
      `${roomPlaneBaseUrl}/${roomConfiguration.id}`,
      roomConfiguration,
      {
        headers,
        baseURL,
      },
    );
    return response;
  } catch (error) {
    console.error('Error updating room configuration:', error);
    throw error;
  }
};

const deleteRoomConfiguration = async (roomConfigurationId: string): Promise<string | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    await axios.delete(`${roomPlaneBaseUrl}/${roomConfigurationId}`, { headers, baseURL });
    return roomConfigurationId;
  } catch (error) {
    console.error('Error deleting room configuration:', error);
    throw error;
  }
};

export const roomPlanService = {
  getAllRoomConfigurations,
  getRoomConfigurationById,
  createRoomConfiguration,
  updateRoomConfiguration,
  deleteRoomConfiguration,
};
