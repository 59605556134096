import { createSlice } from '@reduxjs/toolkit';
import { HotelData } from 'src/utils/types/HotelData';
import {
  ReadHotelCompetitors,
  CreateCompetitorHotelEntry,
  DeleteCompetitorHotelEntry,
  ReadSpecificHotelCompetitor,
  UpdateCompetitorHotelEntry,
} from './thunk';

interface ICompetitorHotelsState {
  competitorHotels: HotelData[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: ICompetitorHotelsState = {
  competitorHotels: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const competitorHotelsSlice = createSlice({
  name: 'competitorHotels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ReadHotelCompetitors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ReadHotelCompetitors.fulfilled, (state, action) => {
        state.competitorHotels = action.payload as HotelData[];
        state.isLoading = false;
      })
      .addCase(ReadHotelCompetitors.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(ReadSpecificHotelCompetitor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ReadSpecificHotelCompetitor.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(ReadSpecificHotelCompetitor.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateCompetitorHotelEntry.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateCompetitorHotelEntry.fulfilled, (state, action) => {
        state.competitorHotels.push(action.payload as HotelData);
        state.isCreating = false;
      })
      .addCase(CreateCompetitorHotelEntry.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateCompetitorHotelEntry.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateCompetitorHotelEntry.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateCompetitorHotelEntry.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      })
      .addCase(DeleteCompetitorHotelEntry.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteCompetitorHotelEntry.fulfilled, (state, action) => {
        const deletedHotelId = action.payload as number;
        state.competitorHotels = state.competitorHotels.filter((hotel) => hotel.id !== deletedHotelId);
        state.isDeleting = false;
      })
      .addCase(DeleteCompetitorHotelEntry.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isDeleting = false;
      });
  },
});

export default competitorHotelsSlice.reducer;
