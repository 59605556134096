import { createSlice } from '@reduxjs/toolkit';
import { IExternalApiKeys } from 'src/utils/types/externalApiKeysTypes';
import {
  CreateExternalApiKey,
  DeleteExternalApiKey,
  GetAllExternalApiKeys,
  GetSingleExternalApiKey,
  UpdateExternalApiKey,
} from './thunk';

interface IExternalApiKeysState {
  externalApiKeys: IExternalApiKeys[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IExternalApiKeysState = {
  externalApiKeys: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const externalApiKeysSlice = createSlice({
  name: 'externalApiKeys',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllExternalApiKeys.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllExternalApiKeys.fulfilled, (state, action) => {
        state.externalApiKeys = action.payload as [];
        state.isLoading = false;
      })
      .addCase(GetAllExternalApiKeys.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleExternalApiKey.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleExternalApiKey.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleExternalApiKey.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateExternalApiKey.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateExternalApiKey.fulfilled, (state, action) => {
        state.externalApiKeys.push(action.payload as IExternalApiKeys);
        state.isCreating = false;
      })
      .addCase(CreateExternalApiKey.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateExternalApiKey.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateExternalApiKey.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateExternalApiKey.rejected, (state) => {
        state.isUpdating = false;
      })
      .addCase(DeleteExternalApiKey.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteExternalApiKey.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(DeleteExternalApiKey.rejected, (state) => {
        state.isDeleting = false;
      });
  },
});

export default externalApiKeysSlice.reducer;
