import axios from 'axios';
import env from 'src/env';
import { IExternalApiKeys } from 'src/utils/types/externalApiKeysTypes';

const baseURL = env.VITE_EXTERNAL_API_KEY_BASE_URL();
const externalApiKeysBaseUrl = '/api/ExternalApiKeys';

//api.dev.externalapikeys.data.projects.excitingdevs.com/api/ExternalApiKeys

const getExternalApiKeys = async (): Promise<IExternalApiKeys[] | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IExternalApiKeys[] = await axios.get(`${externalApiKeysBaseUrl}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching all external api keys:', error);
    throw error;
  }
};

const getSingleExternalApiKey = async (externalApiKeyId: string): Promise<IExternalApiKeys | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IExternalApiKeys = await axios.get(`${externalApiKeysBaseUrl}/${externalApiKeyId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching single external api key:', error);
    throw error;
  }
};

const createExternalApiKey = async (data: IExternalApiKeys): Promise<IExternalApiKeys | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IExternalApiKeys = await axios.post(`${externalApiKeysBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating external api key:', error);
    throw error;
  }
};

const updateExternalApiKey = async (
  externalApiKeyId: string,
  data: IExternalApiKeys,
): Promise<IExternalApiKeys | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IExternalApiKeys = await axios.put(`${externalApiKeysBaseUrl}/${externalApiKeyId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating external api key:', error);
    throw error;
  }
};

const deleteExternalApiKey = async (externalApiKeyId: string): Promise<IExternalApiKeys | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IExternalApiKeys = await axios.delete(`${externalApiKeysBaseUrl}/${externalApiKeyId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error deleting external api key:', error);
    throw error;
  }
};

export const ExternalApiKeysService = {
  getExternalApiKeys,
  getSingleExternalApiKey,
  createExternalApiKey,
  updateExternalApiKey,
  deleteExternalApiKey,
};
