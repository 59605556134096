import { createAsyncThunk } from '@reduxjs/toolkit';
import { createUser, editUser, getAllUsers } from 'src/utils/services/service/userService';
import { ICreateUser, IEditUser } from 'src/utils/types/userTypes';

/**
 * Async thunk action for fetching users.
 * Utilizes `getAllUsers` service and handles errors by rejecting the thunk with the error message.
 */
export const getUsers = createAsyncThunk('users/getUsers', async (data, { rejectWithValue }) => {
  try {
    const response = await getAllUsers(data);
    return response;
  } catch (error) {
    // Check if error is an instance of Error and has a message
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    } else {
      // Fallback error message if the caught error is not an Error instance
      return rejectWithValue('An unexpected error occurred');
    }
  }
});

/**
 * Async thunk action for creating a new user.
 * Utilizes `createUser` service and propagates errors properly using `rejectWithValue`.
 */
export const createNewUser = createAsyncThunk(
  'users/createNewUser',
  async ({ data, tenant }: { data: ICreateUser; tenant: string }, { rejectWithValue }) => {
    try {
      const response = await createUser(data, tenant);
      return response;
    } catch (error) {
      // Check if error is an instance of Error and has a message
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        // Fallback error message if the caught error is not an Error instance
        return rejectWithValue('An unexpected error occurred');
      }
    }
  },
);

/**
 * Async thunk action for editing an existing user's fields.
 * Utilizes `editUser` service and ensures errors are handled and propagated using `rejectWithValue`.
 */
export const changeUserFields = createAsyncThunk(
  'users/changeUserFields',
  async (data: IEditUser, { rejectWithValue }) => {
    try {
      const response = await editUser(data);
      return response;
    } catch (error) {
      // Check if error is an instance of Error and has a message
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        // Fallback error message if the caught error is not an Error instance
        return rejectWithValue('An unexpected error occurred');
      }
    }
  },
);
