import env from 'src/env';
import api from '../axiosConfig';

const baseUrl = env.VITE_REACT_APP_DEFAULTAUTH();

export const addTenant = async (name: string) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await api.post(
    'Tenant',
    {
      tenantName: name,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return res.data;
};

export const getTenants = async () => {
  try {
    const headers = {
      Authorization: null,
    };

    return await api.get('/Tenant', { headers });
  } catch (error) {
    console.error('Error in GetTenants', error);
    throw error;
  }
};

export const getPropertiesForTenants = async () => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await api.get('/data/properties/GetPropertiesForTenant', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      baseUrl,
    },
  });

  return res.data;
};

export const getAssignProperties = async (tenantName: string) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await api.get(`/data/properties/GetPropertiesForTenant?tenant=${tenantName}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      baseUrl,
    },
  });

  return res.data;
};

export const getUnAssignProperties = async () => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await api.get('/data/properties/GetPropertiesForTenant?anonymous=true', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      baseUrl,
    },
  });

  return res.data;
};

export const AssignProperties = async (data: string[], tenantName: string) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await api.post(`/data/properties/link?tenant=${tenantName}&operation=assign`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      baseUrl,
    },
  });

  return res.data;
};

export const UnAssignProperties = async (data: string[], tenantName: string) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await api.post(`/data/properties/link?tenant=${tenantName}&operation=unassign`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      baseUrl,
    },
  });

  return res.data;
};

// https://api.auth.dev.data.projects.excitingdevs.com/data/properties/link?tenant=SiMedia&operation=assign
