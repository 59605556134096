//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import env from 'src/env';

// action
import { profileSuccess, profileError } from './reducer';

import { postFakeProfile, postJwtProfile } from '../../../helpers/fakebackend_helper';

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteProfilePicture,
  getProfilePicture,
  upLoadProfilePicture,
} from 'src/utils/services/service/profileService';

const fireBaseBackend = getFirebaseBackend();

export const editProfile = (user: any) => async (dispatch: any) => {
  try {
    let response;

    if (env.VITE_REACT_APP_DEFAULTAUTH() === 'firebase') {
      response = fireBaseBackend.editProfileAPI(user.username, user.idx);
    } else if (env.VITE_REACT_APP_DEFAULTAUTH() === 'jwt') {
      response = postJwtProfile({
        username: user.username,
        idx: user.idx,
      });
    } else if (env.VITE_REACT_APP_DEFAULTAUTH() === 'fake') {
      response = postFakeProfile({
        username: user.username,
        idx: user.idx,
      });
    }

    const data = await response;

    if (data) {
      dispatch(profileSuccess(data));
    }
  } catch (error) {
    dispatch(profileError(error));
  }
};

export const getProfile_Picture = createAsyncThunk<any>('profile/getProfilePicture', async () => {
  try {
    const response = await getProfilePicture();
    console.log(response);
    return response;
  } catch (error: any) {
    throw Error(error?.message);
  }
});

export const upLoadProfile_Picture = createAsyncThunk<any, any>(
  'profile/upLoadProfilePicture',
  async (formData: any) => {
    try {
      const response = await upLoadProfilePicture(formData);
      console.log(response);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const delete_profilePicture = createAsyncThunk<any>('profile/delete', async () => {
  try {
    const response = await deleteProfilePicture();
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
});
