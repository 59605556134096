import { createSlice } from '@reduxjs/toolkit';
import {
  CreateImageData,
  DeleteImageData,
  GetAllImageData,
  GetSingleImageData,
  UpdateImageData,
} from './thunk';
import { IimageManagement } from 'src/utils/types/imageManagementTypes';

interface IimageManagementState {
  imageManagement: IimageManagement[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IimageManagementState = {
  imageManagement: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const imageManagementSlice = createSlice({
  name: 'imageManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllImageData.pending, (state) => {
        state.isLoading = true;
      })
  
      .addCase(GetAllImageData.fulfilled, (state, action) => {
        state.imageManagement = action.payload as [];
        state.isLoading = false;
      })
      .addCase(GetAllImageData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleImageData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleImageData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleImageData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })

      .addCase(CreateImageData.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateImageData.fulfilled, (state, action) => {
        state.imageManagement.push(action.payload as IimageManagement);
        state.isCreating = false;
      })
    
      .addCase(CreateImageData.rejected, (state, action) => {
        state.error = action.payload as string || 'An error occurred while creating the image';
        state.isCreating = false;
      })
      
      .addCase(UpdateImageData.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateImageData.fulfilled, (state, action) => {
        const updatedImage = action.payload as IimageManagement;
        const index = state.imageManagement.findIndex((img) => img.id === updatedImage.id);
        if (index !== -1) {
          state.imageManagement[index] = updatedImage;
        }
        state.isUpdating = false;
      })
      .addCase(UpdateImageData.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to update data';
        state.isUpdating = false;
      })
      .addCase(DeleteImageData.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteImageData.fulfilled, (state, action) => {
        const deletedImageId = action.payload as string;
        state.imageManagement = state.imageManagement.filter((img) => img.id !== deletedImageId);
        state.isDeleting = false;
      })
      .addCase(DeleteImageData.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to delete data';
        state.isDeleting = false;
      });
  },
});

export default imageManagementSlice.reducer;
