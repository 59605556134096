import MetaTags from 'react-meta-tags';
import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Nav, NavItem, Row, Spinner } from 'reactstrap';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import DeleteModal from 'src/components/Modals/DeleteModal';
import { IImportProvidersTableData } from 'src/utils/types/ImportProviders';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import {
  createConfiguration,
  deleteConfiguration,
  getProviderConfigs, getProviders,
  updateConfiguration,
} from 'src/slices/ImportProviders/thunk';
import { getDecodedToken } from 'src/utils/jwtDecode';
import './index.scss';

const ImportProviders = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [shoDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [changeData, setChangeData] = useState<any | null>(null);
  const [deleteData, setDeleteData] = useState<any | null>(null);
  const [additionalFieldsData, setAdditionalFieldsData] = useState<any>([{ id: Math.random() }]);
  const [decodedToken, setDecodedToken] = useState<any | null>(null);
  const [specificDataError, setSpecificDataError] = useState(false);

  const dispatch = useDispatch();

  const { configurations, loading, providers } = useSelector((state: RootState) => state.configs);

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: configurations?.length,
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: 'e',
      order: 'asc',
    },
  ];

  useEffect(() => {
    const tokenData = getDecodedToken()
    setDecodedToken(tokenData)
    dispatch(getProviderConfigs())
    dispatch(getProviders())
  }, [dispatch]);

  const removeAdditionalField = (fieldId: number) => {
    const fields = additionalFieldsData.filter((item: number) => item !== fieldId);
    setAdditionalFieldsData(fields);
  };

  const addAdditionalField = () => {
    setAdditionalFieldsData((prev: any) => [
      ...prev,
      {
        id: Math.random(),
      },
    ]);
  };

  const contactListColumns = [
    {
      text: 'Actions',
      dataField: 'edit',
      sort: true,
      editable: false,
      style: {
        width: '8em',
      },
      formatter: (_cellContent: string, data: IImportProvidersTableData) => {
        return (
          <div className="tableIconBlock">
            <div className="tableIcon" onClick={() => toggleEditModal(data)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </div>
            <div className="tableIcon" onClick={() => handleDelete(data)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </div>
          </div>
        );
      },
    },
    {
      text: 'Configuration Id',
      dataField: 'configurationId',
      sort: true,
      editable: false,
    },
    {
      text: 'Tenant Id',
      dataField: 'tenantId',
      sort: true,
      editable: false,
    },
    {
      text: 'Configuration Name',
      dataField: 'configurationName',
      sort: true,
      formatter: (cellContent: string) => {
        return `${cellContent ? cellContent : '-'}`;
      },
    },
    {
      text: 'Data',
      dataField: 'data',
      sort: true,
      editable: false,
      formatter: (cellContent: any, data: IImportProvidersTableData) => {
        return (
          <div className="d-flex gap-2">
            {
              data?.data.map((item: any, index: number) => {
                return (
                  <div key={index}>
                    {item.key} - { item.value } {index < data?.data.length - 1 && <span>,</span>}
                  </div>
                )
              })
            }
          </div>
        );
      },
    },
  ];

  const closeCreateModal = () => setShowCreateModal(false);

  const closeEditModal = () => {
    setShowChangeModal(false);
    setAdditionalFieldsData([{ id: Math.random() }]);
  };

  const specificDataValidation = (data: any) => {
    return Object.keys(data)
      .filter(key => key.startsWith("dataKey"))
      .every(item => {
        const value = data[item]?.toString().trim();

        const correspondingValueKey = item.replace("dataKey", "dataValue");
        const correspondingValue = data[correspondingValueKey]?.toString().trim();

        return !(value.length === 0 || correspondingValue.length === 0);
      })
  }

  const crateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const handleSubmitCreateModal = (values: IImportProvidersTableData) => {
    const resultObject = Object.keys(values)
      .filter(key => key.startsWith("dataKey"))
      .reduce((obj, dataKey) => {
        obj[values[dataKey]] = values[dataKey.replace("dataKey", "dataValue")];
        return obj;
      }, {});

    const iss = decodedToken?.iss
    const parts = iss.split('/');
    const tenantId = parts[parts.length - 1];
    const sendData = {
      providerId: values.providerId,
      tenantId,
      configurationName: values.configurationName,
      data: resultObject
    };

    dispatch(createConfiguration(sendData))
    closeCreateModal()
  };

  const toggleEditModal = (data: any) => {
    setShowChangeModal(!showChangeModal);

    if (data?.data) {
      const resultArray = data?.data.map((item: any) => {
        return { key: item.key, value: item.value, id: Math.random() * Date.now() }
      })
      setAdditionalFieldsData(resultArray)
    }
    if (data?.configurationId) {
      setChangeData(data);
    }
  };

  const handleSubmitEditeModal = (data: any) => {

    const resultObject = Object.keys(data)
      .filter(key => key.startsWith("dataKey"))
      .reduce((obj, dataKey) => {
        obj[data[dataKey]] = data[dataKey.replace("dataKey", "dataValue")];
        return obj;
      }, {});

    const iss = decodedToken?.iss
    const parts = iss.split('/');
    const tenantId = parts[parts.length - 1];

    if (!specificDataValidation(resultObject)) {
      return setSpecificDataError(true)
    }

    setSpecificDataError(false)

    const sendData = {
      data: {
        // Todo fix
        // providerId: data.providerId,
        configurationName: data.configurationName,
        tenantId,
        data: resultObject,
      },
      configurationId: changeData.configurationId,
    };

    dispatch(updateConfiguration(sendData))
    closeEditModal()
  };

  const handleDelete = (data: any): void => {
    setShowDeleteModal(!shoDeleteModal);
    if (data?.configurationId) {
      setDeleteData(data);
    }
  };

  const confirmDelete = () => {
    const sendData = { configurationId: deleteData.configurationId }
    dispatch(deleteConfiguration(sendData))
    setShowDeleteModal(false)
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Import Providers</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Import Providers" />
          <Row>
            <Col lg="12">
              <Card>
                {!loading ? (
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="configurationId"
                          data={configurations || []}
                          columns={contactListColumns}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">
                                      Import Providers{' '}
                                      <span className="text-muted fw-normal ms-2">({configurations?.length})</span>
                                    </h5>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                                    {/* <div>
                                      <Nav pills>
                                        <NavItem>
                                          <Link className="nav-link active" to="user-list">
                                            <i className="uil uil-list-ul"></i>
                                          </Link>
                                        </NavItem>
                                        <NavItem>
                                          <Link className="nav-link" to="user-grid">
                                            <i className="uil uil-apps"></i>
                                          </Link>
                                        </NavItem>
                                      </Nav>
                                    </div> */}
                                    <div>
                                      <Link to="#" className="btn btn-light" onClick={crateModal}>
                                        <i className="uil uil-plus me-1"></i> Create Configuration
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // cellEdit={ cellEdit }
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={'table align-middle table-nowrap table-hover'}
                                      bordered={false}
                                      striped={false}
                                      hover={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone {...paginationProps} />
                                </Col>
                              </Row>
                            </>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                ) : (
                  <div className="spinnerBlock">
                    <Spinner />
                  </div>
                )}

                {/*  create modal */}

                <Modal isOpen={showCreateModal} toggle={crateModal}>
                  <ModalHeader toggle={crateModal} tag="h4">
                    Create Configuration
                  </ModalHeader>
                  <ModalBody className="scrollModal">
                    <AvForm
                      onValidSubmit={(_e: any, values: any) => {
                        handleSubmitCreateModal(values);
                      }}
                    >
                      <Row form>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Row form>
                              <Col xs={12}>
                                <div className="mb-3">
                                  <AvField
                                    type="select"
                                    name="providerId"
                                    label="Provider Name"
                                    placeholder="Enter Name"
                                    value={providers?.[0]?.name}
                                  >
                                    {providers &&
                                      providers?.map((provider: any) => {
                                        return (
                                          <option value={providers?.id} key={provider?.id}>
                                            {provider?.name || '-'}
                                          </option>
                                        );
                                      })}
                                  </AvField>
                                </div>
                              </Col>
                            </Row>

                            <div className="py-2">
                              <AvField
                                name="configurationName"
                                label="Configuration Name"
                                type="text"
                                placeholder="Enter Configuration Name"
                                errorMessage="Enter Configuration Name"
                                validate={{
                                  required: { value: true },
                                }}
                                value={''}
                              />
                            </div>

                            {additionalFieldsData?.map((field: any, index: number) => (
                              <div key={field.id} className="updateModalData">
                                <span>
                                  <AvField
                                    name={`dataKey${index}`}
                                    label="Specific Data Key"
                                    type="input"
                                    placeholder="Data key"
                                    errorMessage="Enter data"
                                    validate={{
                                      required: { value: false },
                                    }}
                                    value={''}
                                  />
                                </span>
                                <span>
                                  <AvField
                                    name={`dataValue${index}`}
                                    label="Specific Data Value"
                                    type="input"
                                    placeholder="Data value"
                                    errorMessage="Enter data"
                                    validate={{
                                      required: { value: false },
                                    }}
                                    value={''}
                                  />
                                </span>
                                <span className="deleteBtn">
                                  {index !== 0 && (
                                    <span className="deleteBtnItem" onClick={() => removeAdditionalField(field.id)}>
                                      X
                                    </span>
                                  )}
                                </span>
                              </div>
                            ))}
                            <div className="btn btn-light w-sm">
                              <span onClick={addAdditionalField}>Add more</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button type="button" className="btn btn-light w-sm mx-2" onClick={closeCreateModal}>
                              Close
                            </button>
                            <button type="submit" className="btn btn-success w-sm save-user">
                              Create
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </ModalBody>
                </Modal>

                {/*  create modal end */}

                {/*  edit modal  */}

                <Modal isOpen={showChangeModal} toggle={toggleEditModal}>
                  <ModalHeader toggle={toggleEditModal} tag="h4">
                    Edit Configuration
                  </ModalHeader>
                  <ModalBody className="scrollModal">
                    <AvForm
                      onValidSubmit={(_e: any, values: any) => {
                        handleSubmitEditeModal(values);
                      }}
                    >
                      <Row form>
                        <Col xs={12}>
                          <div className="mb-3">
                            <div className="pb-2">
                              <Row form>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <AvField
                                      name="configurationName"
                                      label="Configuration name"
                                      type="text"
                                      placeholder="Enter Configuration Name"
                                      errorMessage="Enter Configuration Name"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      value={changeData?.configurationName || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            {additionalFieldsData?.map((field: any, index: number) => (
                              <div key={field.id} className="updateModalData">
                                <span>
                                  <AvField
                                    name={`dataKey${index}`}
                                    label="Specific Data Key"
                                    type="input"
                                    placeholder="Data Key"
                                    errorMessage="Enter Data"
                                    validate={{
                                      required: { value: false },
                                    }}
                                    value={field?.key || ""}
                                  />
                                </span>
                                <span>
                                  <AvField
                                    name={`dataValue${index}`}
                                    label="Specific data value"
                                    type="input"
                                    placeholder="Data Value"
                                    errorMessage="Enter Data"
                                    validate={{
                                      required: { value: false },
                                    }}
                                    value={field?.value || ""}
                                  />
                                </span>
                                <span className="deleteBtn">
                                  {index !== 0 && (
                                    <span className="deleteBtnItem" onClick={() => removeAdditionalField(field.id)}>
                                      X
                                    </span>
                                  )}
                                </span>
                              </div>
                            ))}
                            <div className="btn btn-light w-sm">
                              <span onClick={addAdditionalField}>Add more</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button type="button" className="btn btn-light w-sm mx-2" onClick={closeEditModal}>
                              Close
                            </button>
                            <button type="submit" className="btn btn-success w-sm save-user">
                              Update
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </ModalBody>
                </Modal>

                {/*  edit modal end */}

                {/*  delete modal  */}
                <DeleteModal
                  isOpen={shoDeleteModal}
                  toggleDeleteModal={handleDelete}
                  handleShowDeleteModal={() => setShowDeleteModal(false)}
                  onDelete={confirmDelete}
                  title="Delete configuration"
                />

                {/*  delete modal end */}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(ImportProviders);
