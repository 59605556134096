import { createSlice } from '@reduxjs/toolkit';
import { IText } from 'src/utils/types/textTypes';
import { GetAllTexts, CreateNewTextEntry, DeleteTextEntry, GetSingleText, UpdateTextEntry } from './thunk';

interface ITextsState {
  texts: IText[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

export const textsDummyData: IText[] = [
  {
    id: 'text001',
    content: 'This is a dummy text entry',
    hashtags: ['dummy', 'text', 'entry'],
    keywords: ['dummy', 'text', 'entry'],
    enabled: true,
  },
  {
    id: 2,
    content: 'Entspannen Sie in unserem Wellnessbereich!',
    hashtags: ['hotel', 'wellness', 'spa'],
    keywords: ['Massage', 'Sauna'],
    enabled: true,
  },
  {
    id: 'text002',
    content: 'This is another dummy text entry',
    hashtags: ['another', 'dummy', 'text', 'entry'],
    keywords: ['another', 'dummy', 'text', 'entry'],
    enabled: false,
  },
  {
    id: 'text003',
    content: 'This is yet another dummy text entry',
    hashtags: ['yet', 'another', 'dummy', 'text'],
    keywords: ['yet', 'another', 'dummy', 'text'],
    enabled: true,
  },
  {
    id: 'text004',
    content: 'This is the last dummy text entry',
    hashtags: ['last', 'dummy', 'text', 'entry'],
    keywords: ['last', 'dummy', 'text', 'entry'],
    enabled: false,
  },
];

const initialState: ITextsState = {
  texts: textsDummyData,
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const textsSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllTexts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllTexts.fulfilled, (state, action) => {
        state.texts = action.payload as IText[];
        state.isLoading = false;
      })
      .addCase(GetAllTexts.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateNewTextEntry.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateNewTextEntry.fulfilled, (state) => {
        state.isCreating = false;
      })
      .addCase(CreateNewTextEntry.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateTextEntry.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateTextEntry.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateTextEntry.rejected, (state, action) => {
        state.isUpdating = false;
        console.log(action);
      })
      .addCase(DeleteTextEntry.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteTextEntry.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(DeleteTextEntry.rejected, (state) => {
        state.isDeleting = false;
      });
  },
});

export default textsSlice.reducer;
