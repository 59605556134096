import { createAsyncThunk } from '@reduxjs/toolkit';
import { SpecialPeriodsService } from 'src/utils/services/service/specialPeriodsService';
import { ISpecialPeriods } from 'src/utils/types/specialPeriodsTypes';

export const CreateSpecialPeriods = createAsyncThunk(
  'specialPeriods/createSpecialPeriods',
  async ({ data }: { data: ISpecialPeriods }) => {
    try {
      const response = await SpecialPeriodsService.createSpecialPeriods(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetAllSpecialPeriods = createAsyncThunk('specialPeriods/getAllSpecialPeriods', async () => {
  try {
    const response = await SpecialPeriodsService.getAllSpecialPeriods();
    return response;
  } catch (error) {
    return error;
  }
});

export const GetSingleSpecialPeriods = createAsyncThunk(
  'specialPeriods/getSingleSpecialPeriods',
  async ({ eventId }: { eventId: string }) => {
    try {
      const response = await SpecialPeriodsService.getSingleSpecialPeriods(eventId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateSpecialPeriods = createAsyncThunk(
  'specialPeriods/updateSpecialPeriods',
  async ({ eventId, data }: { eventId: string; data: ISpecialPeriods }) => {
    try {
      const response = await SpecialPeriodsService.updateSpecialPeriods(eventId, data);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
);

export const DeleteSpecialPeriods = createAsyncThunk(
  'specialPeriods/deleteSpecialPeriods',
  async ({ eventId }: { eventId: string }) => {
    try {
      const response = await SpecialPeriodsService.deleteSpecialPeriods(eventId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
