import { CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import MetaTags from 'react-meta-tags';
import { Avatar, Card, Spin, Tabs, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import { changeUserFields, delete_profilePicture, getProfile_Picture, upLoadProfile_Picture } from 'src/slices/thunks';
import { TabsProps } from 'antd/lib';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import defaultPicture from '../../assets/images/default.jpg';
const Profile = () => {
  const { isProfilePictureLoading, profilePicture, loading } = useSelector((state: any) => state.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [editFirstName, setEditFirstName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();

  const { userData } = useSelector((state: any) => state.login);
  useEffect(() => {
    dispatch(getProfile_Picture());
  }, []);

  useEffect(() => {
    const imageBlob = new Blob([profilePicture], { type: 'image/jpeg' }); // Adjust the type if your image is not JPEG
    const imageUrl = URL.createObjectURL(imageBlob);
    setImageSrc(imageUrl);
    setSelectedFile(null);
  }, [profilePicture]);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    setImageSrc(URL.createObjectURL(file));
    setSelectedFile(file);
  };

  type NotificationType = 'success' | 'info' | 'warning' | 'error';
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    const response: any = await dispatch(upLoadProfile_Picture(formData));
    console.log(response);
    if (response?.type === 'profile/upLoadProfilePicture/fulfilled') {
      openNotificationWithIcon('success', 'Profile picture updated successfully');
      dispatch(getProfile_Picture());
      dispatch(getProfile_Picture());
      setIsLoading(false);
    } else {
      setIsLoading(false);
      openNotificationWithIcon('error', 'failed to update profile picture');
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const response: any = await dispatch(delete_profilePicture());
    if (response?.type === 'profile/delete/fulfilled') {
      openNotificationWithIcon('success', 'Profile picture deleted successfully');
      dispatch(getProfile_Picture());
      dispatch(getProfile_Picture());
      setIsLoading(false);
    } else {
      setIsLoading(false);
      openNotificationWithIcon('error', 'failed to delete profile picture');
    }
    setIsLoading(false);
  };

  const handleValidEditUserSubmit = async (values: any) => {
    setIsUpdating(true);
    const editedUser = {
      firstName: values.firstName,
      lastName: values.lastName,
      userId: userData?.sub,
      newPassword: values.newPassword,
    };
    const response: any = await dispatch(changeUserFields(editedUser));
    if (response?.type === 'users/changeUserFields/fulfilled') {
      openNotificationWithIcon('success', 'User update is successful');
      setIsUpdating(false);
    } else {
      openNotificationWithIcon('error', 'Failed to update user');
      setIsUpdating(false);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Profile picture',
      children: (
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Card>
                  <Row>
                    <Col style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                      <div className="mb-3">
                        <div>
                          {isProfilePictureLoading ? (
                            <>
                              <div
                                style={{
                                  margin: 20,
                                  display: 'flex',
                                  alignContent: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Spin size="large" />
                              </div>
                            </>
                          ) : (
                            <>
                              {!profilePicture && selectedFile === null ? (
                                <>
                                  <Avatar
                                    style={{
                                      verticalAlign: 'middle',
                                      marginBottom: '20px',
                                      display: 'flex',
                                      alignContent: 'center',
                                      justifyContent: 'center',
                                    }}
                                    src={defaultPicture}
                                    size={200}
                                  >
                                    {`${userData?.given_name?.slice(0, 1)} ${userData?.family_name?.slice(0, 1)} `}
                                  </Avatar>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: 200,
                                        width: 200,
                                        borderRadius: '100%',
                                        marginBottom: '20px',
                                        objectFit: 'cover',
                                        maxWidth: '100%',
                                      }}
                                      src={imageSrc}
                                      alt="No profile picture found"
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                          className="d-flex align-items-start justify-content-start  mt-md-0  mb-3"
                        >
                          {selectedFile ? (
                            <button disabled={isLoading} className="btn btn-primary me-2" onClick={handleSave}>
                              {isLoading ? 'Uploading....' : 'Save'}
                            </button>
                          ) : (
                            <div>
                              <input
                                type="file"
                                id="profile-picture"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  alignContent: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <label htmlFor="profile-picture" className="btn btn-primary me-2">
                                  {!profilePicture ? 'Upload profile' : 'Change Profile Picture'}
                                </label>
                              </div>
                            </div>
                          )}

                          {!profilePicture ? (
                            ''
                          ) : (
                            <button className="btn btn-danger" disabled={isLoading} onClick={handleDelete}>
                              Delete Profile Picture
                            </button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ),
    },
    {
      key: '2',
      label: 'Details',
      children: (
        <>
          <Card>
            <CardBody>
              <Card>
                <AvForm
                  onValidSubmit={(_e: any, values: any) => {
                    handleValidEditUserSubmit(values);
                  }}
                >
                  <Row form>
                    <Col>
                      <div className="mb-3">
                        <div className="py-2">
                          <AvField
                            name="username"
                            style={{ cursor: 'not-allowed' }}
                            disabled={true}
                            label="Username"
                            type="username"
                            // placeholder="Enter first Name"
                            // errorMessage="Enter first Name"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEditFirstName(e.target.value)}
                            validate={{
                              required: { value: false },
                            }}
                            value={userData?.preferred_username || ''}
                          />
                        </div>
                        <AvField
                          name="firstName"
                          label="First Name"
                          type="firstName"
                          placeholder="Enter first Name"
                          errorMessage="Enter first Name"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setEditFirstName(e.target.value)}
                          validate={{
                            required: { value: false },
                          }}
                          value={userData?.given_name || ''}
                        />

                        <div className="py-2">
                          <AvField
                            name="lastName"
                            label="Last Name"
                            type="lastName"
                            placeholder="Enter last Name"
                            errorMessage="Enter last Name"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEditLastName(e.target.value)}
                            validate={{
                              required: { value: false },
                            }}
                            value={userData?.family_name || ''}
                          />
                        </div>

                        <AvField
                          name="newPassword"
                          label="New password"
                          type="password"
                          placeholder="Enter new password"
                          errorMessage="Enter new password"
                          validate={{
                            required: { value: true },
                          }}
                          value={''}
                        />
                        <div className="py-2">
                          <AvField
                            name="confirmPassword"
                            label="Confirm password"
                            type="password"
                            placeholder="Enter password"
                            errorMessage="Password mismatch"
                            validate={{
                              required: { value: true },
                              match: {
                                value: 'newPassword',
                                errorMessage: 'Passwords do not match',
                              },
                            }}
                            value={''}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success w-sm save-user" disabled={isUpdating}>
                          {isUpdating ? 'Updating ....' : 'Save'}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Card>
            </CardBody>
          </Card>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="page-content">
        {contextHolder}
        <MetaTags>
          <title>Profile</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Profile" />
          <Tabs style={{ marginTop: '-10px' }} defaultActiveKey="1" items={items} />
        </Container>
      </div>
    </div>
  );
};

export default Profile;
