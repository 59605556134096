import axios, { AxiosResponse } from 'axios';
import env from 'src/env';
import { ITag } from 'src/utils/types/tagTypes';

const baseURL = env.VITE_TAGS_DEFAULT_BASE_URL();
const specialPeriodsBaseUrl = '/tag-management';

const getListOfTags = async (): Promise<ITag[] | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: ITag[] = await axios.get(`${specialPeriodsBaseUrl}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching list of tags:', error);
    throw error;
  }
};

const createTag = async (data: { name: string; propertyId: string }): Promise<ITag | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: ITag = await axios.post(`${specialPeriodsBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating tag:', error);
    throw error;
  }
};

const updateATag = async (tagId: string, data: ITag): Promise<ITag | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: ITag = await axios.put(`${specialPeriodsBaseUrl}/${tagId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating tag:', error);
    throw error;
  }
};

const addAssignmentToTag = async (tagId: string, data: { propertyId: string; year: number; week: number }) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: AxiosResponse = await axios.post(`${specialPeriodsBaseUrl}/${tagId}/entry`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error adding assignment to tag:', error);
    throw error;
  }
};

const deleteATag = async (tagId: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: AxiosResponse = await axios.delete(`${specialPeriodsBaseUrl}/${tagId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error deleting tag:', error);
    throw error;
  }
};

export const TagService = {
  getListOfTags,
  createTag,
  updateATag,
  addAssignmentToTag,
  deleteATag,
};
