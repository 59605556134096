import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { ConfirmationModal } from '../ConfirmationModal';
import { IoTrashOutline } from 'react-icons/io5';
import { GoTrash } from 'react-icons/go';

interface DeleteButtonProps {
  title: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  triggerButtonId?: string;
  okButtonId?: string;
}

const DeleteButton = ({ onConfirm, title, cancelText, okText, triggerButtonId, okButtonId }: DeleteButtonProps) => {
  return (
    <div>
      <ConfirmationModal
        confirmationType="delete-confirmation"
        title={title}
        onConfirm={onConfirm}
        style={{ backgroundColor: 'none', border: 'none' }}
        okText={okText}
        cancelText={cancelText}
        okButtonId={okButtonId}
        triggerButtonContent={
          <>
            <GoTrash id={triggerButtonId} className="tableIcon" size={20} />
          </>
        }
      />
    </div>
  );
};

export default DeleteButton;
