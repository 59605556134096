import React, { useCallback, useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { menuItems } from './Menu';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import Icon from '@ailibs/feather-react-ts';
import { useSelector } from 'react-redux';
import './index.scss';
import { Popover } from 'antd';

const NewRouteAdd = () => {
  return <span className="badge rounded-pill bg-danger float-end">novidade</span>;
};

const HeaderMenuItem = ({ item, isSmallSidebar }: any) => {
  if (isSmallSidebar) return null;
  return (
    <li className="menu-title" data-key="t-layouts">
      {item.label}
    </li>
  );
};

const MenuItem = ({ item, props, isSmallSidebar, onSmallDevice }: any) => {
  const hasChildren = item.subItems && item.subItems.length > 0;
  const url = item.link ? item.link : '#';
  const hasExtra = item.novidade;
  const arrowclass = item.label === 'Authentication';

  const path = window.location.pathname;

  const content = (
    <>
      {/* <Menu items={item.subItems} props={props} isSmallSidebar={isSmallSidebar} onSmallDevice={true} /> */}
      <div style={{ width: 300 }}>
        {/* <li>{item.subItems?.map(e)=>}</li>
         */}
        {item.subItems?.map((e: any) => {
          return (
            <>
              <li style={{ marginTop: '6px', background: '#f8f9fa', padding: '6px 5px' }}>
                <Link to={e?.link}>
                  <e.icon color="#7b8190" size={20} className={``} />
                  <span style={{ color: '#7b8190', marginLeft: '5px' }}>{e?.label}</span>
                </Link>
              </li>
            </>
          );
        })}
      </div>
    </>
  );

  return (
    <li id={`side-nav-link-${item?.label}`}>
      <Link
        to={url}
        className={classNames(
          // { 'has-arrow': hasChildren && !hasExtra && !arrowclass && !isSmallSidebar },
          { waves: hasChildren },
        )}
      >
        {item.icon && (
          <div>
            {isSmallSidebar ? (
              <>
                <div>
                  <Popover placement="right" content={content} title={item?.label}>
                    <item.icon
                      color={`${path === url ? '#0077b6' : '#7b8190'}`}
                      className={`"icon nav-icon " ${path === url ? 'activeTheLink' : ''}`}
                    />
                  </Popover>
                </div>
              </>
            ) : (
              <>
                <item.icon
                  color={`${path === url ? '#0077b6' : '#7b8190'}`}
                  className={`"icon nav-icon " ${path === url ? 'activeTheLink' : ''}`}
                />
              </>
            )}
          </div>
        )}
        {hasExtra && <NewRouteAdd />}
        {!isSmallSidebar && (
          <span className={`menu-item ${path === url ? 'activeTheLink' : ''}`}>{props.t(item.label)}</span>
        )}
        {item.badge && <span className={'badge rounded-pill ' + item.badgecolor}>{item.badge}</span>}
      </Link>
      {hasChildren && <Menu items={item.subItems} props={props} isSmallSidebar={isSmallSidebar} />}
    </li>
  );
};

const Menu = ({ items, props, isSmallSidebar, onSmallDevice }: any) => {
  return (
    <ul className="sub-menu">
      {items?.map((item: any, key: number) => (
        <MenuItem item={item} key={key} props={props} isSmallSidebar={isSmallSidebar} onSmallDevice={onSmallDevice} />
      ))}
    </ul>
  );
};

const SidebarContent = (props: any) => {
  const ref: any = useRef();
  const [isSmallSidebar, setIsSmallSidebar] = useState(false);

  useEffect(() => {
    const updateSidebarSize = () => {
      const sidebarSize = document.body.getAttribute('data-sidebar-size');
      setIsSmallSidebar(sidebarSize === 'sm');
    };

    // Initial check
    updateSidebarSize();

    // Listen for changes to the data-sidebar-size attribute
    const observer = new MutationObserver(updateSidebarSize);
    observer.observe(document.body, { attributes: true, attributeFilter: ['data-sidebar-size'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  const clearActiveStates = useCallback(() => {
    const activeElements = document.querySelectorAll('.mm-active, .mm-show, .active');
    activeElements.forEach((el) => {
      el.classList.remove('mm-active', 'mm-show', 'active');
    });
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  });

  const scrollElement = useCallback(
    (item: any) => {
      if (item && ref && ref.current) {
        const currentPosition = item.offsetTop;
        if (currentPosition > window.innerHeight) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
          window.scrollTo(0, currentPosition - 300);
        }
      }
    },
    [ref],
  );

  const { userData } = useSelector((state: any) => state.login);

  const hasRealmAdminRole = () => {
    if (
      userData &&
      userData.resource_access &&
      userData.resource_access['realm-management'] &&
      userData.resource_access['realm-management'].roles
    ) {
      return userData.resource_access['realm-management'].roles.includes('realm-admin');
    }
    return false;
  };

  const extractRealmFromIssuer = (iss: string) => {
    const parts = iss?.split('/');
    const realmIndex = parts?.indexOf('realms');
    if (realmIndex !== -1 && realmIndex + 1 < parts?.length) {
      return parts[realmIndex + 1];
    }
    return '';
  };

  const realm = extractRealmFromIssuer(userData?.iss);

  const filteredMenuItems = menuItems.map((item) => {
    if (item.label === 'System Settings' && realm !== 'master') {
      if (hasRealmAdminRole()) {
        // Remove the 'Tenants' sub-item if the user has admin role but the realm is not 'master'
        return {
          ...item,
          subItems: item.subItems.filter((subItem: any) => subItem.label !== 'Tenants'),
        };
      } else {
        // Hide the entire 'System Settings' menu if the user does not have admin role and the realm is not 'master'
        return { ...item, subItems: [], label: '', icon: '' };
      }
    }
    return item;
  });
  return (
    <SimpleBar style={{ maxHeight: '100%' }} ref={ref} className="sidebar-menu-scroll">
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {filteredMenuItems.map((item, key) => (
            <div key={key}>
              {item['isHeader'] ? (
                <HeaderMenuItem item={item} isSmallSidebar={isSmallSidebar} />
              ) : (
                <MenuItem item={item} props={props} isSmallSidebar={isSmallSidebar} />
              )}
            </div>
          ))}
        </ul>
      </div>
    </SimpleBar>
  );
};

export default withTranslation()(withRouter(SidebarContent));
