import { createSlice } from '@reduxjs/toolkit';
import {
  CreateManageHotelData,
  // DeleteManageHotelData,
  GetAllManageHotelData,
  GetSingleManageHotelData,
  UpdateManageHotelData,
} from './thunk';
import { IManageHotel } from 'src/utils/types/manageHotelTypes';

interface IManageHotelState {
  manageHotel: IManageHotel[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IManageHotelState = {
  manageHotel: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const manageHotelSlice = createSlice({
  name: 'manageHotel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllManageHotelData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllManageHotelData.fulfilled, (state, action) => {
        state.manageHotel = action.payload as [];
        state.isLoading = false;
      })
      .addCase(GetAllManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleManageHotelData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleManageHotelData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateManageHotelData.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateManageHotelData.fulfilled, (state, action) => {
        state.manageHotel.push(action.payload as IManageHotel);
        state.isCreating = false;
      })
      .addCase(CreateManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateManageHotelData.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateManageHotelData.fulfilled, (state, action) => {
        const updatedContact = action.payload as IManageHotel;
        const index = state.manageHotel.findIndex((contact) => contact.id === updatedContact.id);
        state.manageHotel[index] = updatedContact;
        state.isUpdating = false;
      })
      .addCase(UpdateManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      });
      // .addCase(DeleteManageHotelData.pending, (state) => {
      //   state.isDeleting = true;
      // })
      // .addCase(DeleteManageHotelData.fulfilled, (state, action) => {
      //   const deletedContactId = action.payload as string;
      //   state.manageHotel = state.manageHotel.filter((contact) => contact.id !== deletedContactId);
      //   state.isDeleting = false;
      // })
      // .addCase(DeleteManageHotelData.rejected, (state, action) => {
      //   state.error = action.payload as string;
      //   state.isDeleting = false;
      // });
  },
});

export default manageHotelSlice.reducer;
