import { combineReducers } from '@reduxjs/toolkit';

import contactsReducer from './Contacts/reducer';

import usersReducer from './Users/reducer';

import layoutReducer from './Layout/reducer';

// Calendar
import calendarReducer from './Calendar/reducer';

//Chat
import chatReducer from './Chat/reducer';

// //Invoices
import invoicesReducer from './Invoices/reducer';

//register
import registerReducer from './Auth/Register/reducer';

//login
import loginReducer from './Auth/Login/reducer';

//project
import projectsReducer from './Projects/reducer';

// // User Profile
import profileReducer from './Auth/Profile/reducer';

// //kanban
import kanbanboardsReducer from './Kanbanboards/reducer';

// // Forget Password
import forgetPasswordReducer from './Auth/Forgetpwd/reducer';

// // Groups
import groupsReducer from './Groups/reducer';

// // Api Keys
import apiKeysReducer from './ApiKey/reducer';

// // Import Provider Configs
import importProvidersReducer from './ImportProviders/reducer';

// // Properties
import propertiesReducer from './Properties/reducer';

// // Properties
import geographicLocationsReducer from './GeographicalLocations/reducer';

// // Weather Configurations
import weatherReducer from './WeatherProvider/reducer';

// // Anomaly
import anomalyReducer from './Anomaly/reducer';

// // ExternalEvents
import externalEventsReducer from './ExternalEvents/reducer';
import occupancyPredictionReducer from './OccupancyPrediction/reducer';
import pricingPredictionReducer from './PricingPrediction/reducer';
import occupancyAssistanceReducer from './OccupancyAssistance/reducer';
import cancellationPredictionReducer from './CancellationPrediction/reducer';
import totalRatePredictionReducer from './TotalRatePrediction/reducer';

import notificationContactsReducer from './NotificationContacts/reducer';
import roomConfigurationReducer from './RoomPlan/reducer';
import specialPeriodReducer from './SpecialPeriods/reducer';
import externalApiKeysReducer from './ExternalApiKeys/reducer';
import manageHotelReducer from './ManageHotel/reducer';
import priceListReducer from './PriceList/reducer';
import mealPlansReducer from './MealPlans/reducer';
import tagsReducer from './Tags/reducer';
import textReducer from './Texts/reducer';
import imageManagementReducer from './ImageManagement/reducer';
import competitorReducer from './CompetitorHotels/reducer';

const rootReducer = combineReducers({
  tenants: contactsReducer,
  Layout: layoutReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  invoices: invoicesReducer,
  register: registerReducer,
  login: loginReducer,
  forgetPassword: forgetPasswordReducer,
  projects: projectsReducer,
  profile: profileReducer,
  kanbanboards: kanbanboardsReducer,
  users: usersReducer,
  groups: groupsReducer,
  keys: apiKeysReducer,
  configs: importProvidersReducer,
  porperty: propertiesReducer,
  geographicLocations: geographicLocationsReducer,
  weatherConfigurations: weatherReducer,
  anomaly: anomalyReducer,
  externalEvents: externalEventsReducer,
  occupancyPrediction: occupancyPredictionReducer,
  pricingPrediction: pricingPredictionReducer,
  occupancyAssistance: occupancyAssistanceReducer,
  cancellationPrediction: cancellationPredictionReducer,
  totalRatePrediction: totalRatePredictionReducer,
  notificationContacts: notificationContactsReducer,
  roomConfiguration: roomConfigurationReducer,
  specialPeriods: specialPeriodReducer,
  externalApiKeys: externalApiKeysReducer,
  manageHotel: manageHotelReducer,
  priceList: priceListReducer,
  mealPlans: mealPlansReducer,
  tags: tagsReducer,
  texts: textReducer,
  imageManagement: imageManagementReducer,
  competitor: competitorReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
