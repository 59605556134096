import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createConfiguration,
  deleteConfiguration,
  getProviderConfigs, getProviders,
  updateConfiguration,
} from 'src/slices/ImportProviders/thunk';

interface ContactsStateType {
  configurations: Array<any>,
  error?: string | object | null | undefined | unknown,
  loading: boolean,
  providers: Array<any>
}
export const initialState: ContactsStateType = {
  configurations: [],
  error: '',
  loading: false,
  providers: []
};

const providerSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProviderConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getProviderConfigs.fulfilled, (state, action) => {
        state.configurations = action.payload as any;
        state.loading = false;
      })

      .addCase(getProviders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getProviders.fulfilled, (state, action) => {
        state.providers = action.payload as any;
        state.loading = false;
      })

      .addCase(createConfiguration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createConfiguration.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateConfiguration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateConfiguration.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(deleteConfiguration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteConfiguration.fulfilled, (state) => {
        state.loading = false;
      })

      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  }

});

export default providerSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
