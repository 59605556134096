import { Switch, BrowserRouter as Router } from 'react-router-dom';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';

//redux
import { useDispatch, useSelector } from 'react-redux';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import HorizontalLayout from './components/HorizontalLayout/index';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';
import './assets/scss/root.scss';

import fakeBackend from './helpers/AuthType/fakeBackend';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { setUserData } from './slices/Auth/Login/reducer';

fakeBackend();

const App = () => {
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));
  const dispatch = useDispatch();
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    if (token) {
      decodeAndSetToken(token);
    }
  }, [token]);

  const decodeAndSetToken = (token: string) => {
    const decodedUser = jwtDecode(token);
    dispatch(setUserData(decodedUser));
  };

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route: any, idx: number) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </>
  );
};

export default App;
