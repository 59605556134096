import { createSlice } from '@reduxjs/toolkit';

interface LoginStateType {
  users: Array<any>;
  userProfile: object;
  error?: string | object | null | undefined | unknown;
  user?: any;
  history?: any;
  userData: any | null;
  isLoading: boolean;
}
export const initialState: LoginStateType = {
  users: [],
  userProfile: {},
  error: '',
  user: '',
  history: '',
  userData: null,
  isLoading: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
    },
    loginSuccess(state, action) {
      state.user = action.payload;
    },
    logoutUserSuccess(state, action) {
      state.history = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    removeUserData(state, action) {
      state.userData = null;
    },
    updateLoadingState(state, action) {
      console.log(action.payload);
      state.isLoading = action.payload;
    },
  },
});

export const { apiError, loginSuccess, logoutUserSuccess, setUserData, removeUserData, updateLoadingState } =
  loginSlice.actions;

export default loginSlice.reducer;
