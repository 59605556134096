
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, InputNumber, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/slices';
import { NotificationType } from 'src/utils/types/NotificationTypes';

import { CreatePriceListData, GetAllPriceListData, UpdatePriceListData } from 'src/slices/PriceList/thunk';
import { useEffect } from 'react';
import { IPriceList } from 'src/utils/types/priceListTypes';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import moment from 'moment';
interface IPriceListProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IPriceList | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IPriceList | null) => void;
}

const PriceListModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  isEditMode = false,
  setIsEditMode,
  setEditingData,
}: IPriceListProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.priceList);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      const formattedValues = {
        ...initialValues,
        startDate: initialValues?.startDate ? moment(initialValues?.startDate).format('YYYY-MM-DD') : '',
        endDate: initialValues?.endDate ? moment(initialValues?.endDate).format('YYYY-MM-DD') : '',
      };
      form.setFieldsValue(formattedValues);
    }
  }, [initialValues, isEditMode]);
  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IPriceList = form.getFieldsValue();
    console.log(formData);

    try {
      if (isEditMode) {
        // ** Update Operation
        const payload = formData;
        // payload.tenant = initialValues?.tenant;
        payload.id = initialValues?.id;
        const response = await dispatch(
          UpdatePriceListData({
            data: payload,
            pricelistId: initialValues?.id as string,
          }),
        );
        // ** Handled Response
        if ((response.payload as IPriceList)['id']) {
          openNotificationWithIcon('success', 'Price List data updated successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllPriceListData());
        } else {
          openNotificationWithIcon('error', `Error updating Price List data`);
        }
      } else {
        // ** Create Operation
        const payload = formData;
        const response = await dispatch(
          CreatePriceListData({
            data: payload,
          }),
        );

        // ** Handled Response
        if ((response.payload as IPriceList)['id']) {
          openNotificationWithIcon('success', 'Price List data created successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllPriceListData());
        } else {
          openNotificationWithIcon('error', `Error creating Price List data`);
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', `Something went wrong`);
    }
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? 'Update' : 'Create'} Price List
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}
            >
              <Input id="priceListName" placeholder="Name" className="p-2 px-2" />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  required={true}
                  label="Start Date"
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select start date',
                    },
                  ]}
                >
                  <input
                  id="priceListStartDate"
                    type="date"
                    style={{
                      padding: 10,
                      borderRadius: 5,
                      width: '100%',
                      border: '1px solid #d9d9d9',
                    }}
                    value={form.getFieldValue('startDate')}
                    onChange={(e) => form.setFieldValue('startDate', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  required={true}
                  label="End Date"
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select end date',
                    },
                  ]}
                >
                  <input
                    id="priceListEndDate"
                    type="date"
                    style={{
                      padding: 10,
                      borderRadius: 5,
                      width: '100%',
                      border: '1px solid #d9d9d9',
                    }}
                    value={form.getFieldValue('endDate')}
                    onChange={(e) => form.setFieldValue('endDate', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Items List */}

            <Form.List name="items">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row gutter={16} key={key}>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, 'roomTypeId']}
                          label="Room Type ID"
                          rules={[{ required: true, message: 'Missing room type ID' }]}
                        >
                          <Input id="roomTypeID" placeholder="Room Type ID" />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, 'price']}
                          label="Price"
                          rules={[{ required: true, message: 'Missing price' }]}
                        >
                          <Input id="price" placeholder="Price" type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => remove(name)}
                          style={{
                            background: 'transparent',
                            border: 'none',
                            color: 'grey',
                            marginTop: '20px',
                            fontSize: '27px',
                          }}
                        >
                          <i className="uil uil-trash-alt me-1"></i>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button id="addPriceListItem" onClick={() => add()}>
                      <i className="uil uil-plus me-1"></i> Add Item
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="specialPeriodCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>
            <AsyncButton
              buttonText={isEditMode ? 'Update' : 'Create'}
              isEditMode={isEditMode}
              style={{ marginTop: 20 }}
              isLoading={isCreating || isUpdating}
              onClick={handleCreateOrUpdate}
            id="priceListORUpdateButton"
            />
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PriceListModal;