import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { IRoomConfiguration } from 'src/utils/types/roomConfigurationsTypes';
import { Link } from 'react-router-dom';
import RoomPlanModal from './components/RoomPlanModal';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import { FiEdit } from 'react-icons/fi';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TableView from 'src/components/Common/TableView/TableView';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { DeleteRoomPlan, GetAllRoomPlans } from 'src/slices/RoomPlan/thunk';
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const RoomPlans = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IRoomConfiguration | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const dispatch: AppDispatch = useDispatch();
  const { isLoading, roomConfigurations, isDeleting } = useSelector((state: RootState) => state.roomConfiguration);

  useEffect(() => {
    if (!isLoading && roomConfigurations.length === 0) {
      dispatch(GetAllRoomPlans());
    }
  }, []);

  const columns: TableProps<IRoomConfiguration>['columns'] = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 100,
      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateRoomPlan" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="Cancel"
                okText="Proceed"
                okButtonId={`confirmAndDeleteRoomPlan`}
                triggerButtonId="deleteRoomPlan"
                onConfirm={() => {
                  // TODO handle delete
                  deleteRoomPlan(record.id as string);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: 200,
    },
    {
      title: 'Count',
      key: 'count',
      dataIndex: 'count',
      width: 200,
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      width: 200,
    },
  ];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const deleteRoomPlan = async (roomId: string) => {
    if (!roomId) {
      openNotificationWithIcon('error', 'Did not find the room id');
      return;
    }
    try {
      const response = await dispatch(
        DeleteRoomPlan({
          roomConfigurationId: roomId,
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        openNotificationWithIcon('success', 'Room plan deleted successfully');
        dispatch(GetAllRoomPlans());
      } else {
        openNotificationWithIcon('error', 'Error deleting room plan');
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };

  return (
    <div>
      {contextHolder}
      <div className="page-content">
        <MetaTags>
          <title>Room Plans</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Settings" breadcrumbItem="Room Plan" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div>
                        <SearchBar
                          id="roomPlanSearchBar"
                          value={searchValue}
                          onChange={handleSearchChange}
                          placeholder="Search room."
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Button
                            id="createRoomPlan"
                            className="btn btn-light"
                            onClick={() => {
                              setIsModalOpen(!isModalOpen);
                            }}
                          >
                            <i className="uil uil-plus me-1"></i> Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableView
                      loading={isLoading || isDeleting}
                      columns={columns}
                      dataSource={roomConfigurations
                        ?.filter((e) => {
                          if (searchValue === '') return e;
                          else if (e.name.toLowerCase().includes(searchValue.toLowerCase())) return e;
                        })
                        .reverse()}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/**  Modal to handle create and update  **/}
          <RoomPlanModal
            isEditMode={isEditMode}
            initialValues={editingData}
            openNotificationWithIcon={openNotificationWithIcon}
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            setIsEditMode={setIsEditMode}
            setEditingData={setEditingData}
          />
        </Container>
      </div>
    </div>
  );
};

export default RoomPlans;
