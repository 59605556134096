import { createAsyncThunk } from '@reduxjs/toolkit';
import { ImageManagementService } from 'src/utils/services/service/imageManagementService';
import { IimageManagement } from 'src/utils/types/imageManagementTypes';


export const CreateImageData = createAsyncThunk(
  'imageManagement/createImageData',
  async ({ data }: { data: IimageManagement }, { rejectWithValue }) => {
    try {
      const response = await ImageManagementService.createimageData(data);
      if (response.status !== 201) {
     
        return rejectWithValue(response.data.message || 'Failed to create image data');
      }

      return response.data; 
    } catch (error: any) {
      console.error("Error in CreateImageData Thunk: ", error);

      return rejectWithValue(
        error.response?.data?.message || error.message || 'An unknown error occurred'
      );
    }
  },
);

export const GetAllImageData = createAsyncThunk(
  'imageManagement/getAllImageData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ImageManagementService.getAllimageData();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const GetSingleImageData = createAsyncThunk(
  'imageManagement/getSingleImageData',
  async ({ imageId }: { imageId: string }, { rejectWithValue }) => {
    try {
      const response = await ImageManagementService.getSingleimageData(imageId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const UpdateImageData = createAsyncThunk(
  'imageManagement/updateImageData',
  async ({ imageId, data }: { imageId: string; data: IimageManagement }, { rejectWithValue }) => {
    try {
      const response = await ImageManagementService.updateimageData(imageId, data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const DeleteImageData = createAsyncThunk(
  'imageManagement/deleteImageData',
  async ({ imageId }: { imageId: string }, { rejectWithValue }) => {
    try {
      const response = await ImageManagementService.deleteimageData(imageId);
      return imageId;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
