/* eslint-disable react-hooks/exhaustive-deps */
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { INotificationContacts } from 'src/utils/types/notificatioinContactsTypes';
import NotificationContactModal from './components/NotificationContactsModal';
import { FiEdit } from 'react-icons/fi';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { AppDispatch } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { DeleteNotificationContact, GetAllNotificationContacts } from 'src/slices/NotificationContacts/thunk';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const NotificationContacts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<INotificationContacts | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();

  const dispatch: AppDispatch = useDispatch();
  const { isLoading, notificationContacts, isDeleting } = useSelector((state: RootState) => state.notificationContacts);

  useEffect(() => {
    if (!isLoading && notificationContacts.length === 0) {
      dispatch(GetAllNotificationContacts());
    }
  }, []);

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const columns: TableProps<INotificationContacts>['columns'] = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 100,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateNotificationContacts" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="Cancel"
                okText="Proceed"
                okButtonId={`confirmAndDeleteNotificationContact`}
                triggerButtonId="deleteNotificationContact"
                onConfirm={() => {
                  deleteNotificationContact(record.id as string);
                }}
              />
            </div>
          </div>
        );
      },
    },

    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 100,
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: 100,
    },
    {
      title: 'Kind',
      dataIndex: 'kind',
      key: 'kind',
      width: 100,
    },
  ];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const deleteNotificationContact = async (contactId: string) => {
    if (!contactId) {
      openNotificationWithIcon('error', 'Did not find the contact id');
      return;
    }
    try {
      const response = await dispatch(
        DeleteNotificationContact({
          notificationId: contactId,
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        openNotificationWithIcon('success', 'Notification contact deleted successfully');
        dispatch(GetAllNotificationContacts());
      } else {
        openNotificationWithIcon('error', 'Error deleting notification contact');
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>Notification Contacts</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="Settings" breadcrumbItem="Notification Contacts" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="notificationContactsSearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder="Search contacts by name, email, kind"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="addNotificationContact"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> Add
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={notificationContacts?.filter((contact) => {
                          if (!searchValue) {
                            return contact;
                          } else if (
                            contact?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                            contact?.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
                            contact?.kind?.toLowerCase().includes(searchValue.toLowerCase())
                          ) {
                            return contact;
                          }
                        })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <NotificationContactModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default NotificationContacts;
