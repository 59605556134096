import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createLocation,
  deleteLocation,
  getCities,
  getContinents,
  getCountries,
  getLocations,
  getPOIs,
  getRegions,
  updateLocations,
} from 'src/utils/services/service/geographicLocationsService';

export const getGeographicLocations = createAsyncThunk<any, any>(
  'locations/getGeographicLocations',
  async (data: any) => {
    try {
      return await getLocations(data.value);
    } catch (error) {
      console.error(error);
    }
  },
);

export const createGeographicLocations = createAsyncThunk<any, any, { rejectValue: string }>(
  'locations/createGeographicLocations',
  async (data, thunkAPI) => {
    try {
      const res = await createLocation(data);
      if (res?.propertyId) {
        await thunkAPI.dispatch(getGeographicLocations({ value: data.propertyId }));
      }
      return res;
    } catch (error) {
      return error;
    }
  },
);

export const updateGeographicLocations = createAsyncThunk<any, any, { rejectValue: any; state: any }>(
  'locations/updateGeographicLocations',
  async (data: any, thunkAPI) => {
    const { selectedProperty, ...cont } = data;

    const res = await updateLocations(cont, selectedProperty);
    if (res!.message) {
      await thunkAPI.dispatch(getGeographicLocations(selectedProperty));
    }
    return res;
  },
);

export const deleteGeographicLocations = createAsyncThunk<any, any, { rejectValue: any; state: any }>(
  'locations/deleteGeographicLocations',
  async (data: any, thunkAPI) => {
    const res = await deleteLocation(data.value);
    if (res) {
      await thunkAPI.dispatch(getGeographicLocations(data));
    }

    return res;
  },
);

export const getAllCities = createAsyncThunk('locations/getAllCities', async () => {
  try {
    return await getCities();
  } catch (error) {
    return error;
  }
});

export const getAllContinents = createAsyncThunk('locations/getAllContinents', async () => {
  try {
    return await getContinents();
  } catch (error) {
    return error;
  }
});

export const getAllCountries = createAsyncThunk('locations/getAllCountries', async () => {
  try {
    return await getCountries();
  } catch (error) {
    return error;
  }
});

export const getAllRegions = createAsyncThunk('locations/getAllRegions', async () => {
  try {
    return await getRegions();
  } catch (error) {
    return error;
  }
});

export const getAllPois = createAsyncThunk('locations/getAllPois', async () => {
  try {
    return await getPOIs();
  } catch (error) {
    return error;
  }
});

