/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Card, Form, Input, Select } from 'antd';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { HotelData } from 'src/utils/types/HotelData';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { AppDispatch } from 'src/store';
import {
  CreateCompetitorHotelEntry,
  ReadHotelCompetitors,
  UpdateCompetitorHotelEntry,
} from 'src/slices/CompetitorHotels/thunk';

interface ICompetitorsHotelsModalProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: HotelData | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: HotelData | null) => void;
}

const CompetitorsHotelsModal = ({
  isOpen,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: ICompetitorsHotelsModalProps) => {
  const { selectedProperty } = useSelector((state: RootState) => state.porperty);
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.competitor);

  const dispatch: AppDispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEditMode && initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      // Set one competitor by default when creating a new record
      form.setFieldsValue({ competitors: [{}] });
    }
  }, [initialValues, isEditMode]);

  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    try {
      if (isEditMode) {
        const formData: HotelData = form.getFieldsValue();
        formData.propertyId = selectedProperty?.value?.toString();
        formData.id = initialValues?.id;

        if (!formData.propertyId) {
          openNotificationWithIcon('error', 'Please select a property');
          return;
        }

        if (!formData?.competitors || formData?.competitors?.length === 0) {
          openNotificationWithIcon('error', 'Please add at least one competitor');
          return;
        }

        const hasEmptyPlatform = formData.competitors.some((competitor) => {
          return !competitor.platforms || competitor.platforms.length === 0;
        });

        if (hasEmptyPlatform) {
          openNotificationWithIcon('error', 'Please add at least one platform for each competitor');
          return;
        }

        const response = await dispatch(
          UpdateCompetitorHotelEntry({
            data: formData,
            id: initialValues?.id as string,
          }),
        );

        if (response.payload.responseDetail.isSuccess) {
          openNotificationWithIcon('success', 'Updated successfully');
          handleCancel();
          form.resetFields();
          dispatch(ReadHotelCompetitors());
        } else {
          openNotificationWithIcon('error', 'Failed to update hotel entry');
        }
      } else {
        const formData: HotelData = form.getFieldsValue();
        formData.propertyId = selectedProperty?.value?.toString();

        if (!formData.propertyId) {
          openNotificationWithIcon('error', 'Please select a property');
          return;
        }

        if (!formData?.competitors || formData?.competitors?.length === 0) {
          openNotificationWithIcon('error', 'Please add at least one competitor');
          return;
        }

        const hasEmptyPlatform = formData.competitors.some((competitor) => {
          return !competitor.platforms || competitor.platforms.length === 0;
        });

        if (hasEmptyPlatform) {
          openNotificationWithIcon('error', 'Please add at least one platform for each competitor');
          return;
        }

        const response = await dispatch(
          CreateCompetitorHotelEntry({
            data: formData,
          }),
        );

        if (response.payload.responseDetail.isSuccess) {
          openNotificationWithIcon('success', 'Created successfully');
          handleCancel();
          form.resetFields();
          dispatch(ReadHotelCompetitors());
        } else {
          openNotificationWithIcon('error', 'Failed to create hotel entry');
        }
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', `Something went wrong`);
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
    }
    form.resetFields();
  };

  return (
    <div>
      <Modal size="lg" centered isOpen={isOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>{isEditMode ? 'Update' : 'Create'} Competitor Hotel</ModalHeader>
        <ModalBody>
          <Form layout="horizontal" form={form}>
            {/* Property ID */}
            <Card title="Manage Competitors">
              <Form.List name="competitors">
                {(fields, { add: addCompetitor, remove: removeCompetitor }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="border p-3" style={{ marginBottom: '20px' }}>
                        <Row>
                          <Col>
                            {/* Competitor URL (Pre-existing competitor) */}
                            <Form.Item
                              {...restField}
                              label="Competitor URL"
                              name={[name, 'url']}
                              rules={[
                                { required: true, message: 'Please enter competitor URL' },
                                { type: 'url', message: 'Please enter a valid URL' },
                              ]}
                            >
                              <Input placeholder="Competitor URL" />
                            </Form.Item>
                          </Col>
                          <Col
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              // alignItems: 'center',
                            }}
                          >
                            <div>
                              <Button className="btn btn-danger" onClick={() => removeCompetitor(name)}>
                                Remove Competitor
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        {/* Platforms for this competitor */}
                        <Form.List name={[name, 'platforms']}>
                          {(platformFields, { add: addPlatform, remove: removePlatform }) => (
                            <>
                              {platformFields.map(({ key: platformKey, name: platformName, ...restPlatformField }) => (
                                <div
                                  key={platformKey}
                                  style={{
                                    marginBottom: '10px',
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Form.Item
                                    {...restPlatformField}
                                    label="Platform"
                                    name={[platformName, 'platform']}
                                    rules={[{ required: true, message: 'Please select a platform' }]}
                                  >
                                    <Select placeholder="Select platform">
                                      <Select.Option value="Booking.com">Booking.com</Select.Option>
                                    </Select>
                                  </Form.Item>

                                  <Form.Item
                                    {...restPlatformField}
                                    label="Platform ID"
                                    name={[platformName, 'platformId']}
                                    rules={[{ required: true, message: 'Please enter platform ID' }]}
                                  >
                                    <Input placeholder="Platform ID" />
                                  </Form.Item>

                                  <Button className="btn btn-danger" onClick={() => removePlatform(platformName)}>
                                    Remove Platform
                                  </Button>
                                </div>
                              ))}
                              <Button onClick={() => addPlatform()}>Add Platform</Button>
                            </>
                          )}
                        </Form.List>
                      </div>
                    ))}
                    <Button onClick={() => addCompetitor()}>Add Competitor</Button>
                  </>
                )}
              </Form.List>
            </Card>

            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={handleCancel}
                style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
              >
                Cancel
              </Button>
              <AsyncButton
                buttonText={isEditMode ? 'Update' : 'Create'}
                isEditMode={isEditMode}
                isLoading={isCreating || isUpdating}
                id="ok"
                onClick={handleCreateOrUpdate}
                style={{ marginTop: 20 }}
              />
            </Col>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CompetitorsHotelsModal;
