import { createAsyncThunk } from '@reduxjs/toolkit';
import { HotelCompetitorService } from 'src/utils/services/service/hotelsCompetitorService';
import { HotelData } from 'src/utils/types/HotelData';

export const CreateCompetitorHotelEntry = createAsyncThunk(
  'competitorHotels/createCompetitorHotelEntry',
  async ({ data }: { data: HotelData }) => {
    try {
      const response = await HotelCompetitorService.createCompetitorHotelEntry(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const ReadHotelCompetitors = createAsyncThunk('competitorHotels/readHotelCompetitors', async () => {
  try {
    const response = await HotelCompetitorService.readHotelCompetitors();
    return response;
  } catch (error) {
    return error;
  }
});

export const ReadSpecificHotelCompetitor = createAsyncThunk(
  'competitorHotels/readSpecificHotelCompetitor',
  async ({ id }: { id: number }) => {
    try {
      const response = await HotelCompetitorService.readSpecificHotelCompetitor(id);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateCompetitorHotelEntry = createAsyncThunk(
  'competitorHotels/updateCompetitorHotelEntry',
  async ({ data, id }: { data: HotelData; id: string }) => {
    try {
      const response = await HotelCompetitorService.updateCompetitorHotelEntry(data, id);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteCompetitorHotelEntry = createAsyncThunk(
  'competitorHotels/deleteCompetitorHotelEntry',
  async ({ id }: { id: number | string }) => {
    try {
      const response = await HotelCompetitorService.deleteCompetitorHotelEntry(id);
      return response;
    } catch (error) {
      return error;
    }
  },
);
