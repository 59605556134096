import { createAsyncThunk } from '@reduxjs/toolkit';

import { addTenant } from 'src/utils/services/service/tenantService';
import { addTenantSuccessful, addingTenant } from './reducer';

export const addNewtenant = (name: string) => async (dispatch: any) => {
  dispatch(addingTenant);
  let res;
  res = await addTenant(name);
  if (res) {
    dispatch(addTenantSuccessful);
    return res;
  }
};

export const add_newTenant = createAsyncThunk('tenant/add_new_tenant', async (name: string) => {
  try {
    const response = await addTenant(name);
    return response;
  } catch (error) {
    return error;
  }
});

// addTenant;
