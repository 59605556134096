/* eslint-disable react-hooks/exhaustive-deps */
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { FiEdit } from 'react-icons/fi';
import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { ISpecialPeriods } from 'src/utils/types/specialPeriodsTypes';
import SpecialPeriodsModal from './Components/SpecialPeriodsModal';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { DeleteSpecialPeriods, GetAllSpecialPeriods } from 'src/slices/SpecialPeriods/thunk';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';

const SpecialPeriods = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<ISpecialPeriods | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const { isLoading, specialPeriods, isDeleting } = useSelector((state: RootState) => state.specialPeriods);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && specialPeriods.length === 0) {
      dispatch(GetAllSpecialPeriods());
    }
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const columns: TableProps<ISpecialPeriods>['columns'] = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 150,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateSpecialPeriod" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="Cancel"
                okText="Proceed"
                okButtonId={`confirmAndDeleteSpecialPeriod`}
                triggerButtonId="deleteSpecialPeriod"
                onConfirm={() => {
                  deleteSpecialPeriod(record.id!);
                }}
              />
            </div>
          </div>
        );
      },
    },

    {
      title: 'Property ID',
      dataIndex: 'propertyId',
      key: 'propertyId',
      width: 100,
    },
    {
      title: 'Kind',
      dataIndex: 'kind',
      key: 'kind',
      width: 100,
      filters: Array.from(new Set(specialPeriods?.map((contact) => contact.kind))).map((kind) => ({
        text: kind,
        value: kind,
      })),
      onFilter: (value, record) => record.kind.indexOf(value as string) === 0,
    },
    {
      title: 'Start Date',
      dataIndex: 'start',
      key: 'start',
      width: 200,
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'End Date',
      dataIndex: 'end',
      key: 'end',
      width: 200,
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 300,
    },
  ];

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deleteSpecialPeriod = async (eventId: string) => {
    if (!eventId) {
      openNotificationWithIcon('error', 'Did not find the event id');
      return;
    }
    try {
      const response = await dispatch(
        DeleteSpecialPeriods({
          eventId,
        }),
      );
      if (response.payload === eventId) {
        openNotificationWithIcon('success', 'Special period deleted successfully');
        dispatch(GetAllSpecialPeriods());
      } else {
        openNotificationWithIcon('error', 'Error deleting special period');
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>Special Periods</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="Settings" breadcrumbItem="Special Periods" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="searchSpecialPeriods"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder="Search periods by title."
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="createSpecialPeriodButton"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> Add
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={specialPeriods
                          ?.filter((event: ISpecialPeriods) => {
                            if (searchValue === '') return event;
                            else if (
                              event.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
                              event.kind?.toLowerCase().includes(searchValue.toLowerCase())
                            ) {
                              return event;
                            }
                          })
                          ?.reverse()}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <SpecialPeriodsModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SpecialPeriods;
