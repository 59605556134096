
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useEffect,useState  } from 'react';
import { IManageHotel } from 'src/utils/types/manageHotelTypes';
import { CreateManageHotelData,GetAllManageHotelData, UpdateManageHotelData } from 'src/slices/ManageHotel/thunk';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

interface IManageHotelProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IManageHotel | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IManageHotel | null) => void;
}


const ManageHotelModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  isEditMode = false,
  setIsEditMode,
  setEditingData,
}: IManageHotelProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.manageHotel);
  const dispatch: AppDispatch = useDispatch();

  // State to store unique dropdown options
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const [kindOptions, setKindOptions] = useState<string[]>([]);
  const [segmentOptions, setSegmentOptions] = useState<string[]>([]);


  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      console.log('initialValues', initialValues);
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);


useEffect(() => {
   
    const fetchManageHotelData = async () => {
      const response = await dispatch(GetAllManageHotelData());

      if (response.payload) {
      
        const uniqueCategories = Array.from(
          new Set((response.payload as IManageHotel[]).map((hotel) => hotel.category))
        );
        const uniqueKinds = Array.from(new Set((response.payload as IManageHotel[]).map((hotel) => hotel.kind)));
        const uniqueSegments = Array.from(new Set((response.payload as IManageHotel[]).map((hotel) => hotel.segment)));

        setCategoryOptions(uniqueCategories);
        setKindOptions(uniqueKinds);
        setSegmentOptions(uniqueSegments);
      }
    };

    fetchManageHotelData();
  }, [dispatch]);
  
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IManageHotel = form.getFieldsValue();
    console.log(formData);

    try {
      if (isEditMode) {
        // ** Update Operation
        const payload = formData;
        // payload.tenant = initialValues?.tenant;
        payload.id = initialValues?.id;
        const response = await dispatch(
          UpdateManageHotelData({
            data: payload,
            hoteldataId: initialValues?.id as string,
          }),
        );
        // ** Handled Response
        if ((response.payload as IManageHotel)['id']) {
          openNotificationWithIcon('success', 'Hotel data updated successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllManageHotelData());
        } else {
          openNotificationWithIcon('error', `Error updating Hotel data`);
        }
      } else {
        // ** Create Operation
        const payload = formData;
        const response = await dispatch(
          CreateManageHotelData({
            data: payload,
          }),
        );

        // ** Handled Response
        if ((response.payload as IManageHotel)['id']) {
          openNotificationWithIcon('success', 'Hotel data created successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllManageHotelData());
        } else {
          openNotificationWithIcon('error', `Error creating Hotel data`);
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', `Something went wrong`);
    }
  };



  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  
  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? 'Update' : 'Create'} Manage Hotel
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
          <Form.Item
              required
              label="Category"
              name="category"
              rules={[
                {
                  required: true,
                  message: 'Please select a category',
                },
              ]}
            >
              <Select id="manageHotelCategory" placeholder="Select Category">
                {categoryOptions.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required={true}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}
            >
              <Input id="manageHotelName" placeholder="Name" className="p-2 px-2" />
            </Form.Item>
           
            <Form.Item
              required
              label="Kind"
              name="kind"
              rules={[
                {
                  required: true,
                  message: 'Please select kind',
                },
              ]}
            >
              <Select id="manageHotelKind"  placeholder="Select Kind">
                {kindOptions.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required
              label="Segment"
              name="segment"
              rules={[
                {
                  required: true,
                  message: 'Please select segment',
                },
              ]}
            >
              <Select id="manageHotelSegment" placeholder="Select Segment">
                {segmentOptions.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="manage-hotel-cancel-button"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>
            <Button
              id="hotelCreateORUpdateButton"
              disabled={isCreating || isUpdating}
              onClick={handleCreateOrUpdate}
              style={{ marginTop: 20 }}
            >
              {isCreating || isUpdating
                ? isEditMode
                  ? 'Updating . . .'
                  : 'Creating . . .'
                : isEditMode
                  ? 'Update'
                  : 'Create'}
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManageHotelModal;
