import { notification } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { IExternalApiKeys } from 'src/utils/types/externalApiKeysTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { Link } from 'react-router-dom';
import { TableProps } from 'antd/lib';
import { FiEdit } from 'react-icons/fi';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import TableView from 'src/components/Common/TableView/TableView';
import ExternalApiKeysModal from './components/ExternalApiKeysModal';
import { AppDispatch } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { DeleteExternalApiKey, GetAllExternalApiKeys } from 'src/slices/ExternalApiKeys/thunk';

const ExternalApiKeys = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IExternalApiKeys | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const dispatch: AppDispatch = useDispatch();
  const { isLoading, externalApiKeys, isDeleting } = useSelector((state: RootState) => state.externalApiKeys);

  useEffect(() => {
    if (!isLoading && externalApiKeys.length === 0) {
      dispatch(GetAllExternalApiKeys());
    }
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const columns: TableProps<IExternalApiKeys>['columns'] = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 150,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateExternalApiKey" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="Cancel"
                okText="Proceed"
                okButtonId={`confirmAndDeleteExternalApiKey`}
                triggerButtonId="deleteExternalApiKey"
                onConfirm={() => {
                  deleteExternalAPiKey(record.id as string);
                }}
              />
            </div>
          </div>
        );
      },
    },
  
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'Property Id',
      key: 'propertyId',
      dataIndex: 'propertyId',
      width: 200,
    },
    {
      title: 'External System',
      dataIndex: 'externalSystem',
      key: 'externalSystem',
      width: 200,
    },
    {
      title: 'Api Key',
      key: 'apiKey',
      dataIndex: 'apiKey',
      width: 300,
    },
  ];

  const deleteExternalAPiKey = async (externalApiKeyId: string) => {
    if (!externalApiKeyId) {
      openNotificationWithIcon('error', 'Id not found');
      return;
    }
    try {
      const response = await dispatch(
        DeleteExternalApiKey({
          externalApiKeyId,
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        openNotificationWithIcon('success', 'External api key deleted successfully');
        dispatch(GetAllExternalApiKeys());
      } else {
        openNotificationWithIcon('error', 'Error deleting external api key');
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>External Api Keys</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="System Settings" breadcrumbItem="External Api Keys" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="externalApiKeySearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder="Search keys by name, property id, external system"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="addExternalApiKey"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> Add
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={externalApiKeys?.filter((e: IExternalApiKeys) => {
                          if (searchValue === '') return e;
                          else if (
                            e?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                            e?.propertyId?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                            e?.externalSystem?.toLowerCase().includes(searchValue.toLowerCase())
                          )
                            return e;
                        })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <ExternalApiKeysModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ExternalApiKeys;
