import { createSlice } from '@reduxjs/toolkit';
import { add_newTenant } from './thunk';

interface ContactsStateType {
  users: Array<any>;
  userProfile: object;
  error?: string | object | null | undefined | unknown;
  loading: boolean;
  isCreating: boolean;
}
export const initialState: ContactsStateType = {
  users: [],
  userProfile: {},
  error: '',
  loading: false,
  isCreating: false,
};

const contactsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    addingTenant(state) {
      state.loading = true;
    },
    addTenantSuccessful(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(add_newTenant.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(add_newTenant.fulfilled, (state) => {
        state.isCreating = false;
      })
      .addCase(add_newTenant.rejected, (state) => {
        state.isCreating = false;
      });
  },
});
export const { addingTenant, addTenantSuccessful } = contactsSlice.actions;

export default contactsSlice.reducer;
