import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from 'src/helpers/firebase_helper'
import {
    postFakeForgetPwd,
    postJwtForgetPwd,
} from 'src/helpers/fakebackend_helper'

import env from "src/env"

const fireBaseBackend = getFirebaseBackend()

export const userForgetPassword = (user: any, _history: any) => async (dispatch: any) => {
    try {
        let response;
        if (env.VITE_REACT_APP_DEFAULTAUTH() === "firebase") {

            response = fireBaseBackend.forgetPassword(
                user.email
            )

        } else if (env.VITE_REACT_APP_DEFAULTAUTH() === "jwt") {
            response = postJwtForgetPwd(
                user.email
            )
        } else {
            response = postFakeForgetPwd(
                user.email
            )
        }

        const data = await response;

        if (data) {
            dispatch(userForgetPasswordSuccess(
                "Reset link are sended to your mailbox, check there first"
            ))
        }
    } catch (forgetError) {
        dispatch(userForgetPasswordError(forgetError))
    }
}