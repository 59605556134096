import axios from 'axios';
import env from 'src/env';
import { INotificationContacts } from 'src/utils/types/notificatioinContactsTypes';

const baseURL = env.VITE_NOTIFICATION_CONTACTS_BASE_URL();
const notificationBaseUrl = '/base/contacts';

const getAllNotificationContacts = async (): Promise<INotificationContacts[] | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: INotificationContacts[] = await axios.get(`${notificationBaseUrl}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching all notification contacts:', error);
    throw error;
  }
};

const getSingleNotificationContact = async (notificationId: string): Promise<INotificationContacts | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: INotificationContacts = await axios.get(`${notificationBaseUrl}/${notificationId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching single notification contact:', error);
    throw error;
  }
};

const createNotificationContact = async (data: INotificationContacts): Promise<INotificationContacts | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: INotificationContacts = await axios.post(`${notificationBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating notification contact:', error);
    throw error;
  }
};

const updateNotificationContact = async (
  notificationId: string,
  data: INotificationContacts,
): Promise<INotificationContacts | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: INotificationContacts = await axios.put(`${notificationBaseUrl}/${notificationId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating notification contact:', error);
    throw error;
  }
};

const deleteNotificationContact = async (notificationId: string): Promise<void> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    await axios.delete(`${notificationBaseUrl}/${notificationId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });
  } catch (error) {
    console.error('Error deleting notification contact:', error);
    throw error;
  }
};

export const NotificationContactService = {
  getAllNotificationContacts,
  getSingleNotificationContact,
  createNotificationContact,
  updateNotificationContact,
  deleteNotificationContact,
};
