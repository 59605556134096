import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { changeUserFields, createNewUser, getUsers } from "./thunk";
import { IUserResponse } from "src/utils/types/userTypes";

interface ContactsStateType {
    users: Array<IUserResponse>,
    error?: string | null,
    loading: boolean,
    totalPages: number
}

const initialState: ContactsStateType = {
    users: [],
    totalPages: 0,
    error: null,
    loading: false,
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle pending states
            .addCase(getUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            // Handle fulfilled states
            .addCase(getUsers.fulfilled, (state, action: PayloadAction<{users: IUserResponse[], total: number}>) => {
                state.users = action.payload.users;
                state.totalPages = action.payload.total;
                state.loading = false;
            })
            // Handle rejected states, explicitly casting error to a string
            .addCase(getUsers.rejected, (state, action) => {
                state.error = action.error.message ?? null; // Fallback to null if message is undefined
                state.loading = false;
            })
            // Repeat the above pattern for other actions (createNewUser, changeUserFields)
            .addCase(createNewUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createNewUser.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
                state.users.push(action.payload); // Assuming payload is the user object directly
                state.loading = false;
            })
            .addCase(createNewUser.rejected, (state, action) => {
                state.error = action.error.message ?? null;
                state.loading = false;
            })
            .addCase(changeUserFields.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(changeUserFields.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
                const index = state.users.findIndex(user => user.id === action.payload.id);
                if (index !== -1) {
                    state.users[index] = action.payload;
                }
                state.loading = false;
            })
            .addCase(changeUserFields.rejected, (state, action) => {
                state.error = action.error.message ?? null;
                state.loading = false;
            });
    },
});

export default usersSlice.reducer;