/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { AppDispatch } from 'src/store';
import { ITag } from 'src/utils/types/tagTypes';
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TagsModal from './components/TagsModal';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { TableProps } from 'antd/lib';
import { FiEdit } from 'react-icons/fi';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import TableView from 'src/components/Common/TableView/TableView';
import { DeleteTag, GetAllTags } from 'src/slices/Tags/thunk';

const Tags = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<ITag | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const { isLoading, tags, isDeleting } = useSelector((state: RootState) => state.tags);
  const dispatch: AppDispatch = useDispatch();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (!isLoading && tags.length === 0) {
      dispatch(GetAllTags());
    }
  }, []);

  const columns: TableProps<ITag>['columns'] = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 100,
      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateTag" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="Cancel"
                okText="Proceed"
                okButtonId={`confirmAndDeleteTag`}
                triggerButtonId="deleteTag"
                onConfirm={() => {
                  deleteTag(record.id as string);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Assignments',
      dataIndex: 'assignments',
      key: 'assignments',
      width: 300,
      render: (items: { year: number; week: number }[]) => {
        return (
          <div>
            {items.map((item, i) => (
              <div key={i}>
                Year: <span>{item.year}</span>, Week: {item.week}
                <hr />
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  const deleteTag = async (tagId: string) => {
    if (!tagId) {
      openNotificationWithIcon('error', 'Tag id is required');
      return;
    }
    try {
      const response: any = await dispatch(
        DeleteTag({
          tagId,
        }),
      );
      console.log(response);
      if (response?.payload?.status === 204) {
        openNotificationWithIcon('success', 'Tag deleted successfully');
        dispatch(GetAllTags());
      } else {
        openNotificationWithIcon('error', 'Error deleting tag');
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  return (
    <div>
      {contextHolder}
      <div className="page-content">
        <MetaTags>
          <title>Tags</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Settings" breadcrumbItem="Tags" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div>
                        <SearchBar
                          id="searchTags"
                          value={searchValue}
                          onChange={handleSearchChange}
                          placeholder="Search tags by name"
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Button
                            id="createTagButton"
                            className="btn btn-light"
                            onClick={() => {
                              setIsModalOpen(!isModalOpen);
                            }}
                          >
                            <i className="uil uil-plus me-1"></i> Add
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableView
                      loading={isLoading || isDeleting}
                      columns={columns}
                      dataSource={tags
                        ?.filter((tag) => {
                          if (!searchValue) return true;
                          const searchWords = searchValue?.toLowerCase().split(' ');
                          return searchWords?.some((word) => tag?.name?.toLowerCase()?.includes(word));
                        })
                        ?.reverse()}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/**  Modal to handle create and update  **/}
          <TagsModal
            isEditMode={isEditMode}
            initialValues={editingData}
            openNotificationWithIcon={openNotificationWithIcon}
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            setIsEditMode={setIsEditMode}
            setEditingData={setEditingData}
          />
        </Container>
      </div>
    </div>
  );
};

export default Tags;
