import { createAsyncThunk } from '@reduxjs/toolkit';
import { processCancellationPrediction } from 'src/utils/services/service/dashboardService';

export const processCancellationPredictionSlice = createAsyncThunk<any, any>(
  'dashboard/processCancellationPrediction',
  async (data) => {
    try {
      return await processCancellationPrediction(data.modelName, data.file);
    } catch (error) {
      throw error;
    }
  },
);

