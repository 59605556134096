export const roomTypes: string[] = [
  'Single',
  'Double',
  'Twin',
  'Suite',
  'Family',
  'Deluxe',
  'Executive',
  'Presidential Suite',
  'Studio',
  'Penthouse',
  'Junior Suite',
  'Superior Room',
  'Bungalow',
  'Cabana',
  'Villa',
];
