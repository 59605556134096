import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAnomalyDetect } from 'src/utils/services/service/anomalyService';

export const getAnomaly = createAsyncThunk<any, any, { rejectValue: string }>(
    "anomlay/getAnomaly",
    async (data: any) => {
        try {
            const response = await getAnomalyDetect(data)
            return response.data;
        } catch (error) {
            return error;
        }
    }
);
