import { createAsyncThunk } from '@reduxjs/toolkit';
import { TagService } from 'src/utils/services/service/tagService';
import { ITag } from 'src/utils/types/tagTypes';

export const CreateTag = createAsyncThunk(
  'tags/createTag',
  async ({ data }: { data: { name: string; propertyId: string } }) => {
    try {
      const response = await TagService.createTag(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetAllTags = createAsyncThunk('tags/getAllTags', async () => {
  try {
    const response = await TagService.getListOfTags();
    return response;
  } catch (error) {
    return error;
  }
});

export const UpdateTag = createAsyncThunk('tags/updateTag', async ({ tagId, data }: { tagId: string; data: ITag }) => {
  try {
    const response = await TagService.updateATag(tagId, data);
    return response;
  } catch (error) {
    return error;
  }
});

export const AddAssignmentToTag = createAsyncThunk(
  'tags/addAssignmentToTag',
  async ({ tagId, data }: { tagId: string; data: { propertyId: string; year: number; week: number } }) => {
    try {
      const response = await TagService.addAssignmentToTag(tagId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteTag = createAsyncThunk('tags/deleteTag', async ({ tagId }: { tagId: string }) => {
  try {
    const response = await TagService.deleteATag(tagId);
    return response;
  } catch (error) {
    return error;
  }
});
