import { createAsyncThunk } from '@reduxjs/toolkit';
import { PriceListService } from 'src/utils/services/service/priceList';

import { IPriceList } from 'src/utils/types/priceListTypes';

export const CreatePriceListData = createAsyncThunk(
  'priceList/createPriceListData',
  async ({ data }: { data: IPriceList }) => {
    try {
      const response = await PriceListService.createPriceListData(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetAllPriceListData = createAsyncThunk(
  'priceList/getAllPriceListData',
  async () => {
    try {
      const response = await PriceListService.getAllPriceListData();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetSinglePriceListData = createAsyncThunk(
  'priceList/getSinglePriceListData',
  async ({ pricelistId }: { pricelistId: string }) => {
    try {
      const response = await PriceListService.getSinglePriceListData(pricelistId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdatePriceListData = createAsyncThunk(
  'priceList/updatePriceListData',
  async ({ pricelistId, data }: { pricelistId: string; data: IPriceList }) => {
    try {
      const response = await PriceListService.updatePriceListData(pricelistId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeletePriceListData = createAsyncThunk(
  'priceList/deletePriceListData',
  async ({ pricelistId }: { pricelistId: string }) => {
    try {
      const response = await PriceListService.deletePriceListData(pricelistId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
