import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAnomaly } from "./thunk";

interface AnomalyStateType {
    anomlayData: Array<any>,
    error?: string | object | null | undefined | unknown,
    anomalyLoading: boolean
}
export const initialState: AnomalyStateType = {
    anomlayData: [],
    error: '',
    anomalyLoading: false
};

const anomalySlice = createSlice({
    name: "anomlay",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAnomaly.pending, (state) => {
                state.anomalyLoading = true;
                state.error = null;
            })

            .addCase(getAnomaly.fulfilled, (state, action: PayloadAction<any>) => {
                state.anomlayData = action.payload.value;
                state.anomalyLoading = false;
            })

            .addMatcher(isError, (state, action: PayloadAction<any>) => {
                state.error = action.payload;
                state.anomalyLoading = false;
            });
    }

});

export default anomalySlice.reducer;

function isError(action: AnyAction) {
    return action.type.endsWith('rejected');
}
