import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  processTotalRatePredictionSlice
} from 'src/slices/TotalRatePrediction/thunk';

interface TotalRatePredictionStateType {
  totalRatePrediction: Array<any>;
  event: any;
  error?: string | object | null | undefined | unknown;
  loading: boolean;
  totalRatePredictionLoading: boolean
  categories: any,
}
export const initialState: TotalRatePredictionStateType = {
  totalRatePrediction: [],
  event: null,
  error: '',
  loading: false,
  categories: [],
  totalRatePredictionLoading: false
};

const TotalRatePredictionSlice = createSlice({
  name: 'totalRatePrediction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processTotalRatePredictionSlice.pending, (state) => {
        state.totalRatePredictionLoading = true;
        state.error = null;
      })

      .addCase(processTotalRatePredictionSlice.fulfilled, (state, action) => {
        state.totalRatePrediction = action.payload;
        state.totalRatePredictionLoading = false;
      })

      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default TotalRatePredictionSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
