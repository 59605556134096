/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllExternalEvents,
  createExternalEvent,
  updateExternalEvent,
  deleteExternalEvent,
  getAllCategories,
  getAllExternalEvent,
} from 'src/utils/services/service/externalEventsService';

export const getExternalEvents = createAsyncThunk<any, any>('events/getExternalEvents', async (data) => {
  try {
    return await getAllExternalEvents(data.value);
  } catch (error) {
    throw error;
  }
});

export const getExternalEvent = createAsyncThunk<any, any>('events/getExternalEvent', async (eventId) => {
  try {
    return await getAllExternalEvent(eventId);
  } catch (error) {
    throw error;
  }
});

export const getEventCategories = createAsyncThunk('events/getEventCategories', async () => {
  try {
    return await getAllCategories();
  } catch (error) {
    throw error;
  }
});

export const createExternalEvents = createAsyncThunk<any, any>(
  'events/createExternalEvents',
  async (data: any, thunkAPI) => {
    const { propertyId, tenants, ...cont } = data;
    try {
      console.log(tenants);
      cont.tenants = tenants;
      const res = await createExternalEvent(cont, tenants);
      if (res?.id) {
        await thunkAPI.dispatch(getExternalEvents(propertyId));
      }
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateExternalEvents = createAsyncThunk('events/updateExternalEvents', async (data: any, thunkAPI) => {
  try {
    const { propertyId, id, ...cont } = data;
    const res = await updateExternalEvent(cont, id);
    if (res && res.message) {
      await thunkAPI.dispatch(getExternalEvents(propertyId));
    }
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteExternalEvents = createAsyncThunk('events/deleteExternalEvents', async (data: any, thunkAPI) => {
  try {
    const res = await deleteExternalEvent(data.eventId);
    if (res) {
      await thunkAPI.dispatch(getExternalEvents(data.propertyId));
    }
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
