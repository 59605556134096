import { createSlice } from '@reduxjs/toolkit';
import { UpdateProperties, getProperties, getPropertiesFromServer } from './thunk';

interface PropertiesStateType {
  properties: Array<any>;
  selectedProperty: any;
  error?: string | object | null | undefined | unknown;
  loading: boolean;
  Properties: Array<any>;
  isError: boolean;
  realFormate: Array<any>;
  isUpdating: boolean;
}
export const initialState: PropertiesStateType = {
  properties: [],
  selectedProperty: '',
  error: '',
  loading: false,
  Properties: [],
  isError: false,
  realFormate: [],
  isUpdating: false,
};

const propertySlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setProperty(state, action) {
      state.selectedProperty = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getProperties.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })

      // .addCase(getProperties.fulfilled, (state, action) => {
      //   // const transformedProperties = action.payload.properties
      //   //   ?.map((property: any) => ({
      //   //     value: property.propertyId,
      //   //     label: property.propertyName,
      //   //   }))
      //   //   ?.sort((a: any, b: any) => a.label.localeCompare(b.label));

      //   // state.properties = transformedProperties;
      //   // state.selectedProperty = transformedProperties?.[0] || null;
      //   state.loading = false;
      // })
      .addCase(getPropertiesFromServer.pending, (state) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(getPropertiesFromServer.fulfilled, (state, action) => {
        console.log(action?.payload);
        state.realFormate = action.payload;
        const transformedProperties = (state.Properties = action.payload
          ?.map((property: any) => ({
            value: property.mandantId,
            label: `${property.mandantId} / ${property.place}`,
          }))
          ?.sort((a: any, b: any) => a.label.localeCompare(b.label)));
        state.properties = transformedProperties;
        state.selectedProperty = transformedProperties?.[0] || null;
        state.loading = false;
        state.isError = false;
      })
      .addCase(getPropertiesFromServer.rejected, (state) => {
        state.loading = false;
        state.isError = true;
      })
      .addCase(UpdateProperties.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateProperties.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateProperties.rejected, (state) => {
        state.isUpdating = false;
      });
  },
});

export const { setProperty } = propertySlice.actions;

export default propertySlice.reducer;
