import { Form, Input, notification, Switch, Select, Upload, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useEffect, useState } from 'react';
import { IimageManagement } from 'src/utils/types/imageManagementTypes';
import { UploadOutlined } from '@ant-design/icons';
import { CreateImageData, GetAllImageData, UpdateImageData } from 'src/slices/ImageManagement/thunk';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

interface IimageManagementProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IimageManagement | null;
  initialViewValues?: IimageManagement | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  isViewMode?: boolean;
  setIsViewMode: (isViewMode: boolean) => void;
  setEditingData: (data: IimageManagement | null) => void;
  setViewData: (data: IimageManagement | null) => void;
}

const ImageManagementModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  initialViewValues,
  isEditMode = false,
  setIsEditMode,
  isViewMode = false,
setIsViewMode,

  setEditingData,
  setViewData,
}: IimageManagementProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating, imageManagement } = useSelector((state: RootState) => state.imageManagement);
  const dispatch: AppDispatch = useDispatch();
  const [fileList, setFileList] = useState<any[]>([]);
  const [imagePreview, setImagePreview] = useState<string | null>(null);


  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
      
      if (initialValues.filename) {
        setImagePreview(`/src/assets/images/users/${initialValues.filename}`);
      }

      console.log("isEditMode cal")
    } 
    else if (isViewMode && initialViewValues !== null) {
      form.setFieldsValue(initialViewValues);
      if (initialViewValues.filename) {
        setImagePreview(`/src/assets/images/users/${initialViewValues.filename}`);
      }
      console.log("isViewMode call")
    } else {
      resetFormState();
    }
  }, [initialValues, isEditMode, isViewMode, initialViewValues]);


  const onFileChange = (info: any) => {
    if (info.fileList.length > 0) {
      setFileList(info.fileList);
      const file = info.fileList[0].originFileObj;

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
          form.setFieldsValue({ filename: file.name });
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImagePreview(null);
      form.setFieldsValue({ filename: '' });
    }
  };

  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IimageManagement = form.getFieldsValue();
    console.log("Form Data: ", formData);
  
    try {
      if (isEditMode) {
      
        openNotificationWithIcon('success', 'Image updated successfully');
      } else {
       
        openNotificationWithIcon('success', 'Image created successfully');
      }
      resetFormState(); 
      toggle();  
    } catch (error) {
      console.error("An error occurred: ", error);
      openNotificationWithIcon('error', `Error ${isEditMode ? 'updating' : 'creating'} image`);
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
    }
    if (isViewMode) {
      setIsViewMode(false);
      setViewData(null);
    }
    resetFormState();
  };

  const resetFormState = () => {
    form.resetFields();
    setFileList([]);
    setImagePreview(null);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? 'Update' : isViewMode? 'View': 'Create'} Image
         
        </ModalHeader>
        <ModalBody className="scrollModal">
          
        {isViewMode ? (
            <div>
              <p><strong>File Name:</strong> {initialViewValues?.filename}</p>
              {imagePreview && <Image width={200} src={imagePreview} alt="Preview" />}
              <p><strong>Hashtags:</strong> {initialViewValues?.hashtags?.join(', ')}</p>
              <p><strong>Keywords:</strong> {initialViewValues?.keywords?.join(', ')}</p>
              <p><strong>Enabled:</strong> {initialViewValues?.enabled ? 'Yes' : 'No'}</p>
            </div>
          ) : (
       <Form layout="vertical" form={form}>
            <Form.Item label="File Name"
              name="filename"
              rules={[{ required: true, message: 'Please input the filename!' }]}>
              <Upload
                beforeUpload={() => false}  
                fileList={fileList}
                onChange={onFileChange}
                maxCount={1}  
              >
                <Button icon={<UploadOutlined />}>Choose File</Button>
              </Upload>
              {imagePreview && (
                <>
                  <Image
                    width={200}
                    src={imagePreview}
                    alt="Preview"
                    preview={false} 
                  />
                </>
              )}
            </Form.Item>
            <Form.Item
              label="Hashtags"
              name="hashtags"
              rules={[{ required: true, message: 'Please input the hashtags!' }]}
            >
              <Select mode="tags" style={{ width: '100%' }} placeholder="Enter hashtags" />
            </Form.Item>
            <Form.Item
              label="Keywords"
              name="keywords"
              rules={[{ required: true, message: 'Please input the keywords!' }]}
            >
              <Select mode="tags" style={{ width: '100%' }} placeholder="Enter keywords" />
            </Form.Item>
            <Form.Item
              label="Enabled"
              name="enabled"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Form>
  
            )}



          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="manage-hotel-cancel-button"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>
            {!isViewMode && (
  <Button
    id="mealPlansCreateORUpdateButton"
    disabled={isCreating || isUpdating}
    onClick={handleCreateOrUpdate}
    style={{ marginTop: 20 }}
  >
    {isCreating || isUpdating
      ? isEditMode
        ? 'Updating . . .'
        : 'Creating . . .'
      : isEditMode
      ? 'Update'
      : 'Create'}
  </Button>
 )} 

          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ImageManagementModal;
