/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AppDispatch, RootState } from 'src/store';
import {
  CreateNotificationContact,
  GetAllNotificationContacts,
  UpdateNotificationContact,
} from 'src/slices/NotificationContacts/thunk';
import { useEffect } from 'react';
import { INotificationContacts } from 'src/utils/types/notificatioinContactsTypes';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

interface ICreateNotificationContactsProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: INotificationContacts | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: INotificationContacts | null) => void;
}
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const NotificationContactModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  isEditMode = false,
  setIsEditMode,
  setEditingData,
}: ICreateNotificationContactsProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.notificationContacts);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      console.log('initialValues', initialValues);
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  // ** Function to handle create or update
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: INotificationContacts = form.getFieldsValue();
    console.log(formData);

    try {
      if (isEditMode) {
        const payload = formData;
        payload.id = initialValues?.id;

        const response = await dispatch(
          UpdateNotificationContact({
            data: payload,
            notificationId: initialValues?.id as string,
          }),
        );

        // ** Handled Response
        if ((response.payload as INotificationContacts)['id']) {
          openNotificationWithIcon('success', 'Notification contact updated  successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllNotificationContacts());
        } else {
          openNotificationWithIcon('error', `Error updating notification contact`);
        }
      } else {
        const payload = formData;
        const response = await dispatch(
          CreateNotificationContact({
            data: payload,
          }),
        );
        // ** Handled Response
        if ((response.payload as INotificationContacts)['id']) {
          openNotificationWithIcon('success', 'Notification contact created successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllNotificationContacts());
        } else {
          openNotificationWithIcon('error', `Error creating notification contact`);
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', `Error ${isEditMode ? 'updating' : 'creating'} Notification Contact`);
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? 'Update' : 'Create'} Notification Contact
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}
            >
              <Input id="notificationContactName" placeholder="Name" className="p-2 px-2" />
            </Form.Item>
            <Form.Item
              style={{ marginTop: '-10px' }}
              required={true}
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter email address',
                },
              ]}
            >
              <Input id="notificationContactEmail" placeholder="Email address" className="p-2 px-2" />
            </Form.Item>
            <Form.Item
              style={{ marginTop: '-10px' }}
              required={true}
              label="Kind"
              name="kind"
              rules={[
                {
                  required: true,
                  message: 'Please select kind',
                },
              ]}
            >
              <Select
                id="notificationContactKind"
                allowClear
                placeholder="Select kind"
                style={{
                  height: '40px',
                }}
              >
                {[
                  { value: 'tax_advisor', label: 'Tax Advisor' },
                  { value: 'financial_advisor', label: 'Financial Advisor' },
                  { value: 'investment_broker', label: 'Investment Broker' },
                  { value: 'legal_advisor', label: 'Legal Advisor' },
                  { value: 'real_estate_agent', label: 'Real Estate Agent' },
                ].map((item) => (
                  <Select.Option key={item.label} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="notificationCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>
            <AsyncButton
              buttonText={isEditMode ? 'Update' : 'Create'}
              isEditMode={isEditMode}
              style={{ marginTop: 20 }}
              isLoading={isCreating || isUpdating}
              onClick={handleCreateOrUpdate}
              id="notificationCreateORUpdateButton"
            />
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NotificationContactModal;
