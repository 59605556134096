import { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

type ComponentProps = {
  options: Array<any>;
  onChange: (arg: any) => void;
  defaultValues?: any[];
  label?: string;
  placeholder?: string;
  selectedBackground?: string;
  isDisabled?: boolean;
  name?: string;
  value?: Array<any>;
  required?: boolean;
  isLoading?: boolean;
  isEmpty?: boolean;
  errorMessage?: string
};

const MultipleSelect = ({
  options,
  onChange,
  defaultValues = [],
  label,
  placeholder,
  selectedBackground,
  isDisabled,
  name,
  required,
  isLoading,
  isEmpty = false,
  errorMessage,
}: ComponentProps) => {
  const selectOptions = options?.map((value) => ({ label: value.name, value: value.name }));
  const animatedComponents = makeAnimated();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    setShowErrorMessage(isEmpty)
  }, [isEmpty])

  const defValues = defaultValues?.map((value) => ({ label: value.name, value: value.name }));
  const onSelectChange = (val: any) => {
    onChange(val?.map((item: any) => item.value));
    setShowErrorMessage(val?.length === 0);
  };

  return (
    <div>
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={defValues}
        onChange={onSelectChange}
        isMulti
        options={selectOptions}
        label={label}
        isDisabled={isDisabled}
        name={name}
        placeholder={placeholder}
        required={required}
        isLoading={isLoading}
        styles={{
          multiValue: (styles: any) => {
            return {
              ...styles,
              backgroundColor: selectedBackground || '#e5f4ff',
            };
          },
          control: (baseStyles: any, _: any) => ({
            ...baseStyles,
            borderColor: showErrorMessage ? 'red' : '#e2e5e8',
          }),
        }}
      />
      {showErrorMessage && <span style={{ color: 'red' }}>{errorMessage || 'Value cannot be empty'}</span>}
    </div>
  );
};

export default MultipleSelect;
