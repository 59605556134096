import { ChangeEvent, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Nav, NavItem, Row, Spinner } from 'reactstrap';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { createNewKey, getApiKeys, getKeycloakUsers, deleteApiKey, changeApiKey } from 'src/slices/thunks';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { isEmpty, set } from 'lodash';
import { IdCell } from 'src/components/elements/ShowTableId';
import { hideMiddleUUID } from 'src/helpers/formaters/handleMiddleUuid';
import Breadcrumb from 'src/components/Common/Breadcrumb';

import './index.scss';
import MultipleSelect from 'src/components/elements/MultipleSelect';
import { getTenants } from 'src/utils/services/service/tenantService';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TableView from 'src/components/Common/TableView/TableView';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';

const ApiKeys = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApiKeys());
    dispatch(getKeycloakUsers());
  }, [dispatch]);

  const { userData } = useSelector((state: any) => state.login);

  const extractRealmFromIssuer = (iss: string) => {
    const parts = iss?.split('/');
    const realmIndex = parts?.indexOf('realms');
    if (realmIndex !== -1 && realmIndex + 1 < parts?.length) {
      return parts[realmIndex + 1];
    }
    return '';
  };

  const realm = extractRealmFromIssuer(userData?.iss);

  const { apiKeys, loading, keycloakUsers } = useSelector((state: RootState) => state.keys);

  const [selectedApiKey, setSelectedApiKey] = useState<string>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [userIdForChange, setUserIdForChange] = useState<string>('');

  const handleShow = (): void => setModal(false);
  const handleShowDeleteModal = (): void => setDeleteModal(false);
  const closeChangeModal = (): void => setShowChangeModal(false);
  const [tenantsOption, setTenantsOption] = useState<any>([]);
  const [tenantsList, setTenantsList] = useState<any>([]);
  const [loadingTenants, setLoadingTenants] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiKeys?.length,
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: 'e',
      order: 'asc',
    },
  ];

  const handleEdit = (data: any): void => {
    setUserIdForChange(data.userId);
    setShowChangeModal(true);
  };

  const handleDelete = (data: any): void => {
    if (data?.id) {
      setSelectedApiKey(data.id);
    }
  };

  const onDelete = async (): Promise<void> => {
    dispatch(deleteApiKey(selectedApiKey));
    setDeleteModal(false);
  };

  const columns = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 100,

      render: (_: any, record: any) => {
        return (
          <div className="tableIconBlock">
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="No"
                okText="Yes"
                okButtonId={`confirmAndDeleteNotificationContact`}
                triggerButtonId="deleteNotificationContact"
                onConfirm={() => {
                  handleDelete(record);
                }}
              />
            </div>
          </div>
        );
      },
    },
 
    {
      title: 'Tenant',
      dataIndex: 'tenant',
      filters: Array.from(new Set(apiKeys?.map((e: any) => e.tenant)))?.map((tenant: any) => ({
        text: tenant,
        value: tenant,
      })),
      onFilter: (value: any, record: any) => record.tenant.indexOf(value as string) === 0,
      width: 150,
    },

    {
      title: 'User Id',
      width: 250,
      dataIndex: 'userId',
    },
    {
      title: 'Api Key',
      dataIndex: 'apiKey',
      width: 250,
    },
  ];

  const toggle = (): void => {
    setModal(!modal);
    if (!modal && !isEmpty(apiKeys) && !!isEdit) {
      setTimeout(() => {
        setIsEdit(false);
      }, 500);
    }
  };

  const toggleEditModal = (): void => {
    setShowChangeModal(!showChangeModal);
    if (!modal && !isEmpty(apiKeys) && !!isEdit) {
      setTimeout(() => {
        setIsEdit(false);
      }, 500);
    }
  };

  const toggleDeleteModal = (): void => {
    setDeleteModal(!deleteModal);
    if (!modal && !isEmpty(apiKeys) && !!isEdit) {
      setTimeout(() => {
        setIsEdit(false);
      }, 500);
    }
  };

  const handleValidKeySubmit = async (values: any): Promise<void> => {
    // const tenant = realm === 'master' ? tenantsOption : realm;
    // const tenantString = Array.isArray(tenant) ? tenant.join(',') : tenant;

    if (!isEdit) {
      dispatch(createNewKey({ data: values, tenant: realm }));
      setModal(false);
      setTenantsOption([]);
    }
    toggle();
  };

  const handleKeyClicks = async (): Promise<any> => {
    setIsEdit(false);
    toggle();
    await getTenantsList();
  };

  const handleValidEditKeySubmit = async (values: any): Promise<void> => {
    if (!isEdit) {
      dispatch(changeApiKey(values));
      setShowChangeModal(false);
    }
    toggleEditModal();
  };

  const getTenantsList = async () => {
    setLoadingTenants(true);
    const response = await getTenants();
    setTenantsList(
      response?.data?.tenants?.map((item: any) => ({
        name: item.name,
        id: item.id,
      })),
    );
    setLoadingTenants(false);
  };

  console.log(apiKeys);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Api Keys</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Api-Keys" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div>
                        <SearchBar value={searchValue} onChange={handleSearchChange} placeholder="Search api key" />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Link to="#" className="btn btn-light" onClick={handleKeyClicks}>
                            <i className="uil uil-plus me-1"></i> Add
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableView loading={loading} columns={columns} dataSource={apiKeys} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <div className="table-responsive">
                <Modal centered isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} tag="h4">
                    Create Api Key
                  </ModalHeader>
                  <ModalBody>
                    <AvForm
                      onValidSubmit={(_e: any, values: any) => {
                        handleValidKeySubmit(values);
                      }}
                    >
                      <Row form>
                        <Col xs={12}>
                          <div className="mb-3">
                            <AvField
                              type="select"
                              name="userId"
                              label="Users"
                              placeholder="Enter user Id"
                              value={keycloakUsers?.users?.[0]?.id || ''}
                            >
                              {keycloakUsers &&
                                keycloakUsers?.users?.map((key: any) => {
                                  return (
                                    <option value={key.id} key={key.id}>
                                      {key.userName || '-'}
                                    </option>
                                  );
                                })}
                            </AvField>

                            {/* <div className="mb-3">
                                                  {realm === 'master' ? (
                                                    <>
                                                      <label>Tenants</label>
                                                      <MultipleSelect
                                                        onChange={setTenantsOption}
                                                        label="Tenants"
                                                        name="tenants"
                                                        placeholder={'Select Tenants'}
                                                        options={tenantsList as any}
                                                        isLoading={loadingTenants}
                                                        required={true}
                                                        // isEmpty={optionsError.tenantsError}
                                                      />
                                                    </>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div> */}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button type="button" className="btn btn-light w-sm mx-2" onClick={handleShow}>
                              Close
                            </button>
                            <button type="submit" className="btn btn-success w-sm save-user">
                              Create
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </ModalBody>
                </Modal>
                <Modal centered isOpen={showChangeModal} toggle={toggleEditModal}>
                  <ModalHeader toggle={toggleEditModal} tag="h4">
                    Edit Key
                  </ModalHeader>
                  <ModalBody>
                    <AvForm
                      onValidSubmit={(_e: any, values: any) => {
                        handleValidEditKeySubmit(values);
                      }}
                    >
                      <Row form>
                        <Col xs={12}>
                          <div className="mb-3">
                            <div className="pb-2">
                              <AvField type="select" name="userId" label="Users" placeholder="Enter user Id">
                                {keycloakUsers &&
                                  keycloakUsers?.users?.map((key: any) => {
                                    return (
                                      <option value={key.id} key={key.id}>
                                        {key.userName || '-'}
                                      </option>
                                    );
                                  })}
                              </AvField>
                            </div>
                            <AvField
                              name="apiKey"
                              label="Api Key"
                              type="apiKey"
                              placeholder="Enter Api Key"
                              errorMessage="Enter Api Key"
                              onChange={(e: ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value)}
                              validate={{
                                required: { value: true },
                              }}
                              value={userIdForChange || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button type="button" className="btn btn-light w-sm mx-2" onClick={closeChangeModal}>
                              Close
                            </button>
                            <button type="submit" className="btn btn-success w-sm save-user">
                              Update
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </ModalBody>
                </Modal>

                <Modal centered isOpen={deleteModal} toggle={toggleDeleteModal}>
                  <ModalBody>
                    <Row form>
                      <Col xs={12}>
                        <div className="mb-3 text-center fs-5">Are you sure you want to delete?</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="w-100 d-flex justify-content-center">
                          <button type="button" className="btn btn-light w-50  mx-2" onClick={handleShowDeleteModal}>
                            Close
                          </button>
                          <button type="submit" className="btn btn-danger w-50 save-user" onClick={() => onDelete()}>
                            Delete
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(ApiKeys);
