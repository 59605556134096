import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createGeographicLocations,
  deleteGeographicLocations,
  getAllCities,
  getAllContinents,
  getAllCountries,
  getAllPois,
  getAllRegions,
  getGeographicLocations,
  updateGeographicLocations,
} from 'src/slices/GeographicalLocations/thunk';
import {
  ICities,
  IContinents,
  ICountries,
  IGeographicalLocations,
  IPois,
  IRegions,
} from 'src/utils/types/geographicalLocations';

interface LocationsStateType {
  locations: Array<IGeographicalLocations>;
  error?: string | object | null | undefined | unknown;
  loading: boolean;
  cities: ICities[];
  continents: IContinents[];
  loadingFields: boolean;
  countries: ICountries[];
  regions: IRegions[];
  pois: IPois[];
}
export const initialState: LocationsStateType = {
  locations: [],
  error: '',
  loading: false,
  loadingFields: false,
  cities: [],
  continents: [],
  countries: [],
  regions: [],
  pois: [],
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGeographicLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getGeographicLocations.fulfilled, (state, action) => {
        state.locations = [];
        state.locations.push(action.payload);
        state.loading = false;
      })

      .addCase(createGeographicLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGeographicLocations.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateGeographicLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateGeographicLocations.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(deleteGeographicLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGeographicLocations.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(getAllCities.pending, (state) => {
        state.loadingFields = true;
        state.error = null;
      })

      .addCase(getAllCities.fulfilled, (state, action) => {
        state.cities = action.payload!.cities;
        state.loadingFields = false;
      })

      .addCase(getAllContinents.pending, (state) => {
        state.loadingFields = true;
        state.error = null;
      })

      .addCase(getAllContinents.fulfilled, (state, action) => {
        state.continents = action.payload!.continents;
        state.loadingFields = false;
      })

      .addCase(getAllCountries.pending, (state) => {
        state.loadingFields = true;
        state.error = null;
      })

      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.countries = action.payload!.countries;
        state.loadingFields = false;
      })

      .addCase(getAllRegions.pending, (state) => {
        state.loadingFields = true;
        state.error = null;
      })

      .addCase(getAllRegions.fulfilled, (state, action) => {
        state.regions = action.payload!.regions;
        state.loadingFields = false;
      })

      .addCase(getAllPois.pending, (state) => {
        state.loadingFields = true;
        state.error = null;
      })

      .addCase(getAllPois.fulfilled, (state, action) => {
        state.pois = action.payload!.pois;
        state.loadingFields = false;
      })

      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default locationsSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
