import { createAsyncThunk } from '@reduxjs/toolkit';
import { ManageHotelDataService } from 'src/utils/services/service/manageHotel';

import { IManageHotel } from 'src/utils/types/manageHotelTypes';

export const CreateManageHotelData = createAsyncThunk(
  'manageHotel/createManageHotelData',
  async ({ data }: { data: IManageHotel }) => {
    try {
      const response = await ManageHotelDataService.createManageHotelData(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetAllManageHotelData = createAsyncThunk(
  'manageHotel/getAllManageHotelData',
  async () => {
    try {
      const response = await ManageHotelDataService.getAllManageHotelData();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetSingleManageHotelData = createAsyncThunk(
  'manageHotel/getSingleManageHotelData',
  async ({ hoteldataId }: { hoteldataId: string }) => {
    try {
      const response = await ManageHotelDataService.getSingleManageHotelData(hoteldataId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateManageHotelData = createAsyncThunk(
  'manageHotel/updateManageHotelData',
  async ({ hoteldataId, data }: { hoteldataId: string; data: IManageHotel }) => {
    try {
      const response = await ManageHotelDataService.updateManageHotelData(hoteldataId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

// export const DeleteManageHotelData = createAsyncThunk(
//   'manageHotel/deleteManageHotelData',
//   async ({ hoteldataId }: { hoteldataId: string }) => {
//     try {
//       const response = await ManageHotelDataService.deleteManageHotelData(hoteldataId);
//       return response;
//     } catch (error) {
//       return error;
//     }
//   },
// );
