import MetaTags from 'react-meta-tags';
import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Nav, NavItem, Row, Spinner } from 'reactstrap';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import DeleteModal from 'src/components/Modals/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { getDecodedToken } from 'src/utils/jwtDecode';
import { IWeatherConfigsGetResponse } from 'src/utils/types/weatherConfigurations';
import {
  createWeatherConfigs,
  deleteWeatherConfigs,
  getWeatherConfig,
  getWeathersConfigs,
  updateWeatherConfigs,
} from 'src/slices/WeatherProvider/thunk';
import LoaderOverlay from 'src/components/elements/LoaderOverlay';
import './index.scss';

const testProviders = [
  { providerId: '1', userName: 'Provider 1' },
  { providerId: '2', userName: 'Provider 2' },
  { providerId: '3', userName: 'Provider 3' },
];

const WeatherProviders = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [decodedToken, setDecodedToken] = useState<any | null>(null);
  const [changeData, setChangeData] = useState<any | null>(null);
  const [deleteData, setDeleteData] = useState<any | null>(null);

  const [configurationData, setConfigurationData] = useState<any>([{ id: Math.random() }]);
  const [selectedConfig, setSelectedConfig] = useState<any | null>(null);

  const dispatch = useDispatch();

  const { configs, loading, configIsLoaded } = useSelector((state: RootState) => state.weatherConfigurations);

  useEffect(() => {
    const tokenData = getDecodedToken();
    setDecodedToken(tokenData);
    dispatch(getWeathersConfigs());
  }, []);

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: configs?.length,
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: 'e',
      order: 'asc',
    },
  ];

  const transformConfigurationData = (values: any) => {
    const resultArray = [];

    for (let i = 0; Object.prototype.hasOwnProperty.call(values, `dataKey${i}`); i++) {
      const slug = values[`dataKey${i}`];
      const value = values[`dataValue${i}`];

      resultArray.push({
        slug,
        value,
      });
    }

    return resultArray;
  };

  const addConfigField = () => {
    setConfigurationData((prev: any) => [
      ...prev,
      {
        id: Math.random(),
      },
    ]);
  };

  const removeConfigurationField = (fieldId: number) => {
    const fields = configurationData.filter((item: number) => item!.id !== fieldId);
    setConfigurationData(fields);
  };

  const contactListColumns = [
    {
      text: 'Actions',
      dataField: 'edit',
      sort: true,
      editable: false,
      style: {
        width: '8em',
      },
      formatter: (_cellContent: string, data: IWeatherConfigsGetResponse) => {
        return (
          <div className="tableIconBlock">
            <div className="tableIcon" onClick={() => toggleEditModal(data)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </div>
            <div className="tableIcon" onClick={() => handleDelete(data)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </div>
          </div>
        );
      },
    },
    {
      text: 'Name',
      dataField: 'name',
      sort: true,
      editable: false,
    },
    {
      text: 'Provider',
      dataField: 'provider',
      sort: true,
      editable: false,
    },
    {
      text: 'Tenant',
      dataField: 'tenant',
      sort: true,
      editable: false,
    },
    {
      text: 'Enabled',
      dataField: 'enabled',
      formatter: (cellContent: any) => (
        <div>
          {cellContent ? (
            <span style={{ color: 'green' }}>Enabled</span>
          ) : (
            <span style={{ color: 'red' }}>Disabled</span>
          )}
        </div>
      ),
    },
  ];

  const closeCreateModal = () => setShowCreateModal(false);

  const closeEditModal = () => {
    setShowChangeModal(false);
  };

  const crateModal = () => {
    setConfigurationData([{ id: Math.random() }]);
    setShowCreateModal(!showCreateModal);
  };

  const handleSubmitCreateModal = (values: any) => {
    const configArray = transformConfigurationData(values);

    const iss = decodedToken?.iss;
    const parts = iss.split('/');
    const tenantId = parts[parts.length - 1];

    const createData = {
      provider: values.providerId,
      tenant: tenantId,
      name: values.name,
      enabled: values.enabled,
      configurations: configArray,
    };

    dispatch(createWeatherConfigs(createData));
    closeCreateModal();
  };

  const getConf = async (id: any) => {
    const { payload }: any = await dispatch(getWeatherConfig(id));
    return payload ? payload : null;
  };

  const toggleEditModal = async (data: any) => {
    try {
      if (data?.id) {
        const configFields = await getConf(data.id);

        if (configFields?.configuration) {
          const resultArray = configFields?.configuration.map((item: any) => {
            return { slug: item.slug, value: item.value, id: Math.random() * Date.now() };
          });
          setConfigurationData(resultArray);
        }
        setSelectedConfig(data);
      }

      setShowChangeModal(!showChangeModal);
    } catch (error) {
      console.error('Error fetching weather config:', error);
    }
  };

  const handleSubmitEditeModal = (data: any) => {
    const configArray = transformConfigurationData(data);

    const editData = {
      id: selectedConfig.id,
      enabled: data.enabled,
      configurations: configArray,
    };

    dispatch(updateWeatherConfigs(editData));
    closeEditModal();
  };

  const handleDelete = (data: any): void => {
    setShowDeleteModal(!showDeleteModal);
    if (data?.id) {
      setDeleteData(data);
    }
  };

  const confirmDelete = () => {
    const sendData = { id: deleteData.id };
    dispatch(deleteWeatherConfigs(sendData));
    setShowDeleteModal(false);
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Weather Providers</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Weather Providers" />
          <Row>
            <Col lg="12">
              <Card>
                { !loading ? (
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={configs || []}
                          columns={contactListColumns}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">
                                      Weather Providers{' '}
                                      <span className="text-muted fw-normal ms-2">({configs?.length})</span>
                                    </h5>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                                    <div>
                                      <Nav pills>
                                        <NavItem>
                                          <Link className="nav-link active" to="user-list">
                                            <i className="uil uil-list-ul"></i>
                                          </Link>
                                        </NavItem>
                                        <NavItem>
                                          <Link className="nav-link" to="user-grid">
                                            <i className="uil uil-apps"></i>
                                          </Link>
                                        </NavItem>
                                      </Nav>
                                    </div>
                                    <div>
                                      <Link to="#" className="btn btn-light" onClick={crateModal}>
                                        <i className="uil uil-plus me-1"></i> Add Configuration
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={'table align-middle table-nowrap table-hover'}
                                      bordered={false}
                                      striped={false}
                                      hover={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone {...paginationProps} />
                                </Col>
                              </Row>
                            </>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                ) : (
                  <div className="spinnerBlock">
                    <Spinner />
                  </div>
                )}

                {/*create modal*/}

                <Modal isOpen={showCreateModal} toggle={crateModal}>
                  <ModalHeader toggle={crateModal} tag="h4">
                    Add Configuration
                  </ModalHeader>
                  <ModalBody className="scrollModal">
                    <AvForm
                      onValidSubmit={(_e: any, values: any) => {
                        handleSubmitCreateModal(values);
                      }}
                    >
                      <Row form>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Row form>
                              <Col xs={12}>
                                <div className="mb-1">
                                  <AvField
                                    type="select"
                                    name="providerId"
                                    label="Provider username"
                                    placeholder="Enter provider Id"
                                    value={testProviders[0]?.providerId}
                                  >
                                    {testProviders &&
                                      testProviders?.map((provider: any) => {
                                        return (
                                          <option value={provider?.configurationId} key={provider?.providerId}>
                                            {provider?.userName || '-'}
                                          </option>
                                        );
                                      })}
                                  </AvField>
                                </div>
                              </Col>

                              <div className="py-2">
                                <AvField
                                  name="name"
                                  label="Name"
                                  type="text"
                                  placeholder="Enter configuration name"
                                  errorMessage="Enter configuration name"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={''}
                                />
                              </div>
                              <div className="py-2">
                                <AvField
                                  name="enabled"
                                  label="Enable"
                                  type="checkbox"
                                  placeholder=""
                                  errorMessage=""
                                  validate={{
                                    required: { value: false },
                                  }}
                                  value={true}
                                />
                              </div>
                              <Col xs={12}>
                                {configurationData.map((field: any, index: number) => (
                                  <div key={field.id} className="updateModalData">
                                    <span>
                                      <AvField
                                        name={`dataKey${index}`}
                                        label="Slug"
                                        type="input"
                                        placeholder="Enter slug"
                                        errorMessage="Enter slug"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={field?.slug || ''}
                                      />
                                    </span>
                                    <span>
                                      <AvField
                                        name={`dataValue${index}`}
                                        label="Value"
                                        type="input"
                                        placeholder="Enter value"
                                        errorMessage="Enter value"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={field?.value || ''}
                                      />
                                    </span>
                                    <span className="deleteBtn">
                                      {index !== 0 && (
                                        <span
                                          className="deleteBtnItem"
                                          onClick={() => removeConfigurationField(field.id)}
                                        >
                                          X
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                ))}
                                <div className="btn btn-light w-sm">
                                  <span onClick={addConfigField}>Add more</span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button type="button" className="btn btn-light w-sm mx-2" onClick={closeCreateModal}>
                              Close
                            </button>
                            <button type="submit" className="btn btn-success w-sm save-user">
                              Create
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </ModalBody>
                </Modal>

                {/*create modal end*/}

                {/*  edit modal  */}

                {configIsLoaded ? (
                  <LoaderOverlay loading={configIsLoaded} />
                ) : (
                  <Modal isOpen={showChangeModal} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal} tag="h4">
                      Edit Configuration
                    </ModalHeader>
                    <ModalBody className="scrollModal">
                      <AvForm
                        onValidSubmit={(_e: any, values: any) => {
                          handleSubmitEditeModal(values);
                        }}
                      >
                        <Row form>
                          <Col xs={12}>
                            <div className="py-2">
                              <AvField
                                name="enabled"
                                label="Enable"
                                type="checkbox"
                                placeholder=""
                                errorMessage=""
                                validate={{
                                  required: { value: false },
                                }}
                                value={selectedConfig?.enabled}
                              />
                            </div>
                            <div className="mb-3">
                              {configurationData?.map((field: any, index: number) => (
                                <div key={field.id} className="updateModalData">
                                  <span>
                                    <AvField
                                      name={`dataKey${index}`}
                                      label="Slug"
                                      type="input"
                                      placeholder="Enter slug"
                                      errorMessage="Enter slug"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      value={field?.slug || ''}
                                    />
                                  </span>
                                  <span>
                                    <AvField
                                      name={`dataValue${index}`}
                                      label="Value"
                                      type="input"
                                      placeholder="Enter value"
                                      errorMessage="Enter value"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      value={field?.value || ''}
                                    />
                                  </span>
                                  <span className="deleteBtn">
                                    {index !== 0 && (
                                      <span
                                        className="deleteBtnItem"
                                        onClick={() => removeConfigurationField(field.id)}
                                      >
                                        X
                                      </span>
                                    )}
                                  </span>
                                </div>
                              ))}
                              <div className="btn btn-light w-sm">
                                <span onClick={addConfigField}>Add more</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button type="button" className="btn btn-light w-sm mx-2" onClick={closeEditModal}>
                                Close
                              </button>
                              <button type="submit" className="btn btn-success w-sm save-user">
                                Update
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                )}

                {/*  edit modal end */}

                {/*  delete modal  */}
                <DeleteModal
                  isOpen={showDeleteModal}
                  toggleDeleteModal={handleDelete}
                  handleShowDeleteModal={() => setShowDeleteModal(false)}
                  onDelete={confirmDelete}
                  title="Delete Configuration"
                />

                {/*  delete modal end */}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(WeatherProviders);
