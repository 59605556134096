import { ICreateUser, IEditUser } from 'src/utils/types/userTypes';
import api from '../axiosConfig';
import env from '../../../env';
import tenants from './mandantnames.json';

const userApi = env.VITE_REACT_APP_DEFAULTAUTH() || '';
const baseURL = env.VITE_REACT_APP_DEFAULTAUTH();
export const getAllProperties = async () => {
  const data = {
    data: {
      properties: tenants.map((tenant) => ({
        propertyId: tenant.MandantId,
        propertyName: tenant.Name,
      })),
    },
  };
  return Promise.resolve(data);
  // const accessToken = localStorage.getItem("accessToken");
  // const headers = {
  //     Authorization: `Bearer ${accessToken}`,
  // };

  // const res = await api.get(`/data/links/tenants-to-properties/properties`, { headers, baseURL: userApi });
  // console.log("res")
  // console.log(res)
  // return res
};

export const getProperties = async (token: string) => {
  const accessToken = token;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await api.get(`/data/properties`, { headers, baseURL: baseURL });
  return res;
};

export const updateProperties = async (token: string, id: string, data: any) => {
  try {
    const accessToken = token;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const res = await api.patch(`/data/properties/${id}`, data, { headers, baseURL: baseURL });
    return res;
  } catch (error) {
    console.error('Error in Update properties', error);
    throw error;
  }
};
