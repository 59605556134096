// Assuming the mock data is stored in the same directory or a relative path
import mockData from './dataanom.json'; // Adjust the path as necessary

export const getAnomalyDetect = async (data: any) => {
    // Simulating a delay to mimic an async operation, e.g., API call
    const simulateDelay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    // Optionally, you can filter or manipulate the mock data based on the input 'data' if needed
    // For example, filtering the mock data to match some criteria in 'data'
    // This step depends on how you intend to use the 'data' parameter and the structure of your mock data

    await simulateDelay(500); // Simulating a 500ms network delay

    // Simulating a successful response structure similar to what your actual API might return
    const res = {
        data: mockData, // or filtered/manipulated data based on 'data' parameter
        status: 200,
        statusText: 'OK',
        headers: {}, // Add any relevant headers your actual response might include
        config: {}, // Add any relevant config your actual response might include
    };

    return res;
}