import { createSlice } from '@reduxjs/toolkit';
import {
  CreateMealPlansData,
  DeleteMealPlansData,
  GetAllMealPlansData,
  GetSingleMealPlansData,
  UpdateMealPlansData,
} from './thunk';
import { IMealPlans } from 'src/utils/types/mealPlansTypes';

interface IMealPlansState {
  mealPlans: IMealPlans[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IMealPlansState = {
  mealPlans: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const mealPlansSlice = createSlice({
  name: 'mealPlans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllMealPlansData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllMealPlansData.fulfilled, (state, action) => {
        state.mealPlans = action.payload as [];
        state.isLoading = false;
      })
      .addCase(GetAllMealPlansData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleMealPlansData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleMealPlansData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleMealPlansData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateMealPlansData.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateMealPlansData.fulfilled, (state, action) => {
        state.mealPlans.push(action.payload as IMealPlans);
        state.isCreating = false;
      })
      .addCase(CreateMealPlansData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateMealPlansData.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateMealPlansData.fulfilled, (state, action) => {
        const updatedContact = action.payload as IMealPlans;
        const index = state.mealPlans.findIndex((contact) => contact.id === updatedContact.id);
        state.mealPlans[index] = updatedContact;
        state.isUpdating = false;
      })
      .addCase(UpdateMealPlansData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      })
      .addCase(DeleteMealPlansData.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteMealPlansData.fulfilled, (state, action) => {
        const deletedContactId = action.payload as string;
        state.mealPlans = state.mealPlans.filter((contact) => contact.id !== deletedContactId);
        state.isDeleting = false;
      })
      .addCase(DeleteMealPlansData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isDeleting = false;
      });
  },
});

export default mealPlansSlice.reducer;
