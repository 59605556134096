import api from "../axiosConfig";
import { SignInProps } from "src/utils/types/authTypes";

export const postLogin = async (data: SignInProps) => {

  const res = await api.post("login", {
    UserName: data.username,
    Password: data.password,
    TenantId: data.tenant,
  });

  return res.data;
};
