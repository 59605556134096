import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createExternalEvents,
  deleteExternalEvents,
  getExternalEvents,
  updateExternalEvents,
  getExternalEvent,
  getEventCategories
} from 'src/slices/ExternalEvents/thunk';

interface EventsStateType {
  events: Array<any>;
  event: any;
  error?: string | object | null | undefined | unknown;
  loading: boolean;
  eventsLoading: boolean
  categories: any,
}
export const initialState: EventsStateType = {
  events: [],
  event: null,
  error: '',
  loading: false,
  categories: [],
  eventsLoading: false
};

const externalEventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExternalEvents.pending, (state) => {
        state.eventsLoading = true;
        state.error = null;
      })

      .addCase(getExternalEvents.fulfilled, (state, action) => {
        state.events = action.payload.events;
        state.eventsLoading = false;
      })

      .addCase(getExternalEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getExternalEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.loading = false;
      })

      .addCase(createExternalEvents.pending, (state) => {
        state.eventsLoading = true;
        state.error = null;
      })
      .addCase(createExternalEvents.fulfilled, (state) => {
        state.eventsLoading = false;
      })

      .addCase(updateExternalEvents.pending, (state) => {
        state.eventsLoading = true;
        state.error = null;
      })
      .addCase(updateExternalEvents.fulfilled, (state) => {
        state.eventsLoading = false;
      })

      .addCase(deleteExternalEvents.pending, (state) => {
        state.eventsLoading = true;
        state.error = null;
      })
      .addCase(deleteExternalEvents.fulfilled, (state) => {
        state.eventsLoading = false;
      })

      .addCase(getEventCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEventCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories
        state.loading = false;
      })

      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default externalEventsSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
