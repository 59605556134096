import React, { useEffect, useState } from 'react';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import MetaTags from 'react-meta-tags';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Form, Input, Tabs, Transfer, TransferProps, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { TabsProps } from 'antd/lib';
import { useDispatch, useSelector } from 'react-redux';
import { create_Group, delete_Group, getMyGroups, getProperties_For_Tenants, getUsers } from 'src/slices/thunks';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import DeleteIcon from 'src/components/elements/Icons/DeleteIcon';
import EditIcon from 'src/components/elements/Icons/EditIcon';
import GroupUpdateModal from './GroupUpdateModal';
import TableView from 'src/components/Common/TableView/TableView';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { FiEdit } from 'react-icons/fi';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';

const Groups = () => {
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updatingData, setUpdatingData] = useState<any | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [DeletingData, setDeletingData] = useState(null);

  const toggle = () => {
    setDeletingData(null);
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const [form] = useForm();
  const data = useSelector((state: any) => ({
    users: state.users.users?.length ? state.users.users : [],
    totalPages: state.users.totalPages ? state.users.totalPages : 0,
    loading: state.users.loading,
  }));

  const { ProPertiesForTenants, groups, isCreating, loading } = useSelector((state: any) => state.groups);
  console.log(groups);
  const dispatch = useDispatch();

  const crateModal = async () => {
    form.resetFields();
    setTargetKeysForProperties([]);
    setTargetKeys([]);
    setIsCreationModalOpen(!isCreationModalOpen);
  };

  const updateModal = async () => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
  };

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const [targetKeys, setTargetKeys] = useState<TransferProps['targetKeys']>([]);
  const [selectedKeys, setSelectedKeys] = useState<TransferProps['targetKeys']>([]);

  const [targetKeysForProperties, setTargetKeysForProperties] = useState<TransferProps['targetKeys']>([]);
  const [selectedKeysForProperties, setSelectedKeysForProperties] = useState<TransferProps['targetKeys']>([]);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const onChangeV1: TransferProps['onChange'] = (nextTargetKeys) => {
    setTargetKeysForProperties(nextTargetKeys);
  };

  const onSelectChangeV1: TransferProps['onSelectChange'] = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeysForProperties([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  useEffect(() => {
    dispatch(getMyGroups());
    dispatch(getUsers());
    dispatch(getProperties_For_Tenants());
  }, [dispatch]);

  const onChange: TransferProps['onChange'] = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange: TransferProps['onSelectChange'] = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Users',
      children: (
        <>
          <Transfer
            style={{ width: '100%' }}
            dataSource={data?.users.map((user: any) => ({ key: user.id, title: user.userName }))}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onChange}
            onSelectChange={onSelectChange}
            render={(item) => item.title!}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Properties',
      children: (
        <Transfer
          style={{ width: '100%' }}
          dataSource={
            ProPertiesForTenants?.length &&
            ProPertiesForTenants?.map((property: any) => ({ key: property.id, title: property.place }))
          }
          targetKeys={targetKeysForProperties}
          selectedKeys={selectedKeysForProperties}
          onChange={onChangeV1}
          onSelectChange={onSelectChangeV1}
          render={(item) => item.title!}
        />
      ),
    },
  ];

  const handleCreate = async () => {
    form.validateFields();
    const name = form.getFieldValue('name');

    const dataToCreate = {
      name,
      users: targetKeys,
      properties: targetKeysForProperties,
    };
    // console.log(dataToCreate);
    const response: any = await dispatch(create_Group({ data: dataToCreate }));
    if (response?.payload?.status === 201) {
      openNotificationWithIcon('success', 'Group created successfully');
      crateModal();
      dispatch(getMyGroups());
    } else {
      openNotificationWithIcon('error', 'Group creation failed');
    }
  };

  const handleDelete = async (data: any) => {
    const response: any = await dispatch(delete_Group({ id: data?.id }));
    if (response?.payload?.status === 204) {
      openNotificationWithIcon('success', 'Group deleted successfully');
      dispatch(getMyGroups());
    } else {
      openNotificationWithIcon('error', 'failed to delete group');
    }
  };

  const columns = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 100,
      render: (_cellContent: string, data: any) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setUpdatingData(data);
                setIsUpdateModalOpen(true);
              }}
            >
              <FiEdit size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="No"
                okText="Yes"
                okButtonId={`confirmAndDeleteNotificationContact`}
                triggerButtonId="deleteNotificationContact"
                onConfirm={() => {
                  handleDelete(data);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 250,
    },
    {
      title: 'Total Users in Group',
      dataIndex: 'users',
      width: 250,
      render: (_cellContent: string, data: any) => {
        return <div className="tableIconBlock">{data?.users?.length}</div>;
      },
    },
    {
      title: 'Total Properties in Group',
      dataIndex: 'properties',
      sort: true,
      editable: false,
      width: 250,
      render: (_cellContent: string, data: any) => {
        return <div className="tableIconBlock">{data?.properties?.length}</div>;
      },
    },
  ];

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: groups?.length,
    custom: true,
  };
  const defaultSorted: any = [
    {
      dataField: 'e',
      order: 'asc',
    },
  ];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <div className="page-content">
        {contextHolder}
        <MetaTags>
          <title>Groups</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="System Settings" breadcrumbItem="Groups" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div>
                        <SearchBar
                          value={searchValue}
                          onChange={handleSearchChange}
                          placeholder="Search group by name."
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Link to="#" className="btn btn-light" onClick={crateModal}>
                            <i className="uil uil-plus me-1"></i> Add
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableView
                      loading={loading}
                      columns={columns}
                      dataSource={groups?.filter((e: any) => {
                        if (searchValue === '') {
                          return e;
                        } else if (e.name.toLowerCase().includes(searchValue.toLowerCase())) {
                          return e;
                        }
                      })}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Creation Modal start */}
          <Modal isOpen={isCreationModalOpen} toggle={crateModal}>
            <ModalHeader toggle={crateModal} tag="h4">
              Create Group
            </ModalHeader>
            <ModalBody className="scrollModal">
              <Form layout="vertical" form={form}>
                <Form.Item
                  required={true}
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the group name',
                    },
                  ]}
                >
                  <Input placeholder="Enter Group name" />
                </Form.Item>
                <Tabs defaultActiveKey="1" items={items} />
              </Form>

              <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
                <Button disabled={isCreating} onClick={() => handleCreate()} style={{ marginTop: 20 }}>
                  {isCreating ? 'Creating . . . ' : 'Create'}
                </Button>
              </Col>
            </ModalBody>
          </Modal>

          <GroupUpdateModal isOpen={isUpdateModalOpen} toggle={updateModal} updatingData={updatingData} />
          {/* Creation modal end  */}

          <Modal isOpen={isConfirmModalOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Are you sure you want to Delete
            </ModalHeader>

            <ModalBody className="scrollModal">
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'end', gap: '10px' }}>
                <Button onClick={toggle}>Cancel</Button>
                <Button onClick={() => handleDelete(DeletingData)}>Yes</Button>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default Groups;
