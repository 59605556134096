import { Card, Form, Input, Select, Table } from 'antd';
import { useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from 'reactstrap';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

interface SearchModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const SearchModal = ({ isOpen, toggle }: SearchModalProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchForm] = Form.useForm();
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const dataSource = [
    {
      id: 'hotel001',
      name: 'The Ritz-Carlton, Berlin',
      address: 'Potsdamer Platz 3, 10785 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel002',
      name: 'Hotel Adlon Kempinski',
      address: 'Unter den Linden 77, 10117 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel003',
      name: 'Hotel Berlin, Berlin',
      address: 'Lützowplatz 17, 10785 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel004',
      name: 'InterContinental Berlin',
      address: 'Budapester Str. 2, 10787 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel005',
      name: 'NH Collection Berlin Mitte Friedrichstrasse',
      address: 'Friedrichstraße 96, 10117 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel006',
      name: 'Park Inn by Radisson Berlin Alexanderplatz',
      address: 'Alexanderplatz 7, 10178 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel007',
      name: 'Grand Hyatt Berlin',
      address: 'Marlene-Dietrich-Platz 2, 10785 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel008',
      name: 'Steigenberger Hotel Am Kanzleramt',
      address: 'Ella-Trebe-Straße 5, 10557 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel009',
      name: 'Radisson Blu Hotel, Berlin',
      address: 'Karl-Liebknecht-Str. 3, 10178 Berlin, Germany',
      city: 'Berlin',
    },
    {
      id: 'hotel010',
      name: 'Scandic Berlin Potsdamer Platz',
      address: 'Gabriele-Tergit-Promenade 19, 10963 Berlin, Germany',
      city: 'Berlin',
    },
  ];

  // Define columns
  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: () => (
        <Button
          id="addNotificationContact"
          className="btn btn-ghost"
          onClick={() => {
            //   setIsSearchModalOpen(!isSearchModalOpen);
          }}
        >
          Import
        </Button>
      ),
    },
    {
      title: 'Hotel Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
  ];
  return (
    <div>
      <Modal
        size="xl"
        // style={{
        //   width: '500px',
        // }}
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader toggle={() => toggle()} tag="h3">
          Search Hotels
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={searchForm}>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Col>
                <div>
                  <Form.Item required label="Platform" name="platform">
                    <Select
                      style={{
                        width: '100%',
                        height: '30px',
                      }}
                      placeholder="Platform"
                    >
                      <Select.Option value={'Booking'}>Booking</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col>
                <div>
                  <Form.Item required label="Hotel Name" name="hotelName">
                    <Input id="hotelName" placeholder="Hotel Name" className="p-1 px-2" />
                  </Form.Item>
                </div>
              </Col>

              <Col>
                <div>
                  <Form.Item required label="City" name="city">
                    <Input id="city" placeholder="City Name" className="p-1 px-2" />
                  </Form.Item>
                </div>
              </Col>
              <Col>
                <div>
                  <Form.Item required label="Address" name="address">
                    <Input id="city" placeholder="Hotel address" className="p-1 px-2" />
                  </Form.Item>
                </div>
              </Col>
              <Col>
                <Button
                  id="addNotificationContact"
                  className="btn btn-info"
                  onClick={() => {
                    //   setIsSearchModalOpen(!isSearchModalOpen);
                  }}
                >
                  <IoIosSearch />

                  <span
                    style={{
                      marginLeft: '5px',
                    }}
                  >
                    Search
                  </span>
                </Button>
              </Col>
            </Row>
          </Form>

          <Card title="Hotel Search Results">
            <Table
              scroll={{ y: 350 }}
              pagination={{
                defaultPageSize: 100,
                pageSizeOptions: [50, 100],
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
              dataSource={dataSource}
              columns={columns}
              rowKey="id" // This ensures each row has a unique key, which is 'id' in this case
            />
          </Card>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SearchModal;
