import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { IExternalApiKeys } from 'src/utils/types/externalApiKeysTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import {
  CreateExternalApiKey,
  GetAllExternalApiKeys,
  UpdateExternalApiKey,
  // ValidateApiKey,
} from 'src/slices/ExternalApiKeys/thunk';
import { AppDispatch } from 'src/store';

interface IExternalApiKeysModalProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IExternalApiKeys | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IExternalApiKeys | null) => void;
}

const ExternalApiKeysModal = ({
  isOpen,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: IExternalApiKeysModalProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.externalApiKeys);
  const { selectedProperty } = useSelector((state: RootState) => state.porperty);
  const dispatch: AppDispatch = useDispatch();

  const [isApiKeyChanged, setIsApiKeyChanged] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  // ** Track changes to API Key input field
  const onApiKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== initialValues?.apiKey) {
      setIsApiKeyChanged(true);
      setIsValidated(false);
    } else {
      setIsApiKeyChanged(false);
    }
  };

  // ** Function to handle validation of API key
  const handleValidateApiKey = async () => {
    const apiKey = form.getFieldValue('apiKey');
    setIsValidated(true);
  };

  // ** Function to handle create or update
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IExternalApiKeys = form.getFieldsValue();
    formData.propertyId = selectedProperty?.value;

    try {
      if (isEditMode) {
        const payload = { ...formData, id: initialValues?.id };
        const response = await dispatch(
          UpdateExternalApiKey({ data: payload, externalApiKeyId: initialValues?.id as string }),
        );

        if ((response.payload as IExternalApiKeys)['id']) {
          openNotificationWithIcon('success', 'External API key updated successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllExternalApiKeys());
        } else {
          openNotificationWithIcon('error', `Error updating external API key`);
        }
      } else {
        const response = await dispatch(CreateExternalApiKey({ data: formData }));

        if ((response.payload as IExternalApiKeys)['id']) {
          openNotificationWithIcon('success', 'External API key created successfully');
          handleCancel();
          form.resetFields();
          dispatch(GetAllExternalApiKeys());
        } else {
          openNotificationWithIcon('error', `Error creating external API key`);
        }
      }
    } catch (error) {
      openNotificationWithIcon('error', `Error ${isEditMode ? 'updating' : 'creating'} API key`);
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? 'Update' : 'Create'} External API key
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item required label="Name" name="name" rules={[{ required: true, message: 'Please enter name' }]}>
              <Input id="externalApiKeyName" placeholder="Name" className="p-2 px-2" />
            </Form.Item>

            <Form.Item
              required
              label="External System"
              name="externalSystem"
              rules={[{ required: true, message: 'Please select external system' }]}
            >
              <Select
                id="externalSystem"
                placeholder="Select external system"
                options={[
                  { label: 'Booking', value: 'booking' },
                  { label: 'Airbnb', value: 'airbnb', disabled: true },
                  { label: 'Expedia', value: 'expedia', disabled: true },
                ]}
              />
            </Form.Item>

            <Form.Item
              required
              label="API Key"
              name="apiKey"
              rules={[{ required: true, message: 'Please enter the API key' }]}
            >
              <Input
                id="apiSecret"
                placeholder="API Key"
                onChange={onApiKeyChange}
                // disabled={isEditMode && isValidated}
              />
            </Form.Item>
          </Form>

          <Col style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              id="externalApiKeyCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>

            {isApiKeyChanged && !isValidated ? (
              <AsyncButton
                buttonText="Validate"
                style={{ marginTop: 20 }}
                isLoading={false}
                onClick={handleValidateApiKey}
                id="validateApiKeyButton"
              />
            ) : (
              <AsyncButton
                buttonText={isEditMode ? 'Update' : 'Create'}
                style={{ marginTop: 20 }}
                isLoading={isCreating || isUpdating}
                onClick={handleCreateOrUpdate}
                id="externalApiKeyCreateOrUpdateButton"
                disabled={!isValidated}
              />
            )}
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ExternalApiKeysModal;
