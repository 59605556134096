// Import axios for making HTTP requests and API configurations.
import axios from "axios";
import api from "../axiosConfig";
import env from "../../../env";

// Retrieving API URLs from environment variables.
const dashboardUrl = env.VITE_DASHBOARD();

/**
 * Fetches OccupancyPrediction.
 * @returns A Promise resolving to the response containing OccupancyPrediction.
 */
export const processOccupancyPrediction = async (data: any) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const headers = {
            // Use the accessToken for Authorization header if available.
            // Authorization: accessToken ? `Bearer ${accessToken}` : null,
        };
        const formData = new FormData();
        formData.append('File', data?.file);
        formData.append('ModelName', data?.modelName);
        formData.append('StartDate', data?.startDate);
        formData.append('EndDate', data?.endDate);
        formData.append('IsFromForm', data?.isFromForm);

        const response = await axios.post(`${dashboardUrl}/ProcessMLModelFile/processOccupancyModelFile`, formData);
        return response;
    } catch (error) {
        console.error("Error fetching OccupancyPrediction:", error);
        // Handle or throw the error based on your error handling policy
        throw error;
    }
};

/**
 * Fetches CancellationPrediction.
 * @returns A Promise resolving to the response containing CancellationPrediction.
 */
export const processCancellationPrediction = async (modelName: string, file: File) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const headers = {
            // Use the accessToken for Authorization header if available.
            // Authorization: accessToken ? `Bearer ${accessToken}` : null,
        };
        const formData = new FormData();
        formData.append('File', file);
        formData.append('ModelName', "Cancellation");

        const response = await axios.post(`${dashboardUrl}/ProcessMLModelFile/upload`, formData);
        return response;
    } catch (error) {
        console.error("Error fetching CancellationPrediction:", error);
        // Handle or throw the error based on your error handling policy
        throw error;
    }
};

/**
 * Fetches TotalRatePrediction.
 * @returns A Promise resolving to the response containing TotalRatePrediction.
 */
export const processTotalRatePrediction = async (modelName: string, file: File) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const headers = {
            // Use the accessToken for Authorization header if available.
            // Authorization: accessToken ? `Bearer ${accessToken}` : null,
        };
        const formData = new FormData();
        formData.append('File', file);
        formData.append('ModelName', modelName);

        const response = await axios.post(`${dashboardUrl}/ProcessMLModelFile/processRateChangeModelFile`, formData);
        return response;
    } catch (error) {
        console.error("Error fetching TotalRatePrediction:", error);
        // Handle or throw the error based on your error handling policy
        throw error;
    }
};

/**
 * Fetches PricingPrediction.
 * @returns A Promise resolving to the response containing PricingPrediction.
 */
export const processPricingPrediction = async (data: any) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const headers = {
            // Use the accessToken for Authorization header if available.
            // Authorization: accessToken ? `Bearer ${accessToken}` : null,
        };

        const response = await axios.post(`${dashboardUrl}/ProcessMLModelFile/processPricingModel`, data);
        return response;
    } catch (error) {
        console.error("Error fetching PricingPrediction:", error);
        // Handle or throw the error based on your error handling policy
        throw error;
    }
};

/**
 * Fetches OccupancyAssistance.
 * @returns A Promise resolving to the response containing OccupancyAssistance.
 */
export const processOccupancyAssistance = async (data: any) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const headers = {
            // Use the accessToken for Authorization header if available.
            // Authorization: accessToken ? `Bearer ${accessToken}` : null,
        };

        const response = await axios.post(`${dashboardUrl}/ProcessMLModelFile/processOccupancyAssistanceModel`, data);
        return response;
    } catch (error) {
        console.error("Error fetching OccupancyAssistance:", error);
        // Handle or throw the error based on your error handling policy
        throw error;
    }
};