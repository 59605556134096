import api from 'src/utils/services/axiosConfig';
import env from 'src/env';

const eventsUrl = env.VITE_EXTERNAL_EVENT();

const getAccessToken = () => localStorage.getItem('accessToken');

const createHeaders = (token: string | null) => ({
  Authorization: token ? `Bearer ${token}` : null,
});

const handleError = (error: any) => {
  console.error(error);
};

export const getAllExternalEvents = async (propertyId: string) => {
  try {
    const res = await api.get(`/events/events?propertyId=${propertyId}`, {
      headers: createHeaders(getAccessToken()),
      baseURL: eventsUrl,
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const getAllExternalEvent = async (eventId: string) => {
  try {
    const res = await api.get(`/events/event?eventId=${eventId}`, {
      headers: createHeaders(getAccessToken()),
      baseURL: eventsUrl,
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const getAllCategories = async () => {
  try {
    const res = await api.get(`/events/eventCategories`, { headers: createHeaders(null), baseURL: eventsUrl });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const getExternalEvent = async () => {
  try {
    const accessToken = getAccessToken();
    const res = await api.get('/events/event', { headers: createHeaders(accessToken), baseURL: eventsUrl });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const createExternalEvent = async (data: any, tenant: string) => {
  try {
    const accessToken = getAccessToken();
    const res = await api.post('/events/event', data, {
      headers: createHeaders(accessToken),
      baseURL: eventsUrl,
      params: {
        tenant: tenant,
      },
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const updateExternalEvent = async (data: any, eventId: string) => {
  try {
    const accessToken = getAccessToken();
    const res = await api.put(`/events/event?eventId=${eventId}`, data, {
      headers: createHeaders(accessToken),
      baseURL: eventsUrl,
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteExternalEvent = async (eventId: string) => {
  try {
    const accessToken = getAccessToken();
    const res = await api.delete(`/events/event?eventId=${eventId}`, {
      headers: createHeaders(accessToken),
      baseURL: eventsUrl,
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};
