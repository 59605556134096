/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from 'react-router-dom';
import {
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Spinner,
} from 'reactstrap';

import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb

import Breadcrumb from 'src/components/Common/Breadcrumb';
// import {
//   getUsers as onGetUsers,
//   addNewUser as onAddNewUser,
// } from "../../../slices/thunks";

import { isEmpty, size, map, get, set } from 'lodash';
import { Button, Card, Flex, Form, Input, Popconfirm, Tabs, Transfer, TransferProps, notification } from 'antd';

//redux
import { useSelector, useDispatch } from 'react-redux';
import api from 'src/utils/services/axiosConfig';
import {
  getTenants,
  addTenant,
  getPropertiesForTenants,
  getAssignProperties,
  getUnAssignProperties,
  UnAssignProperties,
  AssignProperties,
} from 'src/utils/services/service/tenantService';
import { addNewtenant, add_newTenant } from 'src/slices/thunks';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import { FiEdit } from 'react-icons/fi';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TableView from 'src/components/Common/TableView/TableView';
import { RootState } from 'src/slices';
import Table, { ColumnsType } from 'antd/es/table';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';
import Search from 'antd/es/transfer/search';
import { IoSearchSharp } from 'react-icons/io5';
import { TableRowSelection } from 'antd/es/table/interface';

const TenantList = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [tenantName,setSelected]
  const [updatingData, setUpdatingData] = useState<any>(null);

  // const [properties, setProperties] = useState([]);

  const { userData } = useSelector((state: RootState) => state.login);
  const { realFormate } = useSelector((state: RootState) => state.porperty);

  const handleGetTenant = async () => {
    try {
      setLoading(true);
      const response = await getTenants();
      const optionArray = response.data.tenants?.map((item: any) => ({
        name: item.name,
        id: item.id,
      }));
      setUsers(optionArray);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [assignedProperties, setAssignedProperties] = useState<any[]>([]);
  const [unAssignProperties, setUnAssignProperties] = useState<any[]>([]);
  const [isAssignPropertiesLoading, setIsAssignPropertiesLoading] = useState<boolean>(false);
  const [isUnAssigningPropertiesLoading, setIsUnAssigningPropertiesLoading] = useState<boolean>(false);

  const [targetKeysForProperties, setTargetKeysForProperties] = useState<any[]>([]);
  const [selectedKeysForProperties, setSelectedKeysForProperties] = useState<any[]>([]);

  const extractRealmFromIssuer = (iss: string) => {
    const parts = iss?.split('/');
    const realmIndex = parts?.indexOf('realms');
    if (realmIndex !== -1 && realmIndex + 1 < parts?.length) {
      return parts[realmIndex + 1];
    }
    return '';
  };

  const realm = extractRealmFromIssuer(userData?.iss);

  const GetUnAssignProperties = async () => {
    setIsUnAssigningPropertiesLoading(true);
    try {
      const response: any = await getUnAssignProperties();
      setUnAssignProperties(response);
      setTargetKeysForProperties(response);
    } catch (error) {
      setIsUnAssigningPropertiesLoading(false);
      console.error('Failed to load properties', error);
    } finally {
      setIsUnAssigningPropertiesLoading(false);
    }
  };
  const GetAssignProperties = async () => {
    try {
      setIsAssignPropertiesLoading(true);
      const response = await getAssignProperties(updatingData.name);
      setAssignedProperties(response);
      setSelectedKeysForProperties(response);
    } catch (error) {
      setIsAssignPropertiesLoading(false);
      console.error('Failed to load properties', error);
    } finally {
      setIsAssignPropertiesLoading(false);
    }
  };

  const targetKeys = assignedProperties.map((item) => item.id);

  useEffect(() => {
    if (isEdit) {
      GetAssignProperties();
      GetUnAssignProperties();
    }
  }, [isEdit]);

  console.log(updatingData);

  const { isCreating } = useSelector((state: any) => state.tenants);
  console.log('creating tenant', isCreating);
  const [userList, setUserList] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);

  type NotificationType = 'success' | 'info' | 'warning' | 'error';
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const handleShow = () => {
    setUpdatingData(null);
    setModal(false);
  };

  const contactListColumns = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 150,

      render: (_: any, record: any) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                handleUserClick(record);
                setUpdatingData(record);
              }}
            >
              <FiEdit size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="No"
                okText="Yes"
                okButtonId={`confirmAndDeleteNotificationContact`}
                triggerButtonId="deleteNotificationContact"
                onConfirm={() => {
                  // TODO handle delete
                  console.log('Remove');
                  openNotificationWithIcon('info', 'This feature is not available');
                }}
              />
            </div>
          </div>
        );
      },
    },

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
  ];

  useEffect(() => {
    if (users && !users?.length) {
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    handleGetTenant();
    setUserList(users);
    setIsEdit(false);
  }, []);

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(users) && !!isEdit) {
      setTimeout(() => {
        setUserList(users);
        setIsEdit(false);
      }, 500);
    }
  };

  const handleUserClick = (arg: any) => {
    const user = arg;

    setUserList({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
    });
    setIsEdit(true);

    toggle();
  };

  // const handleDeleteUser = (user: any) => {
  //   dispatch(onDeleteUser(user));
  // };

  /**
   * Handling submit user on user form
   */
  const handleValidUserSubmit = async (values: any) => {
    if (!isEdit) {
      const response: any = await dispatch(add_newTenant(values.name));
      if (response?.payload?.tenantName === values.name) {
        openNotificationWithIcon('success', 'Tenant created successfully');
        dispatch(handleGetTenant());
        setModal(false);
      } else {
        openNotificationWithIcon('error', 'Tenant creation failed');
      }
      setModal(false);
    }
    // toggle();
  };
  const handleUserClicks = () => {
    setUserList('');
    setIsEdit(false);
    toggle();
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const [selectedKeys, setSelectedKeys] = useState<any>([]);

  const handleSelectChange = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  console.log('realFormate:', realFormate);

  const [searchAssignedProperties, setSearchAssignedProperties] = useState('');

  const assignedPropertiesColumns: ColumnsType<any> = [
    // {
    //   title: 'Property Id',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: 200,
    //   render: (text: string) => AbbreviatedId({ id: text }),
    // },

    {
      title: 'Name',
      dataIndex: 'name',
      render: (_cellContent: any, data: any) => (
        <>
          <span>{`${data?.mandantId} / ${data?.place}`}</span>
        </>
      ),
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      sorter: (a: any, b: any) => {
        if (!a.postalCode) return 1;
        if (!b.postalCode) return -1;
        return a.postalCode.localeCompare(b.postalCode);
      },
      onFilter: (value: any, record: any) => {
        return record.postalCode ? record.postalCode.indexOf(value) === 0 : record.postalCode;
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [newAssignedSelectedRowKeys, setNewAssignedSelectedRowKeys] = useState<React.Key[]>([]);
  const [isUnAssigning, setIsUnAssigning] = useState<boolean>(false);
  const [isUnAssignModalOpen, setIsUnAssignModalOpen] = useState<boolean>(false);
  const [isAssigning, setIsAssigning] = useState<boolean>(false);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectChangeForNewAssigned = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setNewAssignedSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const rowSelectionForNewAssigned: TableRowSelection<any> = {
    selectedRowKeys: newAssignedSelectedRowKeys,
    onChange: onSelectChangeForNewAssigned,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const hasSelected = selectedRowKeys.length > 0;
  const hasSelectedUnAssigned = newAssignedSelectedRowKeys.length > 0;

  const handleRemoveAssignedProperties = async (id: string[]) => {
    setIsUnAssigning(true);
    const newAssignedProperties = assignedProperties.filter((property) => {
      return !id.includes(property.id);
    });
    setAssignedProperties(newAssignedProperties);
    setSelectedRowKeys([]);
    try {
      const response = await UnAssignProperties(id, updatingData.name);
      console.log(response);
      openNotificationWithIcon('success', 'Properties unassigned successfully');
    } catch (error) {
      console.log('Error', error);
      openNotificationWithIcon('error', 'Failed to unassign properties');
    } finally {
      setIsUnAssigning(false);
      GetAssignProperties();
      GetUnAssignProperties();
    }
  };

  const handleAssignedProperties = async (id: string[]) => {
    setIsAssigning(true);
    const res = assignedProperties.filter((property) => {
      return !id.includes(property.id);
    });
    setUnAssignProperties(res);
    setNewAssignedSelectedRowKeys([]);
    try {
      const response = await AssignProperties(id, updatingData.name);
      console.log(response);
      openNotificationWithIcon('success', 'Properties assigned successfully');
      setIsUnAssignModalOpen(false);
    } catch (error) {
      console.log('Error', error);
      openNotificationWithIcon('error', 'Failed to assign properties');
    } finally {
      setIsAssigning(false);
      GetUnAssignProperties();
      GetAssignProperties();
    }
  };

  // const

  return (
    <>
      <div className="page-content">
        {contextHolder}
        <MetaTags>
          <title>Tenants | Data!</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Tenants" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div>
                        <SearchBar
                          value={searchValue}
                          onChange={handleSearchChange}
                          placeholder="Search tenants by name"
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Link
                            to="#"
                            className="btn btn-light"
                            onClick={
                              // setIsModalOpen(!isModalOpen);
                              handleUserClicks
                            }
                          >
                            <i className="uil uil-plus me-1"></i> Add
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableView
                      loading={loading}
                      columns={contactListColumns}
                      dataSource={users?.filter((value: any) => {
                        if (searchValue === '') {
                          return value;
                        } else if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
                          return value;
                        }
                      })}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            size={isEdit ? 'lg' : ''}
            centered
            isOpen={modal}
            toggle={() => {
              toggle();
              if (isEdit) {
                setIsEdit(false);
                setUpdatingData(null);
                setSelectedRowKeys([]);
              }
            }}
          >
            <ModalHeader
              toggle={() => {
                toggle();
                if (isEdit) {
                  setIsEdit(false);
                  setUpdatingData(null);
                  setSelectedRowKeys([]);
                }
              }}
              tag="h4"
            >
              {isEdit ? 'Update tenant' : 'Create tenant'}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={(_e: any, values: any) => {
                  handleValidUserSubmit(values);
                }}
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <AvField
                        name="name"
                        label="Name"
                        type="name"
                        placeholder="Enter tenant name"
                        errorMessage="Enter tenant name"
                        validate={{
                          required: { value: true },
                        }}
                        value={updatingData?.name || ''}
                      />
                    </div>
                  </Col>
                  {isEdit && (
                    <>
                      <Col>
                        <Card
                          title="Manage Properties"
                          extra={
                            <>
                              <Button
                                loading={isUnAssigningPropertiesLoading}
                                disabled={isUnAssigningPropertiesLoading}
                                ghost
                                icon={
                                  <>
                                    <i className="uil uil-plus me-1"></i>
                                  </>
                                }
                                type="primary"
                                onClick={() => setIsUnAssignModalOpen(true)}
                              >
                                Assign Properties
                              </Button>
                            </>
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginBottom: 10,
                              alignContent: 'center',
                            }}
                          >
                            <p>Assigned Properties</p>
                            <Input
                              onChange={(e) => setSearchAssignedProperties(e.target.value)}
                              placeholder="Search Properties"
                              prefix={
                                <>
                                  <IoSearchSharp color="#ced4da" size={20} />
                                </>
                              }
                              style={{ width: 300 }}
                            />
                          </div>

                          <Table
                            rowKey={(record) => record.id}
                            loading={isAssignPropertiesLoading || isUnAssigning}
                            scroll={{ y: 250 }}
                            columns={assignedPropertiesColumns}
                            dataSource={assignedProperties?.filter((column: any) => {
                              if (searchAssignedProperties === '') {
                                return column;
                              } else if (
                                column?.name?.toLowerCase().includes(searchAssignedProperties.toLowerCase()) ||
                                column?.place?.toLowerCase().includes(searchAssignedProperties.toLowerCase())
                              ) {
                                return column;
                              }
                            })}
                            rowSelection={{
                              type: 'checkbox',
                              ...rowSelection,
                            }}
                            pagination={{
                              defaultPageSize: 100,
                              pageSizeOptions: [50, 100],
                              showSizeChanger: false,
                              hideOnSinglePage: true,
                            }}
                          />
                          <Flex
                            align="center"
                            gap="middle"
                            style={{
                              marginTop: 20,
                            }}
                          >
                            <Popconfirm
                              title="Unassign selected properties?"
                              description="Are you sure you want to unassign selected properties ?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => {
                                handleRemoveAssignedProperties(selectedRowKeys as string[]);
                              }}
                            >
                              <Button
                                type="primary"
                                danger
                                style={{
                                  borderRadius: 5,
                                }}
                                loading={isUnAssigning}
                                // onClick={() => {
                                //   handleRemoveAssignedProperties(selectedRowKeys as string[]);
                                // }}
                                disabled={!hasSelected || isUnAssigning}
                              >
                                Unassign
                              </Button>
                            </Popconfirm>

                            {hasSelected ? `Selected ${selectedRowKeys.length} Properties` : null}
                          </Flex>
                        </Card>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col>
                    <div className="text-end mt-5">
                      <button type="button" className="btn btn-light w-sm" onClick={handleShow}>
                        Close
                      </button>
                      <button
                        style={{ marginLeft: 10 }}
                        type="submit"
                        disabled={isCreating}
                        className="btn btn-success save-user "
                      >
                        {isEdit ? <>Update</> : <>create</>}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={isUnAssignModalOpen}
            toggle={() => {
              setIsUnAssignModalOpen(!isUnAssignModalOpen);
              setNewAssignedSelectedRowKeys([]);
            }}
            centered
          >
            <ModalHeader
              toggle={() => {
                setIsUnAssignModalOpen(!isUnAssignModalOpen);
                setNewAssignedSelectedRowKeys([]);
              }}
              tag="h4"
            >
              Unassigned Properties
            </ModalHeader>
            <Card>
              <Table
                rowKey={(record) => record.id}
                loading={isUnAssigningPropertiesLoading || isAssigning}
                scroll={{ y: 250 }}
                columns={assignedPropertiesColumns}
                dataSource={unAssignProperties}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelectionForNewAssigned,
                }}
                pagination={{
                  defaultPageSize: 100,
                  pageSizeOptions: [50, 100],
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
              />
              <Flex
                align="center"
                gap="middle"
                style={{
                  marginTop: 20,
                }}
              >
                <Button
                  type="primary"
                  style={{
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    handleAssignedProperties(newAssignedSelectedRowKeys as string[]);
                  }}
                  disabled={!hasSelectedUnAssigned || isAssigning}
                >
                  Assign
                </Button>
                {hasSelectedUnAssigned ? `Selected ${newAssignedSelectedRowKeys.length} Properties` : null}
              </Flex>
            </Card>
            <Row>
              <Col>
                <div className="text-end mt-5 p-2">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={() => {
                      setIsUnAssignModalOpen(!isUnAssignModalOpen);
                      setNewAssignedSelectedRowKeys([]);
                    }}
                  >
                    Close
                  </button>
                </div>
              </Col>
            </Row>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default withRouter(TenantList);
