import React, { FC } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';

interface DeleteModalProps {
  isOpen: boolean;
  toggleDeleteModal: (arg?: any) => void;
  handleShowDeleteModal: (arg: any) => void;
  onDelete: (arg?: any) => void;
  title?: string;
  confirmText?: string;
}

const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  toggleDeleteModal,
  handleShowDeleteModal,
  onDelete,
  confirmText,
  title,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleDeleteModal}>
      <ModalHeader toggle={toggleDeleteModal} tag="h4">
        {title || 'Delete modal'}
      </ModalHeader>
      <ModalBody>
        <Row form>
          <Col xs={12}>
            <div className="mb-3 text-center fs-5">{confirmText || 'Are you sure you want to delete?'}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="w-100 d-flex justify-content-center">
              <button type="button" className="btn btn-light w-50  mx-2" onClick={handleShowDeleteModal}>
                Close
              </button>
              <button type="submit" className="btn btn-danger w-50 save-user" onClick={onDelete}>
                Delete
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
