import { IRoomConfiguration } from 'src/utils/types/roomConfigurationsTypes';
import { CreateRoomPlan, DeleteRoomPlan, GetAllRoomPlans, GetSingleRoomPlan, UpdateRoomPlan } from './thunk';
import { createSlice } from '@reduxjs/toolkit';

interface IRoomConfigurationState {
  roomConfigurations: IRoomConfiguration[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IRoomConfigurationState = {
  roomConfigurations: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const roomConfigurationSlice = createSlice({
  name: 'roomConfigurations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRoomPlans.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllRoomPlans.fulfilled, (state, action) => {
        state.roomConfigurations = action.payload as [];
        state.isLoading = false;
      })
      .addCase(GetAllRoomPlans.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleRoomPlan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleRoomPlan.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleRoomPlan.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(CreateRoomPlan.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateRoomPlan.fulfilled, (state) => {
        state.isCreating = false;
      })
      .addCase(CreateRoomPlan.rejected, (state) => {
        state.isCreating = false;
      })
      .addCase(UpdateRoomPlan.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateRoomPlan.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateRoomPlan.rejected, (state) => {
        state.isUpdating = false;
      })
      .addCase(DeleteRoomPlan.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteRoomPlan.fulfilled, (state, action) => {
        console.log(action);
        state.isDeleting = false;
      })
      .addCase(DeleteRoomPlan.rejected, (state) => {
        state.isDeleting = false;
      });
  },
});

export default roomConfigurationSlice.reducer;
