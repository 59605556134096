import { createAsyncThunk } from '@reduxjs/toolkit';
import { MealPlansDataService } from 'src/utils/services/service/mealPlans';

import { IMealPlans } from 'src/utils/types/mealPlansTypes';

export const CreateMealPlansData = createAsyncThunk(
  'mealPlans/createMealPlansData',
  async ({ data }: { data: IMealPlans }) => {
    try {
      const response = await MealPlansDataService.createMealPlansData(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetAllMealPlansData = createAsyncThunk(
  'mealPlans/getAllMealPlansData',
  async () => {
    try {
      const response = await MealPlansDataService.getAllMealPlansData();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetSingleMealPlansData = createAsyncThunk(
  'mealPlans/getSingleMealPlansData',
  async ({ mealplansId }: { mealplansId: string }) => {
    try {
      const response = await MealPlansDataService.getSingleMealPlansData(mealplansId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateMealPlansData = createAsyncThunk(
  'mealPlans/updateMealPlansData',
  async ({ mealplansId, data }: { mealplansId: string; data: IMealPlans }) => {
    try {
      const response = await MealPlansDataService.updateMealPlansData(mealplansId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteMealPlansData = createAsyncThunk(
  'mealPlans/deleteMealPlansData',
  async ({ mealplansId }: { mealplansId: string }) => {
    try {
      const response = await MealPlansDataService.deleteMealPlansData(mealplansId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
