import axios from 'axios';
import { IPriceList } from 'src/utils/types/priceListTypes';
import env from 'src/env';
const baseURL = env.VITE_PRICE_LIST_DEFAULT_BASE_URL();
console.log("baseUR",baseURL);
const priceListBaseUrl = '/price-lists';

const getAllPriceListData = async (): Promise<IPriceList[] | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IPriceList[] = await axios.get(`${priceListBaseUrl}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching all Price List Data:', error);
    throw error;
  }
};

const getSinglePriceListData = async (pricelistId: string): Promise<IPriceList | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IPriceList = await axios.get(`${priceListBaseUrl}/${pricelistId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching single Price List Data:', error);
    throw error;
  }
};

const createPriceListData = async (data: IPriceList): Promise<IPriceList | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IPriceList = await axios.post(`${priceListBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating Price List Data:', error);
    throw error;
  }
};

const updatePriceListData = async (
  pricelistId: string,
  data: IPriceList,
): Promise<IPriceList | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IPriceList = await axios.put(`${priceListBaseUrl}/${pricelistId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating Price List Data:', error);
    throw error;
  }
};



const deletePriceListData = async (pricelistId: string): Promise<string | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    await axios.delete(`${priceListBaseUrl}/${pricelistId}`, { headers, baseURL });
    return pricelistId;
  } catch (error) {
    console.error('Error deleting Price List Data:', error);
    throw error;
  }
};


export const PriceListService = {
  getAllPriceListData,
  getSinglePriceListData,
  createPriceListData,
  updatePriceListData,
  deletePriceListData,
};
