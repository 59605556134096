import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationContactService } from 'src/utils/services/service/notificationContacts';

import { INotificationContacts } from 'src/utils/types/notificatioinContactsTypes';

export const CreateNotificationContact = createAsyncThunk(
  'notificationContacts/createNotificationContact',
  async ({ data }: { data: INotificationContacts }) => {
    try {
      const response = await NotificationContactService.createNotificationContact(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetAllNotificationContacts = createAsyncThunk(
  'notificationContacts/getAllNotificationContacts',
  async () => {
    try {
      const response = await NotificationContactService.getAllNotificationContacts();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetSingleNotificationContacts = createAsyncThunk(
  'notificationContacts/getSingleNotificationContacts',
  async ({ notificationId }: { notificationId: string }) => {
    try {
      const response = await NotificationContactService.getSingleNotificationContact(notificationId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateNotificationContact = createAsyncThunk(
  'notificationContacts/updateNotificationContact',
  async ({ notificationId, data }: { notificationId: string; data: INotificationContacts }) => {
    try {
      const response = await NotificationContactService.updateNotificationContact(notificationId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteNotificationContact = createAsyncThunk(
  'notificationContacts/deleteNotificationContact',
  async ({ notificationId }: { notificationId: string }) => {
    try {
      const response = await NotificationContactService.deleteNotificationContact(notificationId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
