import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITag } from 'src/utils/types/tagTypes';
import { GetAllTags, AddAssignmentToTag, CreateTag, DeleteTag, UpdateTag } from './thunk';

interface ITagsState {
  tags: ITag[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isAddingAssignment: boolean;
  error: string | null;
}

const initialState: ITagsState = {
  tags: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isAddingAssignment: false,
  error: null,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllTags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllTags.fulfilled, (state, action) => {
        state.tags = action.payload as ITag[];
        state.isLoading = false;
      })
      .addCase(GetAllTags.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateTag.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateTag.fulfilled, (state) => {
        state.isCreating = false;
      })
      .addCase(CreateTag.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateTag.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateTag.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateTag.rejected, (state, action) => {
        state.isUpdating = false;
        console.log(action);
      })
      .addCase(DeleteTag.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteTag.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(DeleteTag.rejected, (state) => {
        state.isDeleting = false;
      })
      .addCase(AddAssignmentToTag.pending, (state) => {
        state.isAddingAssignment = true;
      })
      .addCase(AddAssignmentToTag.fulfilled, (state) => {
        state.isAddingAssignment = false;
      })
      .addCase(AddAssignmentToTag.rejected, (state) => {
        state.isAddingAssignment = false;
      });
  },
});

export default tagsSlice.reducer;
