import { createAsyncThunk } from '@reduxjs/toolkit';
import { roomPlanService } from 'src/utils/services/service/roomPlanService';
import { IRoomConfiguration } from 'src/utils/types/roomConfigurationsTypes';

const CreateRoomPlan = createAsyncThunk(
  'room-configurations/create-room-plan',
  async ({ data }: { data: IRoomConfiguration }) => {
    try {
      const response = await roomPlanService.createRoomConfiguration(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

const GetAllRoomPlans = createAsyncThunk('room-configurations/get-all-room-plans', async () => {
  try {
    const response = await roomPlanService.getAllRoomConfigurations();
    return response;
  } catch (error) {
    return error;
  }
});

const GetSingleRoomPlan = createAsyncThunk(
  'room-configurations/get-single-room-plan',
  async ({ roomConfigurationId }: { roomConfigurationId: string }) => {
    try {
      const response = await roomPlanService.getRoomConfigurationById(roomConfigurationId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

const UpdateRoomPlan = createAsyncThunk(
  'room-configurations/update-room-plan',
  async ({ data }: { data: IRoomConfiguration }) => {
    try {
      const response = await roomPlanService.updateRoomConfiguration(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

const DeleteRoomPlan = createAsyncThunk(
  'room-configurations/delete-room-plan',
  async ({ roomConfigurationId }: { roomConfigurationId: string }) => {
    try {
      const response = await roomPlanService.deleteRoomConfiguration(roomConfigurationId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export { CreateRoomPlan, GetAllRoomPlans, GetSingleRoomPlan, UpdateRoomPlan, DeleteRoomPlan };
