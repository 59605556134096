

import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { IMealPlans } from 'src/utils/types/mealPlansTypes';
import MealPlansModal from './components/MealPlansModal';
import { FiEdit } from 'react-icons/fi';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { GetAllMealPlansData, DeleteMealPlansData } from 'src/slices/MealPlans/thunk';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';
import { NotificationType } from 'src/utils/types/NotificationTypes';



const MealPlans = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IMealPlans | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();


  const { isLoading,isDeleting, mealPlans } = useSelector((state: RootState) => state.mealPlans);
  const dispatch: AppDispatch = useDispatch();


  useEffect(() => {
    if (!isLoading && mealPlans.length === 0) {
      dispatch(GetAllMealPlansData());
    }
  }, []);


  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };



  const columns: TableProps<IMealPlans>['columns'] = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 150,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateMealPlans" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="No"
                okText="Yes"
                okButtonId={`confirmAndDeleteMealPlans`}
                triggerButtonId="deleteMealPlans"
                onConfirm={() => {
                  deleteMealPlans(record.id!);
                }}


              />
            </div>
          </div>
        );
      },
    },
 
    {
      title: 'Property ID',
      dataIndex: 'propertyId',
      key: 'propertyId',
      width: 100,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 150,

    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      width: 200,

    },


    {
      title: 'Accommodation Kinds',
      key: 'accommodationKinds',
      dataIndex: 'accommodationKinds',
      width: 150,
      render: (accommodationKinds: string[]) => accommodationKinds.join(', '),
    },
    {
      title: 'Room Types',
      key: 'roomTypes',
      dataIndex: 'roomTypes',
      width: 150,
      render: (roomTypes: string[]) => roomTypes.join(', '),
    },


  ];



  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };


  const deleteMealPlans = async (mealplansId: string) => {
    if (!mealplansId) {
      openNotificationWithIcon('error', 'Did not find the meal id');
      return;
    }
    try {
      const response = await dispatch(
        DeleteMealPlansData({
          mealplansId,
        }),
      );
      if (response.payload === mealplansId) {
        openNotificationWithIcon('success', 'Meal Plans deleted successfully');
        dispatch(GetAllMealPlansData());
      } else {
        openNotificationWithIcon('error', 'Error deleting meal List');
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>Meal Plans</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="Settings" breadcrumbItem="Meal Plans" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                          id="mealPlansSearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder="Search data by name"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                             id="createMealPlans"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> Add
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >

                      <TableView
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={mealPlans
                          ?.filter((data: IMealPlans) => {
                            if (searchValue === '') return data;
                            else if (

                              data.name?.toLowerCase().includes(searchValue.toLowerCase())

                            ) {
                              return data;
                            }
                          })
                          ?.reverse()}
                      />

                      {/* <TableView loading={false} columns={columns} dataSource={manageHotelTestData} /> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <MealPlansModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default MealPlans;
