import axios from 'axios';
import { ICreateUser, IEditUser } from 'src/utils/types/userTypes';
import env from '../../../env';

// Base URL for the user API, with a fallback if not defined in environment variables
const userApiBaseUrl = env.VITE_REACT_APP_DEFAULTAUTH_USERS() || '';

// Configures the axios instance with the base URL
const api = axios.create({
  baseURL: userApiBaseUrl,
});

/**
 * Helper function to get the authorization headers.
 * @returns Authorization headers with the JWT token.
 */
const getAuthHeaders = () => {
  const accessToken = localStorage.getItem('accessToken');
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

/**
 * Fetches all users with optional filters.
 * @param {Object} data Optional parameters for search, skip, and take.
 * @returns A list of users based on the provided parameters.
 */
export const getAllUsers = async (data: any = {}) => {
  try {
    const { search = '', skip = '0', take = '10' } = data;
    const response = await api.get(`/User?skip=${skip}&take=${take}&searchKeyword=${search}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error; // Rethrow to let calling component handle it
  }
};

/**
 * Creates a new user.
 * @param {ICreateUser} data User creation data.
 * @returns The created user data.
 */
export const createUser = async (data: ICreateUser, t: string) => {
  console.log(t);
  try {
    const response = await api.post('/User', data, {
      headers: getAuthHeaders(),
      params: {
        tenant: t,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Check if the error response is structured as expected from the backend
      if (error.response && typeof error.response.data === 'object') {
        // Handle known error structure (e.g., conflict or validation error)
        console.error('Error creating user:', error.response.data);

        // Consider creating a custom Error type or structure that your components can easily interpret
        throw new Error(JSON.stringify(error.response.data));
      } else {
        // Generic error message for unexpected error structures
        console.error('An unexpected error occurred:', error);
        throw new Error('An unexpected error occurred while creating the user. Please try again.');
      }
    } else {
      // Non-Axios error (e.g., network error, timeout)
      console.error('Network or timeout error:', error);
      throw new Error(
        'A network or timeout error occurred while creating the user. Please check your connection and try again.',
      );
    }
  }
};

/**
 * Edits an existing user.
 * @param {IEditUser} data User edit data.
 * @returns The updated user data.
 */
export const editUser = async (data: IEditUser) => {
  try {
    const response = await api.put('/User', data, { headers: getAuthHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error; // Rethrow to let calling component handle it
  }
};
