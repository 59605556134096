import axios, { AxiosResponse } from 'axios';
import env from 'src/env';
import { IText } from 'src/utils/types/textTypes';

const baseURL = '';
const textManagementBaseUrl = ' /marketing/settings/texts';

export const getListOfTexts = async (): Promise<IText | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IText[] = await axios.get(`${textManagementBaseUrl}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching list of Texts:', error);
    throw error;
  }
};

export const getSingleText = async (textId: string): Promise<IText | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IText = await axios.get(`${textManagementBaseUrl}/${textId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error fetching Text:', error);
    throw error;
  }
};

export const createNewTextEntry = async (data: {
  content: string;
  hashtags: string[];
  keywords: string[];
  enabled: boolean;
}): Promise<IText | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IText = await axios.post(`${textManagementBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating Text:', error);
    throw error;
  }
};

export const updateTextEntry = async (textId: string, data: IText): Promise<IText | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IText = await axios.put(`${textManagementBaseUrl}/${textId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating Text:', error);
    throw error;
  }
};

export const deleteTextEntry = async (textId: string): Promise<IText | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IText = await axios.delete(`${textManagementBaseUrl}/${textId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error deleting Text:', error);
    throw error;
  }
};

export const TextManagementService = {
  getListOfTexts,
  getSingleText,
  createNewTextEntry,
  updateTextEntry,
  deleteTextEntry,
};
