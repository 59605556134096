/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { AppDispatch } from 'src/store';
import { Competitor, HotelData } from 'src/utils/types/HotelData';
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { IoIosSearch } from 'react-icons/io';
import SearchModal from './components/SearchModal';
import { FiEdit } from 'react-icons/fi';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import CompetitorsHotelsModal from './components/CompetitorsHotelsModal';
import { DeleteCompetitorHotelEntry, ReadHotelCompetitors } from 'src/slices/CompetitorHotels/thunk';
import TableView from 'src/components/Common/TableView/TableView';

const CompetitorHotels = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<HotelData | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const dispatch: AppDispatch = useDispatch();
  const { isLoading, competitorHotels, isDeleting } = useSelector((state: RootState) => state.competitor);
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };
  useEffect(() => {
    if (!isLoading && competitorHotels.length === 0) {
      dispatch(ReadHotelCompetitors());
    }
  }, []);

  console.log(competitorHotels);

  const data: HotelData[] = [
    {
      propertyId: 12,
      competitors: [
        {
          platforms: [
            { platform: 'Booking.com', platformId: '12345' },
            { platform: 'Expedia', platformId: '67890' },
          ],
          url: 'https://www.example-competitor1.com',
        },
      ],
    },
    {
      propertyId: 11,
      competitors: [
        {
          platforms: [
            { platform: 'Airbnb', platformId: '12' },
            { platform: 'Ola', platformId: '67' },
          ],
          url: 'https://www.example-competitor2.com',
        },
      ],
    },
    {
      propertyId: 13,
      competitors: [
        {
          platforms: [
            { platform: 'TripAdvisor', platformId: '34521' },
            { platform: 'Hotels.com', platformId: '98765' },
          ],
          url: 'https://www.example-competitor3.com',
        },
      ],
    },
    {
      propertyId: 14,
      competitors: [
        {
          platforms: [
            { platform: 'Agoda', platformId: '54321' },
            { platform: 'Trivago', platformId: '19283' },
          ],
          url: 'https://www.example-competitor4.com',
        },
      ],
    },
    {
      propertyId: 15,
      competitors: [
        {
          platforms: [
            { platform: 'Kayak', platformId: '10001' },
            { platform: 'Orbitz', platformId: '10002' },
          ],
          url: 'https://www.example-competitor5.com',
        },
      ],
    },
    {
      propertyId: 16,
      competitors: [
        {
          platforms: [
            { platform: 'Priceline', platformId: '23456' },
            { platform: 'Skyscanner', platformId: '78901' },
          ],
          url: 'https://www.example-competitor6.com',
        },
      ],
    },
    {
      propertyId: 17,
      competitors: [
        {
          platforms: [
            { platform: 'Travelocity', platformId: '34567' },
            { platform: 'Hotwire', platformId: '89123' },
          ],
          url: 'https://www.example-competitor7.com',
        },
      ],
    },
    {
      propertyId: 18,
      competitors: [
        {
          platforms: [
            { platform: 'Hostelworld', platformId: '98765' },
            { platform: 'Lastminute.com', platformId: '45678' },
          ],
          url: 'https://www.example-competitor8.com',
        },
      ],
    },
    {
      propertyId: 19,
      competitors: [
        {
          platforms: [
            { platform: 'Couchsurfing', platformId: '65432' },
            { platform: 'Google Hotels', platformId: '32109' },
          ],
          url: 'https://www.example-competitor9.com',
        },
      ],
    },
    {
      propertyId: 20,
      competitors: [
        {
          platforms: [
            { platform: 'Vrbo', platformId: '54321' },
            { platform: 'Trip.com', platformId: '87654' },
          ],
          url: 'https://www.example-competitor10.com',
        },
      ],
    },
  ];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const deleteCompetitorHotel = async (Id: string) => {
    if (!Id) {
      openNotificationWithIcon('error', 'Did not find the hotel id');
      return;
    }
    try {
      const response = await dispatch(
        DeleteCompetitorHotelEntry({
          id: Id as string,
        }),
      );

      if (response.payload.status === 204) {
        openNotificationWithIcon('success', 'Competitor hotel deleted successfully');
        dispatch(ReadHotelCompetitors());
      } else {
        openNotificationWithIcon('error', 'Error deleting Competitor hotel');
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };

  return (
    <div>
      <div>
        <div>
          {contextHolder}
          <div className="page-content">
            <MetaTags>
              <title>Competitors Hotel </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumb title="Settings" breadcrumbItem="Competitor Hotel " />

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <div>
                            <SearchBar
                              id="notificationContactsSearchBar"
                              value={searchValue}
                              onChange={handleSearchChange}
                              placeholder="Search contacts by name, email, kind"
                            />
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                            <Button
                              id="addNotificationContact"
                              className="btn btn-info"
                              onClick={() => {
                                setIsSearchModalOpen(!isSearchModalOpen);
                              }}
                            >
                              <IoIosSearch />

                              <span
                                style={{
                                  marginLeft: '5px',
                                }}
                              >
                                Search Hotels
                              </span>
                            </Button>
                            <div>
                              <Button
                                id="addNotificationContact"
                                className="btn btn-light"
                                onClick={() => {
                                  setIsModalOpen(!isModalOpen);
                                }}
                              >
                                <i className="uil uil-plus me-1"></i> Add
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {/* Table View */}
                      <div
                        style={{
                          height: '64vh',
                        }}
                      >
                        <Table
                          loading={isLoading || isDeleting}
                          bordered
                          scroll={{ y: 400 }}
                          sticky={true}
                          dataSource={competitorHotels
                            ?.filter((hotel) => {
                              if (searchValue === '') {
                                return hotel;
                              } else if (
                                hotel?.competitors?.some((competitor) => {
                                  return competitor?.url?.toLowerCase().includes(searchValue.toLowerCase());
                                })
                              ) {
                                return hotel;
                              } else if (
                                hotel?.competitors?.some((competitor) => {
                                  return competitor?.platforms?.some((platform) => {
                                    return platform.platformId?.toLowerCase().includes(searchValue.toLowerCase());
                                  });
                                })
                              ) {
                                return hotel;
                              } else if (
                                hotel?.competitors?.some((competitor) => {
                                  return competitor?.platforms?.some((platform) => {
                                    return platform.platform?.toLowerCase().includes(searchValue.toLowerCase());
                                  });
                                })
                              ) {
                                return hotel;
                              }
                            })
                            ?.reverse()}
                          rowKey="propertyId"
                          pagination={false}
                        >
                          <Table.Column<HotelData>
                            title="Action"
                            width={100}
                            key="action"
                            render={(_, record: HotelData) => (
                              <div style={{ display: 'flex' }}>
                                <div
                                  className="tableIcon"
                                  onClick={() => {
                                    setIsModalOpen(!isModalOpen);
                                    setIsEditMode(true);
                                    setEditingData(record);
                                  }}
                                >
                                  <FiEdit id="updateNotificationContacts" size={20} />
                                </div>
                                <div className="tableIcon">
                                  <DeleteButton
                                    title="Are you sure you want to delete?"
                                    cancelText="Cancel"
                                    okText="Proceed"
                                    okButtonId={`confirmAndDeleteNotificationContact`}
                                    triggerButtonId="deleteNotificationContact"
                                    onConfirm={() => {
                                      deleteCompetitorHotel(record.id as string);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          />

                          <Table.Column<HotelData>
                            title="Competitor URL"
                            dataIndex={['competitors']} // Access all competitors
                            key="url"
                            render={(competitors: Competitor[]) => (
                              <>
                                {competitors?.map((competitor, index) => (
                                  <div key={index}>
                                    <a href={competitor?.url} target="_blank" rel="noopener noreferrer">
                                      {competitor?.url}
                                    </a>
                                    <hr />
                                  </div>
                                ))}
                              </>
                            )}
                          />

                          <Table.ColumnGroup title="Competitors">
                            <Table.Column<HotelData>
                              title="Platform"
                              key="platform"
                              render={(_, record: HotelData) => (
                                <>
                                  {record?.competitors?.map((competitor, competitorIndex) => (
                                    <div key={competitorIndex}>
                                      {competitor?.platforms?.map((platform, platformIndex) => (
                                        <div key={platformIndex}>
                                          {platform.platform}
                                          <hr />
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </>
                              )}
                            />
                            <Table.Column<HotelData>
                              title="Platform ID"
                              key="platformId"
                              render={(_, record: HotelData) => (
                                <>
                                  {record?.competitors?.map((competitor, competitorIndex) => (
                                    <div key={competitorIndex}>
                                      {competitor?.platforms.map((platform, platformIndex) => (
                                        <div key={platformIndex}>
                                          <Tag color="blue">{platform.platformId}</Tag>
                                          <br />
                                          <hr />
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </>
                              )}
                            />
                          </Table.ColumnGroup>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/**  Modal to handle create and update  **/}
              <CompetitorsHotelsModal
                isEditMode={isEditMode}
                initialValues={editingData}
                openNotificationWithIcon={openNotificationWithIcon}
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(!isModalOpen)}
                setIsEditMode={setIsEditMode}
                setEditingData={setEditingData}
              />

              <SearchModal isOpen={isSearchModalOpen} toggle={() => setIsSearchModalOpen(!isSearchModalOpen)} />
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorHotels;
