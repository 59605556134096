import { createSlice } from '@reduxjs/toolkit';
import {
  CreateNotificationContact,
  DeleteNotificationContact,
  GetAllNotificationContacts,
  GetSingleNotificationContacts,
  UpdateNotificationContact,
} from './thunk';
import { INotificationContacts } from 'src/utils/types/notificatioinContactsTypes';

interface INotificationContactsState {
  notificationContacts: INotificationContacts[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: INotificationContactsState = {
  notificationContacts: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const notificationContactsSlice = createSlice({
  name: 'notificationContacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllNotificationContacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllNotificationContacts.fulfilled, (state, action: any) => {
        console.log(action);
        state.notificationContacts = action.payload.notificationContracts;
        state.isLoading = false;
      })
      .addCase(GetAllNotificationContacts.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleNotificationContacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleNotificationContacts.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleNotificationContacts.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateNotificationContact.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateNotificationContact.fulfilled, (state, action) => {
        state.notificationContacts.push(action.payload as INotificationContacts);
        state.isCreating = false;
      })
      .addCase(CreateNotificationContact.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateNotificationContact.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateNotificationContact.fulfilled, (state, action) => {
        const updatedContact = action.payload as INotificationContacts;
        const index = state.notificationContacts.findIndex((contact) => contact.id === updatedContact.id);
        state.notificationContacts[index] = updatedContact;
        state.isUpdating = false;
      })
      .addCase(UpdateNotificationContact.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      })
      .addCase(DeleteNotificationContact.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteNotificationContact.fulfilled, (state, action) => {
        const deletedContactId = action.payload as string;
        state.notificationContacts = state.notificationContacts.filter((contact) => contact.id !== deletedContactId);
        state.isDeleting = false;
      })
      .addCase(DeleteNotificationContact.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isDeleting = false;
      });
  },
});

export default notificationContactsSlice.reducer;
