import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Data!.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Made with <i className="mdi mdi-heart text-danger"></i> in southern tyrolia
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
