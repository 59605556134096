import React, { useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row, Spinner, Button, Modal } from 'reactstrap';
import { processOccupancyAssistanceSlice } from 'src/slices/OccupancyAssistance/thunk';
import { useDispatch, useSelector } from 'react-redux';

import { notification, Space } from 'antd';
import DualAxesChart, { DualAxesChartProps, defaultDualAxesChartValue } from 'src/components/elements/DualAxesChart';
import './index.scss';
import { RootState } from 'src/slices';
import { Tabs } from 'antd';
import type { NotificationArgsProps, TabsProps } from 'antd';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { IOccupancyAssistanceFormHandle, OccupancyAssistanceForm } from '../form/OccupancyAssistanceForm';
import { useTranslation } from 'react-i18next';

const OccupancyAssistance: React.FC = () => {
  const userInputFormHandle = useRef<IOccupancyAssistanceFormHandle>(null);
  const { properties, selectedProperty } = useSelector((state: RootState) => state.porperty);
  const { t } = useTranslation('');

  const handleButtonClick = async () => {
    const mealPlans = userInputFormHandle.current?.mealPlans;
    const roomTypes = userInputFormHandle.current?.roomTypes;

    const formValues: any = await userInputFormHandle.current?.fetchValues();
    console.log(formValues);

    const newModalData = {
      ...mealPlans,
      ...roomTypes,
      StandardPrice: formValues?.totalRate,
      MaxDiscountPercentage: formValues?.variationPercentage,
      Occupancy: formValues?.occupancy,
      MandantId: selectedProperty?.value,
      StartDate: formValues?.dateRange[0],
      EndDate: formValues?.dateRange[1],
    };
    console.log('newModalData');
    console.log(newModalData);

    try {
      const response: any = await dispatch(processOccupancyAssistanceSlice(newModalData));
      console.log(response?.payload);
      console.log(occupancyAssistance);
      if (response && response.payload) {
        console.log(response.payload);
        const validRates = response.payload
          .map((item : any) => item.price)
          .filter((rate: number) => rate !== undefined && rate !== null && !isNaN(rate));

        // if (validRates.length > 0) {
        //   const maxDiscountedRate = Math.max(...validRates);
        // } 
        // var highest = Math.max(
        //   response.payload.map((item : any) => parseInt(item.price))
        // )
        console.log(Math.max(...validRates));
        setChartData({
          response: response.payload,
          xField: 'date',
          barField: 'occupancy',
          lineField: 'price',
          maxDomain: Math.max(...validRates) + 100,
          barLabel: 'Occupancy (%)',
          lineLabel: 'Discounted Rate',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [chartData, setChartData] = useState<DualAxesChartProps>(defaultDualAxesChartValue);
  const { occupancyAssistance, occupancyAssistanceLoading } = useSelector(
    (state: RootState) => state.occupancyAssistance,
  );

  console.log(selectedProperty);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };
  type NotificationPlacement = NotificationArgsProps['placement'];

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string, placement: NotificationPlacement) => {
    api[type]({
      message: message,
      placement,
    });
  };

  const handleSubmit = async () => {
    if (!file) {
      openNotificationWithIcon('warning', 'Please select a file', 'bottomRight');
      return;
    }

    const requestData = {
      modelName: 'Occupancy',
      file: file,
      isFromForm: false,
    };

    try {
      const response: any = await dispatch(processOccupancyAssistanceSlice(requestData));
      console.log(response);
      console.log(occupancyAssistance);

      setChartData(response?.payload); // Assuming data is in the format expected by your chart library
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Data Entry',
      children: (
        <Card>
          <CardBody>
            <OccupancyAssistanceForm callFunction={handleButtonClick} ref={userInputFormHandle} />
            {/* <button style={{ marginTop: '-10px' }} className="btn btn-primary" onClick={handleButtonClick}>
              Process
            </button> */}
          </CardBody>
        </Card>
      ),
    },
  ];

  console.log(chartData);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Occupancy Assistance</title>
      </MetaTags>
      <Container fluid>
        {contextHolder}
        <Breadcrumb title={t("Dashboard")} breadcrumbItem={t("Occupancy Assistance")} />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    {!chartData?.response?.length && !chartData?.response?.length ? (
                      <>
                        <div
                          style={{
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                            background: '#e9ecef',
                            padding: '2rem',
                          }}
                        >
                          {occupancyAssistanceLoading ? (
                            <div className="spinnerBlock">
                              <Spinner />
                            </div>
                          ) : (
                            <>
                              <h5>{t('Please make your selections and execute the analysis.')}</h5>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <div>
                        {occupancyAssistanceLoading ? (
                          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            <Spinner />
                          </div>
                        ) : (
                          <>{chartData && <DualAxesChart {...chartData} />}</>
                        )}
                      </div>
                    )}

                    <FileUploadModal
                      showModal={showModal}
                      handleFileChange={handleFileChange}
                      handleSubmit={handleSubmit}
                      OccupancyAssistanceLoading={occupancyAssistanceLoading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Tabs style={{ marginTop: '-10px' }} defaultActiveKey="1" items={items} />
      </Container>
    </div>
  );
};

const convertData = (data: any, MandantName: string) => {
  return {
    Inputs: {
      data: [data],
    },
    GlobalParameters: 0.0,
  };
};

const FileUploadModal = ({
  showModal,
  closeModal,
  handleFileChange,
  handleSubmit,
  OccupancyAssistanceLoading,
  chartData,
}: any) => {
  return (
    <Modal isOpen={showModal} onRequestClose={closeModal}>
      <div id="file-upload-div">
        <input type="file" onChange={handleFileChange} />
        <button disabled={OccupancyAssistanceLoading} onClick={handleSubmit}>
          Upload File
        </button>
      </div>
      {OccupancyAssistanceLoading && (
        <div className="spinnerBlock">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

export default OccupancyAssistance;
