import axios from 'axios';
import env from "../../../env";

const apiKeyUrl = env.VITE_REACT_APP_DEFAULTAUTH();;

export const getLocations = async (propertyId: any) => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(`${apiKeyUrl}/geographical-details/?propertyId=${propertyId}`, { headers });
};

export const createLocation = async (data: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await axios.post(`${apiKeyUrl}/geographical-details`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return res;
};

export const updateLocations = async (data: any, selectedProperty: { label: string; value: string }) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await axios.put(`${apiKeyUrl}/geographical-details?propertyId=${selectedProperty.value}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return res;
};

export const deleteLocation = async (propertyId: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await axios.delete(`${apiKeyUrl}/geographical-details?propertyId=${propertyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return res;
};

export const getCities = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(`${apiKeyUrl}/data/city`, { headers });
};

export const getContinents = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(`${apiKeyUrl}/data/continent`, { headers });
};

export const getCountries = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(`${apiKeyUrl}/data/country`, { headers });
};

export const getRegions = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(`${apiKeyUrl}/data/region`, { headers });
};

export const getPOIs = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(`${apiKeyUrl}/data/POI`, { headers });
};
